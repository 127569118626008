import React from 'react';
import type { IconProps } from '@models/types';

export const MiniArrowIcon: React.FC<IconProps> = (props) => (
    <svg
        width='14'
        height='10'
        viewBox='0 0 14 10'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M8.4946 7.74404C8.16917 8.06947 8.16917 8.59711 8.4946 8.92255C8.82004 9.24799 9.34768 9.24799 9.67311 8.92255L13.0064 5.58922L13.5957 4.99996L13.0064 4.4107L9.67311 1.07737C9.34768 0.751933 8.82004 0.751933 8.4946 1.07737C8.16917 1.40281 8.16917 1.93044 8.4946 2.25588L10.4053 4.16663L1.16719 4.16663C0.706955 4.16663 0.333859 4.53972 0.333859 4.99996C0.333859 5.4602 0.706955 5.83329 1.16719 5.83329L10.4053 5.83329L8.4946 7.74404Z'
            fill='currentColor'
        />
    </svg>
);
