import { useSelector } from 'react-redux';
import { ROUTES } from '@constants';
import type { RootState } from '@store/root-reducer';

export const useLoadedApp = () => {
    const isAdmin = useSelector((state: RootState) => state.domain.settings.is_admin_panel);
    const isWidget = window.location.pathname.startsWith('/widget');
    const isPolicy = window.location.pathname.startsWith('/policy');
    const isApiDocs = window.location.pathname.startsWith(ROUTES.API.DOCS);

    return {
        isAdmin,
        isWidget,
        isApiDocs,
        isPolicy,
    };
};
