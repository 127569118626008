import type { UploadDocumentResponse } from '@modules/docs-verification-widget/models/upload-document-response';
import i18n from 'i18next';

import type { GetVerificationResponse } from './get-verification-response';
import type {
    ProceedVerificationRequest,
    SetCountryRequest,
    UploadDocumentRequest,
} from './requests';
import { sdkApi } from './sdk-base-api';

export const sdkApiSlice = sdkApi.injectEndpoints({
    endpoints: (build) => ({
        getVerificationById: build.query<GetVerificationResponse, { url: string; id: string }>({
            query: ({ url, id }) => `${url}/api/v3/sdk/${id}`,
        }),
        uploadDocumentSDK: build.mutation<UploadDocumentResponse, UploadDocumentRequest>({
            query({ url, id, body }) {
                const formData = new FormData();

                Object.keys(body).forEach((key) => {
                    const value = body[key as keyof typeof body];

                    if (value !== undefined) {
                        formData.append(key, value);
                    }
                });

                return {
                    url: `${url}/api/v3/upload/sdk/${id}`,
                    method: 'POST',
                    body: formData,
                    headers: {
                        'Accept-Language': i18n.language,
                    },
                };
            },
            invalidatesTags: [],
        }),
        proceedVerificationSDK: build.mutation<
            { result: string; error?: string },
            ProceedVerificationRequest
        >({
            query({ url, id }) {
                return {
                    url: `${url}/api/v3/sdk/${id}/proceed`,
                    method: 'POST',
                    headers: {
                        'Accept-Language': i18n.language,
                    },
                };
            },
            invalidatesTags: [],
        }),
        setCountrySDK: build.mutation<{ message: string }, SetCountryRequest>({
            query({ url, id, country }) {
                return {
                    url: `${url}/api/v3/sdk/${id}/set_country`,
                    method: 'POST',
                    body: {
                        country,
                    },
                    headers: {
                        'Accept-Language': i18n.language,
                    },
                };
            },
            invalidatesTags: [],
        }),
        setManualFieldsSDK: build.mutation<
            { message: string },
            { url: string; id: string; body: unknown }
        >({
            query({ url, id, body }) {
                return {
                    url: `${url}/api/v3/sdk/${id}/fields`,
                    method: 'POST',
                    body,
                    headers: {
                        'Accept-Language': i18n.language,
                    },
                };
            },
        }),
        uploadCustomFileSDK: build.mutation<
            { message: string },
            { url: string; id: string; body: FormData }
        >({
            query({ url, id, body }) {
                return {
                    url: `${url}/api/v3/sdk/${id}/upload`,
                    method: 'POST',
                    body,
                    headers: {
                        'Accept-Language': i18n.language,
                    },
                };
            },
        }),
    }),
});

export const {
    useUploadDocumentSDKMutation,
    useProceedVerificationSDKMutation,
    useLazyGetVerificationByIdQuery,
    useSetCountrySDKMutation,
    useSetManualFieldsSDKMutation,
    useUploadCustomFileSDKMutation,
} = sdkApiSlice;
