export enum ApplicantExternalInfoEnum {
    FULL_NAME = 'full_name',
    FIRST_NAME = 'first_name',
    LAST_NAME = 'last_name',
    DOB = 'dob',
    GENDER = 'gender',
    CITIZENSHIP = 'citizenship',
    ADDRESS = 'address',
    COUNTRY = 'country',
    REGISTRATION_ID = 'registration_id',
    PHONE = 'phone',
    ADDRESSES = 'addresses',
    RESIDENCE = 'residence',
    CITY = 'city',
    STREET = 'street',
    POSTAL_CODE = 'postal_code',
}

export enum ApplicantExternalInfoAlternativeEnum {
    RESIDENCE_COUNTRY = 'residence_country',
}

export enum ApplicantExternalInfoGenderEnum {
    M = 'M',
    F = 'F',
}
