import type { PepEntityRiskScoreEnum } from '@models/enums';
import {
    FilterAmlNames,
    FilterFieldArrayOptionNamesEnum,
    FilterFieldDateNamesEnum,
    FilterFieldOptionNamesEnum,
} from '@models/enums';
import type { AutocompleteOption, DateRangeValue, FilterMenuProps } from '@models/types';
import { createSlice } from '@reduxjs/toolkit';
import { getFiltersReducer } from '@utils/filters/util';

export type PepHistoryFiltersState = {
    [FilterFieldDateNamesEnum.DATE_RANGE]: DateRangeValue;
    [FilterFieldArrayOptionNamesEnum.RISK_SCORES]: Array<
        AutocompleteOption<PepEntityRiskScoreEnum>
    >;
    [FilterFieldOptionNamesEnum.ACCOUNT_EMAIL]: AutocompleteOption<string> | null;
};

export type PepHistoryFiltersSliceState = FilterMenuProps<PepHistoryFiltersState>;

export const pepHistoryFiltersInitState: PepHistoryFiltersState = {
    [FilterFieldDateNamesEnum.DATE_RANGE]: null,
    [FilterFieldArrayOptionNamesEnum.RISK_SCORES]: [],
    [FilterFieldOptionNamesEnum.ACCOUNT_EMAIL]: null,
};

const initialState: PepHistoryFiltersSliceState = {
    isOpen: false,
    applyCount: 0,
    filters: pepHistoryFiltersInitState,
    searchQuery: '',
};

export const pepHistoryFiltersSlice = createSlice({
    name: FilterAmlNames.PEP_HISTORY_FILTERS_SLICE,
    initialState,
    reducers: getFiltersReducer<PepHistoryFiltersState, PepHistoryFiltersSliceState>(
        pepHistoryFiltersInitState,
    ),
});
