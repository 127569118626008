import React from 'react';
import type { IconProps } from '@models/types/icon-props';

export const CopySecondIcon: React.FC<IconProps> = ({ className }) => (
    <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={className}
    >
        <g id='link share'>
            <path
                id='Union'
                fillRule='evenodd'
                clipRule='evenodd'
                d='M19.4656 4.5344C17.4197 2.48853 14.1027 2.48853 12.0569 4.5344L9.86881 6.72245C9.48664 7.10462 9.48664 7.72424 9.86881 8.1064C10.251 8.48857 10.8706 8.48857 11.2528 8.1064L13.4408 5.91835C14.7223 4.63682 16.8001 4.63682 18.0816 5.91836C19.3632 7.19989 19.3632 9.27766 18.0816 10.5592L15.9994 12.6415C15.6172 13.0236 15.6172 13.6433 15.9994 14.0254C16.3815 14.4076 17.0011 14.4076 17.3833 14.0254L19.4656 11.9431C21.5115 9.89727 21.5115 6.58027 19.4656 4.5344ZM8.20491 11.1543C8.58708 10.7721 8.58708 10.1525 8.20491 9.7703C7.82275 9.38813 7.20313 9.38813 6.82096 9.7703L4.5344 12.0569C2.48853 14.1027 2.48853 17.4197 4.5344 19.4656C6.58027 21.5115 9.89728 21.5115 11.9431 19.4656L14.1588 17.2499C14.541 16.8678 14.541 16.2482 14.1588 15.866C13.7766 15.4838 13.157 15.4838 12.7748 15.866L10.5592 18.0816C9.27766 19.3632 7.19989 19.3632 5.91835 18.0816C4.63682 16.8001 4.63682 14.7223 5.91835 13.4408L8.20491 11.1543ZM16.6086 8.86837C16.9908 8.4862 16.9908 7.86658 16.6086 7.48441C16.2264 7.10224 15.6068 7.10224 15.2246 7.48441L7.60501 15.104C7.22285 15.4862 7.22285 16.1058 7.60501 16.488C7.98718 16.8702 8.6068 16.8702 8.98897 16.488L16.6086 8.86837Z'
                fill='currentColor'
            />
        </g>
    </svg>
);
