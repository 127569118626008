import is from 'is_js';

export const encodeUrlParams = (params: Record<string, string>) =>
    new URLSearchParams(params).toString();

export const isExternalURL = (url: string) => {
    let validUrl: URL;

    try {
        validUrl = new URL(url);

        return validUrl.origin !== window.location.origin;
    } catch (e) {
        // if URL is incorrect, it means it is an internal link
        return false;
    }
};

export const validateUrl = (
    str: string,
    errorText: string,
    {
        requiredText = '',
        requireProtocol = false,
    }: {
        requiredText?: string;
        requireProtocol?: boolean;
    } = {},
) => {
    if (!str.length) {
        return requiredText;
    }
    if (requireProtocol && !(str.startsWith('http://') || str.startsWith('https://'))) {
        return errorText;
    }

    if (is.url(str)) {
        return '';
    }

    return errorText;
};

export const getSearchParams = (params: Record<string, unknown>) => {
    const result: Record<string, string | unknown[]> = {};

    Object.entries(params).forEach(([key, value]) => {
        if (Array.isArray(value)) {
            if (value.length) {
                result[key] = value;
            }
        } else if (value !== undefined && value !== '') {
            result[key] = String(value);
        }
    });

    // @ts-expect-error array is valid
    return new URLSearchParams(result);
};
