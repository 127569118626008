import type { DomainSettingsModel } from '@modules/shared/models/white-label/domain-settings-model';

import { publicApi } from './public-base-api';

const URL_PREFIX = '/webapi/wl/';

export const whiteLabelApiSlice = publicApi.injectEndpoints({
    endpoints: (build) => ({
        getDomainSettings: build.query<DomainSettingsModel, { url: string; domain?: string }>({
            query: ({ url, domain }) =>
                `${url}${URL_PREFIX}public-settings${domain ? `/${domain}` : ''}`,
        }),
        getOpenApiDocs: build.query<string, { url: string }>({
            query: ({ url }) => ({
                url: `${url}${URL_PREFIX}public-docs`,
                responseHandler: (response) => response.text(),
            }),
        }),
    }),
});

export const { useLazyGetDomainSettingsQuery, useGetOpenApiDocsQuery } = whiteLabelApiSlice;
