import React from 'react';

export const ProfileIcon = () => (
    <svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 4C4.34315 4 3 5.34315 3 7C3 8.65685 4.34315 10 6 10C7.65685 10 9 8.65685 9 7C9 5.34315 7.65685 4 6 4ZM5 7C5 6.44772 5.44772 6 6 6C6.55228 6 7 6.44772 7 7C7 7.55228 6.55228 8 6 8C5.44772 8 5 7.55228 5 7Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.9991 6.95656C21.9879 6.69544 21.8766 6.46025 21.7026 6.28842L15.7117 0.297497C15.5304 0.113829 15.2785 0 15 0H3C1.34315 0 0 1.34315 0 3V16C0 17.6569 1.34315 19 3 19H19C20.6569 19 22 17.6569 22 16V7C22 6.98545 21.9997 6.97096 21.9991 6.95656ZM2 3C2 2.44772 2.44772 2 3 2H14V5.00003C14 6.65688 15.3431 8.00003 17 8.00003H20V16C20 16.5523 19.5523 17 19 17H12C12 13.6863 9.31368 11 5.99997 11C4.46328 11 3.06151 11.5777 2 12.5278V3ZM5.99997 13C8.20911 13 9.99997 14.7909 9.99997 17H3C2.56343 17 2.1922 16.7202 2.0558 16.3302C2.37448 14.4399 4.01907 13 5.99997 13ZM16 5.00003V3.41424L18.5858 6.00003H17C16.4477 6.00003 16 5.55231 16 5.00003Z"
            fill="currentColor"
        />
    </svg>
);
