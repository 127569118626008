import React from 'react';
import type { IconProps } from '@models/types';

export const CardIcon: React.FC<IconProps> = (props) => (
    <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
    >
        <path
            d='M5 13C4.44772 13 4 13.4477 4 14C4 14.5523 4.44772 15 5 15H10.5C11.0523 15 11.5 14.5523 11.5 14C11.5 13.4477 11.0523 13 10.5 13H5Z'
            fill='currentColor'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M3.5 3H20.5C21.8807 3 23 4.11929 23 5.5V16.5C23 17.8807 21.8807 19 20.5 19H3.5C2.11929 19 1 17.8807 1 16.5V5.5C1 4.11929 2.11929 3 3.5 3ZM3.5 5C3.22386 5 3 5.22386 3 5.5V8H21V5.5C21 5.22386 20.7761 5 20.5 5H3.5ZM3 16.5V10H21V16.5C21 16.7761 20.7761 17 20.5 17H3.5C3.22386 17 3 16.7761 3 16.5Z'
            fill='currentColor'
        />
    </svg>
);
