import { initReactI18next } from 'react-i18next';
import { LangEnum } from '@models/enums';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

export const AVAILABLE_LANGUAGES = [LangEnum.EN, LangEnum.RU, LangEnum.ES, LangEnum.PT];

type JsonsProps = {
    [key: string]: {
        [key: string]: string;
    };
};

const getAllJsons = (lang: LangEnum) => {
    if (lang === LangEnum.EN) {
        const context = require?.context(`./locales/en`, true, /.json$/);

        return context.keys().map((jsonPath: string) => context(jsonPath));
    }

    if (lang === LangEnum.PT) {
        const context = require?.context(`./locales/pt`, true, /.json$/);

        return context.keys().map((jsonPath: string) => context(jsonPath));
    }

    if (lang === LangEnum.RU) {
        const context = require?.context(`./locales/ru`, true, /.json$/);

        return context.keys().map((jsonPath: string) => context(jsonPath));
    }

    if (lang === LangEnum.ES) {
        const context = require?.context(`./locales/es`, true, /.json$/);

        return context.keys().map((jsonPath: string) => context(jsonPath));
    }

    return {};
};

export const createLocalization = (languages = [LangEnum.EN], lng?: LangEnum) => {
    const getFlatJsons = (jsons: JsonsProps) => {
        let obj = {};

        Object.values(jsons).forEach((item) => {
            obj = {
                ...obj,
                ...item,
            };
        });

        return obj;
    };

    const resources = languages.reduce(
        (acc, item) => ({
            ...acc,
            [item]: {
                translation: getFlatJsons(getAllJsons(item)),
            },
        }),
        {},
    );

    i18n.use(LanguageDetector)
        .use(initReactI18next)
        .init({
            fallbackLng: LangEnum.EN,
            resources,
            lng,
            interpolation: {
                escapeValue: false,
            },
        });
};
