import React from 'react';
import type { IconProps } from '@models/types';

export const AttentionIcon: React.FC<IconProps> = ({ className }) => (
    <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={className}
    >
        <path
            d='M10.5726 5.12034L3.72748 17.0016C2.95931 18.3349 3.92166 20 5.46045 20H18.6618C20.1728 20 21.1381 18.3889 20.4253 17.0566L14.0691 5.17532C13.3288 3.79158 11.3561 3.76055 10.5726 5.12034Z'
            stroke='currentColor'
            strokeWidth='2'
            strokeLinejoin='round'
        />
        <path
            d='M12 10C12.5523 10 13 9.55228 13 9C13 8.44772 12.5523 8 12 8C11.4477 8 11 8.44772 11 9C11 9.55228 11.4477 10 12 10Z'
            fill='currentColor'
        />
        <path
            d='M12 11C11.7348 11 11.4804 11.1054 11.2929 11.2929C11.1054 11.4804 11 11.7348 11 12V17C11 17.2652 11.1054 17.5196 11.2929 17.7071C11.4804 17.8946 11.7348 18 12 18C12.2652 18 12.5196 17.8946 12.7071 17.7071C12.8946 17.5196 13 17.2652 13 17V12C13 11.7348 12.8946 11.4804 12.7071 11.2929C12.5196 11.1054 12.2652 11 12 11Z'
            fill='currentColor'
        />
    </svg>
);
