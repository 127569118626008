import React from 'react';
import type { IconProps } from '@models/types';

export const PricingIcon: React.FC<IconProps> = ({ className }) => (
    <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={className}
    >
        <g clipPath='url(#clip0_593_47593)'>
            <path
                d='M17.4238 12.146C17.4238 10.769 16.2628 10.472 15.3718 10.247C14.7958 10.103 14.3458 9.986 14.3458 9.644C14.3458 9.383 14.6338 9.257 14.9308 9.257C15.2818 9.257 15.5608 9.428 15.5608 9.788L17.2348 9.779C17.2348 8.78 16.6588 8.105 15.6328 7.907V7.304H14.2918V7.898C13.2568 8.078 12.6538 8.717 12.6538 9.689C12.6538 11.0599 13.76 11.3336 14.6312 11.5491L14.6428 11.552L14.6726 11.5594C15.2345 11.6998 15.6778 11.8105 15.6778 12.182C15.6778 12.488 15.3448 12.668 15.0028 12.668C14.6248 12.668 14.2918 12.47 14.2918 12.056H12.5728C12.5728 13.154 13.2478 13.874 14.3458 14.072V14.702H15.6958V14.072C16.7308 13.874 17.4238 13.226 17.4238 12.146Z'
                fill='currentColor'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M5.99997 9V7C5.99997 5.34315 7.34312 4 8.99998 4H21C22.6568 4 24 5.34315 24 7V14C24 15.6569 22.6568 17 21 17H14.3663C14.3112 17.0955 14.2392 17.1834 14.1508 17.2593C13.5345 17.7875 12.5157 18.5714 11.4315 19.2297C10.889 19.5592 10.3117 19.8686 9.747 20.0982C9.19598 20.3223 8.58651 20.5 7.99998 20.5C7.10701 20.5 5.78219 20.3192 4.54646 20.1111C3.28943 19.8995 2.04728 19.646 1.28304 19.4762C0.743911 19.3564 0.40398 18.8222 0.523788 18.2831C0.643595 17.7439 1.17777 17.404 1.71691 17.5238C2.45267 17.6873 3.66052 17.9338 4.87849 18.1389C6.11776 18.3475 7.29294 18.5 7.99998 18.5C8.21344 18.5 8.54563 18.4277 8.99357 18.2455C9.42783 18.0689 9.90674 17.8158 10.3934 17.5203C10.6673 17.354 10.9377 17.1778 11.1973 17H8.49998C7.39204 17 6.45244 16.2793 6.12442 15.2811C5.95331 15.4181 5.73621 15.5 5.49998 15.5C4.94769 15.5 4.49998 15.0523 4.49998 14.5C4.49998 14.0108 4.8161 13.6982 4.94039 13.5864C5.11475 13.4295 5.3316 13.2986 5.56887 13.1852C6.04802 12.9563 6.76231 12.7277 7.80386 12.5194C8.35415 12.4094 8.67867 12.0396 8.87315 11.507C8.93406 11.3402 8.97722 11.1672 9.00553 11H5.49998C5.49926 11 5.48572 11.0002 5.45675 11.0038C5.42744 11.0074 5.38863 11.0137 5.33952 11.0237C5.24024 11.044 5.11625 11.0761 4.96873 11.1209C4.67314 11.2107 4.32178 11.3396 3.94894 11.4899C3.20437 11.7902 2.42929 12.1534 1.94719 12.3944C1.45321 12.6414 0.852537 12.4412 0.605548 11.9472C0.358558 11.4532 0.558783 10.8526 1.05276 10.6056C1.57066 10.3466 2.39558 9.95983 3.20101 9.63506C3.60317 9.4729 4.01431 9.32057 4.38747 9.20723C4.73377 9.10204 5.14197 9 5.49998 9H5.99997ZM7.99997 7C7.99997 6.44772 8.44769 6 8.99998 6H21C21.5523 6 22 6.44772 22 7V14C22 14.5523 21.5523 15 21 15H8.49998C8.23083 15 8.01135 14.7873 8.0004 14.5209C8.06358 14.5075 8.1288 14.494 8.19609 14.4806C9.6458 14.1906 10.4046 13.1438 10.7518 12.193C11.082 11.2889 11.1081 10.3092 10.9701 9.75746C10.8588 9.3123 10.4588 9 9.99998 9H7.99997V7Z'
                fill='currentColor'
            />
        </g>
        <defs>
            <clipPath id='clip0_593_47593'>
                <rect width='24' height='24' fill='white' />
            </clipPath>
        </defs>
    </svg>
);
