export const TAB_NAME_KEY = 'tabName';
export const SUB_TAB_NAME_KEY = 'subTabName';

export const Z_INDEX = {
    NAVIGATION: 100,
    MODAL: 999,
};
export const MODAL_MAX_WIDTH = {
    DEFAULT: '90vw',
};

export const MODAL_WIDTH = {
    DEFAULT: '567px',
};

export const SANDBOX = {
    SANDBOX: 'sandbox',
    IS_SANDBOX: 'isSandbox',
    ERROR_MESSAGE_IS_SANDBOX: 'is available in the sandbox',
    ERROR_MESSAGE_IS_NONE_SANDBOX: 'is not available in the sandbox',
    APPLICANT: 'applicant',
    VERIFICATION: 'verification',
};
