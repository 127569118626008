import React from 'react';
import type { IconProps } from '@models/types';

export const WarningIcon: React.FC<IconProps> = ({ className }) => (
    <svg
        className={className}
        width='18'
        height='18'
        viewBox='0 0 18 18'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9ZM9 3C9.55229 3 10 3.44772 10 4V10C10 10.5523 9.55229 11 9 11C8.44771 11 8 10.5523 8 10V4C8 3.44772 8.44771 3 9 3ZM10 13.5C10 12.9477 9.55229 12.5 9 12.5C8.44771 12.5 8 12.9477 8 13.5V14C8 14.5523 8.44771 15 9 15C9.55229 15 10 14.5523 10 14V13.5Z'
            fill='currentColor'
        />
    </svg>
);
