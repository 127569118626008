import * as React from 'react';
import { forwardRef } from 'react';
import { TypographyWrapper } from '@components/atoms/typography/styles';
import type { TypographyProps } from '@components/atoms/typography/types';

export const Typography = forwardRef<
    HTMLSpanElement,
    TypographyProps & React.HTMLAttributes<HTMLSpanElement>
>((props, ref) => {
    const {
        children,
        className,
        tag = 'span',
        color = 'text-main',
        variant,
        style,
        ...rest
    } = props;

    return (
        <TypographyWrapper
            ref={ref}
            as={tag}
            variant={variant}
            className={className}
            style={style}
            $color={color}
            {...rest}
        >
            {children}
        </TypographyWrapper>
    );
});
