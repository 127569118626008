import type { VerificationProfileSettingsModel } from '@models';
import type {
    DocsVerificationAddressTypeEnum,
    DocsVerificationBiometricTypeEnum,
    DocsVerificationDocumentTypeEnum,
    DocsVerificationLivenessTagErrorEnum,
    DocsVerificationSideEnum,
    VerificationPhotosKeysEnum,
} from '@models/enums';
import { DocsVerificationStepEnum, DocsVerificationTabEnum } from '@models/enums';
import { DocsVerificationFileUploadApproachEnum } from '@models/enums/docs-verification/docs-verification-file-upload-approach-enum';
import type { AutocompleteOption, FileWithPreview } from '@models/types';
import { DocumentsStepEnum } from '@modules/dashboard/pages/docs-verification/docs-verification-page/tabs/documents/types';
import type { GetVerificationResponse } from '@modules/docs-verification-widget/api/get-verification-response';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { FaceLandmarksDetector } from '@tensorflow-models/face-landmarks-detection';
import { isDesktop } from '@utils/util';

export type VerificationFileState = {
    file?: FileWithPreview;
    id?: string;
    tab?: DocsVerificationTabEnum;
    uploadedFile?: {
        preview: string;
        name: string;
        size: number;
    };
    documentType:
        | DocsVerificationDocumentTypeEnum
        | DocsVerificationBiometricTypeEnum
        | DocsVerificationAddressTypeEnum;
    side?: DocsVerificationSideEnum;
    isTwoSideDocument?: boolean;
    tag?: DocsVerificationLivenessTagErrorEnum;
    country?: string;
    status: {
        loading?: boolean;
        success?: boolean;
        error?: boolean;
        message?: string;
        code?: number;
        isFailedVerification?: boolean;
    };
};

export type VerificationState = {
    tabs: DocsVerificationTabEnum[];
    currentTabIndex: number;
    currentStep: DocsVerificationStepEnum;
    currentTab: DocsVerificationTabEnum;
    applicantId: string;
    continueVerification: DocsVerificationTabEnum | null;
    verificationId: string;
    lastVerificationId: string;
    country?: AutocompleteOption<string>;
    documentsCountry: string;
    documents: {
        files: VerificationFileState[];
    };
    documentsStep: DocumentsStepEnum;
    sdkOptions?: {
        id: string;
        elementId: string;
        apiUrl: string;
        checks: VerificationPhotosKeysEnum[];
        settings: VerificationProfileSettingsModel;
        country?: string;
        redirectUrl?: string;
        logsUrl?: string;
        status: GetVerificationResponse['status'];
        verificationUrl: string;
        expiresAt: string;
        source: string | null;
        isDemo: boolean;
    };
    faceDetector?: FaceLandmarksDetector;
    results: unknown;
    isCameraMode: boolean;
    isTermsAccepted: boolean;
    isShowRequirements: boolean;
    isFailedVerification: boolean;
    fileUploadApproach: DocsVerificationFileUploadApproachEnum;
};

const initialState: VerificationState = {
    tabs: [],
    currentTabIndex: 0,
    currentStep: DocsVerificationStepEnum.START,
    currentTab: DocsVerificationTabEnum.DOCUMENT,
    applicantId: '',
    verificationId: '',
    continueVerification: null,
    lastVerificationId: '',
    // autocomplete has problem with first render if it is undefined
    country: {
        label: '',
        value: '',
    },
    documentsCountry: '',
    documents: {
        files: [],
    },
    documentsStep: DocumentsStepEnum.UPLOAD_FIRST_SIDE,
    results: [],
    isCameraMode: false,
    isTermsAccepted: false,
    isShowRequirements: false,
    isFailedVerification: false,
    fileUploadApproach: DocsVerificationFileUploadApproachEnum.PHOTO_AND_FILE,
};

export const docsVerificationSlice = createSlice({
    name: 'docsVerificationSlice',
    initialState,
    reducers: {
        setLastVerificationId: (state, { payload }: PayloadAction<string>) => {
            state.lastVerificationId = payload;
        },
        setCurrentStep: (state, { payload }: PayloadAction<DocsVerificationStepEnum>) => {
            state.currentStep = payload;
            state.isCameraMode = false;
        },
        goBackStep: (state) => {
            const newIndex = state.currentTabIndex - 1;

            if (newIndex === -1) {
                state.currentStep = DocsVerificationStepEnum.START;
            } else {
                state.currentTabIndex = newIndex;
                state.currentTab = state.tabs[newIndex];
            }
            state.isCameraMode = false;
        },
        goNextStep: (state, { payload }: PayloadAction<boolean | undefined>) => {
            const newIndex = state.currentTabIndex + 1;

            if (newIndex === state.tabs.length) {
                if (payload) {
                    // eslint-disable-next-line prefer-destructuring
                    state.currentTab = state.tabs[0];
                    state.currentTabIndex = 0;
                } else {
                    state.currentStep = DocsVerificationStepEnum.FINISH;
                }
            } else {
                state.currentTabIndex = newIndex;
                state.currentTab = state.tabs[newIndex];
            }
            state.isCameraMode = false;
        },
        addFile: (state, { payload }: PayloadAction<VerificationFileState>) => {
            state.documents.files = state.documents.files.filter((file) => !file.status.error);
            state.documents.files.push(payload);
        },
        changeFile: (
            state,
            {
                payload,
            }: PayloadAction<{
                file: VerificationFileState;
                id: string;
            }>,
        ) => {
            const index = state.documents.files.findIndex((item) => item.id === payload.id);

            if (index >= 0) {
                state.documents.files[index] = payload.file;
            }
        },
        deleteFileById: (state, { payload }: PayloadAction<string>) => {
            const index = state.documents.files.findIndex((item) => item.id === payload);

            if (index >= 0) {
                state.documents.files.splice(index, 1);
            }
        },
        deleteFilesByTab: (state, { payload }: PayloadAction<DocsVerificationTabEnum>) => {
            state.documents.files = state.documents.files.filter((item) => item.tab !== payload);
        },
        clearSteps: () => initialState,
        useSDK: (state, { payload }: PayloadAction<VerificationState['sdkOptions']>) => {
            state.sdkOptions = payload;

            if (payload?.status === 'expired') {
                state.currentStep = DocsVerificationStepEnum.EXPIRED;

                return;
            }

            if (payload?.status === 'completed') {
                state.currentStep = DocsVerificationStepEnum.FINISH;

                return;
            }

            if (!payload?.settings?.allow_desktop && isDesktop()) {
                state.currentStep = DocsVerificationStepEnum.DESKTOP_NOT_ALLOWED;

                return;
            }

            state.tabs = [];

            if (state?.sdkOptions?.settings?.manual_fields_settings?.enabled) {
                state.tabs.push(DocsVerificationTabEnum.PERSONAL_DATA);
            }

            if (state?.sdkOptions?.settings.poi_required) {
                state.tabs.push(DocsVerificationTabEnum.DOCUMENT);
            }

            if (state?.sdkOptions?.settings.face_comparison_required) {
                state.tabs.push(DocsVerificationTabEnum.BIOMETRICS);
            }

            if (state?.sdkOptions?.settings.poa_required) {
                state.tabs.push(DocsVerificationTabEnum.ADDRESS);
            }

            // eslint-disable-next-line prefer-destructuring
            state.currentTab = state.tabs[0];

            if (payload?.source === 'telegram') {
                state.currentStep = DocsVerificationStepEnum.VERIFICATION;
            } else {
                state.currentStep = DocsVerificationStepEnum.START;
            }
        },
        setCountry: (state, { payload }: PayloadAction<AutocompleteOption<string> | undefined>) => {
            state.country = payload;
        },
        setDocumentsCountry: (state, { payload }: PayloadAction<string>) => {
            state.documentsCountry = payload;
        },
        setFaceDetector: (state, { payload }: PayloadAction<FaceLandmarksDetector>) => {
            state.faceDetector = payload;
        },
        setIsCameraMode: (state, { payload }: PayloadAction<boolean>) => {
            state.isCameraMode = payload;
        },
        setIsTermsAccepted: (state, { payload }: PayloadAction<boolean>) => {
            state.isTermsAccepted = payload;
        },
        setCurrentTab: (state, { payload }: PayloadAction<DocsVerificationTabEnum>) => {
            state.currentTab = payload;
            state.isCameraMode = false;
        },
        setIsShowRequirements: (state, { payload }: PayloadAction<boolean>) => {
            state.isShowRequirements = payload;
        },
        setDocumentsStep: (state, { payload }: PayloadAction<DocumentsStepEnum>) => {
            state.documentsStep = payload;
        },
        setIsFailedVerification: (state, { payload }: PayloadAction<boolean>) => {
            state.isFailedVerification = payload;
        },
        setFileUploadApproach: (
            state,
            { payload }: PayloadAction<DocsVerificationFileUploadApproachEnum>,
        ) => {
            state.fileUploadApproach = payload;
        },
    },
});
