import React from 'react';
import type { IconProps } from '@models/types';

export const CompanyIcon: React.FC<IconProps> = ({ className }) => (
    <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={className}
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M12.5547 3.16795C12.2188 2.94402 11.7812 2.94402 11.4453 3.16795L2.44529 9.16795C2.07863 9.41239 1.9152 9.86803 2.04289 10.2898C2.17059 10.7115 2.55932 11 2.99999 11H4.99999V20H2.99999C2.4477 20 1.99999 20.4477 1.99999 21C1.99999 21.5523 2.4477 22 2.99999 22L21 22C21.5523 22 22 21.5523 22 21C22 20.4477 21.5523 20 21 20H19V11H21C21.4407 11 21.8294 10.7115 21.9571 10.2898C22.0848 9.86803 21.9213 9.41239 21.5547 9.16795L12.5547 3.16795ZM6.99999 20H8.99999V11H6.99999V20ZM17 11V20H15V11H17ZM17.6972 9L12 5.20185L6.30276 9H17.6972ZM13 11V20H11V11H13Z'
            fill='currentColor'
        />
    </svg>
);
