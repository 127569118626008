export enum VerificationPhotosKeysEnum {
    SELFIE = 'selfie',
    PASSPORT = 'passport',
    ID_CARD_FRONT = 'id_card_front',
    ID_CARD_BACK = 'id_card_back',
    RESIDENCE_PERMIT_FRONT = 'residence_permit_front',
    RESIDENCE_PERMIT_BACK = 'residence_permit_back',
    VISA = 'visa',
    POA = 'poa',
    LIVENESS_PHOTO = 'liveness_photo',
    DRIVER_LICENSE_FRONT = 'driver_license_front',
    DRIVER_LICENSE_BACK = 'driver_license_back',
    OTHER = 'other',
    MANUAL_FIELD_FILE = 'manual_field_file',
}
