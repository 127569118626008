import type { OrganizationAccountInfoModel, OrganizationInfoModel, TeamMemberModel } from '@models';
import { AccountStatus } from '@models';
import { OrganizationRoleEnum } from '@models/enums';
import type { AccountModel, OrganizationAccountModel } from '@modules/admin-panel/models';

export type UpdateOrganizationProps =
    | OrganizationInfoModel
    | OrganizationAccountInfoModel
    | TeamMemberModel
    | OrganizationAccountModel
    | AccountModel;

export const updateOrganizationRole = (obj: UpdateOrganizationProps) => {
    if ('organization_role' in obj) {
        const upperCaseRole = obj.organization_role.toUpperCase();

        if (Object.values(OrganizationRoleEnum).includes(upperCaseRole as OrganizationRoleEnum)) {
            obj.organization_role = upperCaseRole as OrganizationRoleEnum;
        }
    }

    return obj;
};
export const updateOrganizationStatus = (obj: UpdateOrganizationProps, key: string) => {
    if (key in obj) {
        const upperCaseRole = obj[key as keyof typeof obj]?.toUpperCase();

        if (Object.values(AccountStatus).includes(upperCaseRole as AccountStatus)) {
            (obj as Record<string, string>)[key] = upperCaseRole as AccountStatus;
        }
    }

    return obj;
};

export const updateOrganization = (obj: UpdateOrganizationProps): UpdateOrganizationProps => {
    let newObj = obj;

    if ('organization_role' in obj) {
        newObj = updateOrganizationRole(obj);
    }
    if ('account_status' in obj) {
        newObj = updateOrganizationStatus(newObj, 'account_status');
    }
    if ('status' in obj) {
        newObj = updateOrganizationStatus(newObj, 'status');
    }

    return newObj;
};
