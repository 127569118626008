import type {
    DocsVerificationDocumentTypeEnum,
    DocsVerificationStatusEnum,
    FilterFieldArrayOptionNamesEnum,
    FilterFieldBooleanNamesEnum,
    FilterFieldDateNamesEnum,
    FilterFieldOptionNamesEnum,
} from '@models/enums';
import { FilterDocsVerNames } from '@models/enums/filters';
import type { AutocompleteOption, DateRangeValue, FilterMenuProps } from '@models/types';
import { createSlice } from '@reduxjs/toolkit';
import { getFiltersReducer } from '@utils/filters/util';

export type AdminDocsVerificationFiltersState = {
    [FilterFieldOptionNamesEnum.COUNTRY]?: AutocompleteOption<string> | null;
    [FilterFieldDateNamesEnum.DATE_RANGE]: DateRangeValue;
    [FilterFieldArrayOptionNamesEnum.DOCUMENT_TYPES]: Array<
        AutocompleteOption<DocsVerificationDocumentTypeEnum>
    >;
    [FilterFieldArrayOptionNamesEnum.PROFILES]: Array<AutocompleteOption<string>>;
    [FilterFieldArrayOptionNamesEnum.STATUSES]: Array<
        AutocompleteOption<DocsVerificationStatusEnum>
    >;
    [FilterFieldBooleanNamesEnum.ONLY_DOC_VER_MANUAL_STATUS]: boolean;
};

export type AdminDocsVerificationFiltersSliceState =
    FilterMenuProps<AdminDocsVerificationFiltersState>;

export const adminDocsVerificationFiltersInitState: AdminDocsVerificationFiltersState = {
    country: null,
    dateRange: null,
    documentTypes: [],
    profiles: [],
    statuses: [],
    onlyDocVerManualStatus: false,
};

const initialState: AdminDocsVerificationFiltersSliceState = {
    isOpen: false,
    applyCount: 0,
    filters: adminDocsVerificationFiltersInitState,
    searchQuery: '',
};

export const adminDocsVerificationFiltersSlice = createSlice({
    name: FilterDocsVerNames.ADMIN_DOCS_VERIFICATION_FILTERS_SLICE,
    initialState,
    reducers: getFiltersReducer<
        AdminDocsVerificationFiltersState,
        AdminDocsVerificationFiltersSliceState
    >(adminDocsVerificationFiltersInitState),
});
