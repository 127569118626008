import React from 'react';
import type { IconProps } from '@models/types';

export const DocsVerificationIcon: React.FC<IconProps> = ({ ...props }) => (
    <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M3.17219 6.17157C3.13996 6.20381 3.10861 6.23658 3.07812 6.26992C3.19237 4.81938 3.47463 3.86897 4.17202 3.17157C5.3436 2 7.22921 2 11.0005 2H13.0005C16.7717 2 18.6573 2 19.8289 3.17157C20.5262 3.8689 20.8085 4.8192 20.9227 6.26952C20.8924 6.23632 20.8612 6.20368 20.829 6.17157C19.6575 5 17.7719 5 14.0006 5H10.0006C6.22938 5 4.34376 5 3.17219 6.17157ZM2 14C2 10.2288 2 8.34315 3.17157 7.17157C4.34315 6 6.22876 6 10 6H14C17.7712 6 19.6569 6 20.8284 7.17157C22 8.34315 22 10.2288 22 14C22 17.7712 22 19.6569 20.8284 20.8284C19.6569 22 17.7712 22 14 22H10C6.22876 22 4.34315 22 3.17157 20.8284C2 19.6569 2 17.7712 2 14ZM7 10.5C6.44772 10.5 6 10.9477 6 11.5C6 12.0523 6.44772 12.5 7 12.5H16.5C17.0523 12.5 17.5 12.0523 17.5 11.5C17.5 10.9477 17.0523 10.5 16.5 10.5H7ZM7 15C6.44772 15 6 15.4477 6 16C6 16.5523 6.44772 17 7 17H12.5C13.0523 17 13.5 16.5523 13.5 16C13.5 15.4477 13.0523 15 12.5 15H7Z'
            fill='currentColor'
        />
    </svg>
);
