import type {
    FilterFieldArrayOptionNamesEnum,
    FilterFieldDateNamesEnum,
    PepEntityRiskScoreEnum,
} from '@models/enums';
import { FilterOrganizationNames } from '@models/enums';
import type { AutocompleteOption, DateRangeValue, FilterMenuProps } from '@models/types';
import { createSlice } from '@reduxjs/toolkit';
import { getFiltersReducer } from '@utils/filters/util';

export type OrganizationAmlChecksFiltersState = {
    [FilterFieldDateNamesEnum.CREATED_AT_DATE_RANGE]: DateRangeValue;
    [FilterFieldArrayOptionNamesEnum.RISK_SCORES]: Array<
        AutocompleteOption<PepEntityRiskScoreEnum>
    >;
};

export type OrganizationAmlChecksFiltersSliceState =
    FilterMenuProps<OrganizationAmlChecksFiltersState>;

export const organizationAmlChecksFiltersInitState: OrganizationAmlChecksFiltersState = {
    createdAtDateRange: null,
    riskScores: [],
};

const initialState: OrganizationAmlChecksFiltersSliceState = {
    isOpen: false,
    applyCount: 0,
    filters: organizationAmlChecksFiltersInitState,
};

export const organizationAmlChecksFiltersSlice = createSlice({
    name: FilterOrganizationNames.ORGANIZATION_AML_CHECKS_FILTERS_SLICE,
    initialState,
    reducers: getFiltersReducer<
        OrganizationAmlChecksFiltersState,
        OrganizationAmlChecksFiltersSliceState
    >(organizationAmlChecksFiltersInitState),
});
