export enum ApplicantAlertTypeEnum {
    AML_RISK_SCORE_ABOVE_LOW = 'aml.risk_score.above_low',
    AML_RISK_SCORE_HIGHT = 'aml.risk_score.high',
    DOCVER_CHECK_FAILED_POA = 'docver.check_failed.poa',
    DOCVER_TEMPETING_POA = 'docver.tampering.poa',
    DOCVER_TEMPETING_POI = 'docver.tampering.poi',
    DOCVER_TEMPETING_LIVENESS = 'docver.tampering.liveness',
    DOCVER_LIVENESS_DEEPFAKE = 'docver.liveness.fraud.deepfake',
    DOCVER_LIVENESS_SPOOFING = 'docver.liveness.fraud.spoofing',
    DOCVER_EXPIRED_DOCUMENT = 'docver.expired_document',
}
