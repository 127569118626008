import type { ApplicantModel } from '@models';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

interface funcCallback {
    (applicant: ApplicantModel): void;
}

export type ApplicantEditModalState = {
    applicant?: ApplicantModel;
    updateCallback: funcCallback;
};

const initialState: ApplicantEditModalState = {
    applicant: undefined,
    updateCallback: () => {},
};

export const applicantEditModalSlice = createSlice({
    name: 'applicantEditModal',
    initialState,
    reducers: {
        setOptionsApplicant: (
            state,
            {
                payload,
            }: PayloadAction<{
                applicant: ApplicantModel;
                updateCallback: funcCallback;
            }>,
        ) => {
            state.applicant = payload.applicant;
            state.updateCallback = payload.updateCallback;
        },
        resetOptionsApplicant: (state) => {
            state.applicant = undefined;
            state.updateCallback = () => {};
        },
    },
});
