import type {
    ApplicantVerificationStatusEnum,
    FilterFieldArrayOptionNamesEnum,
    FilterFieldBooleanNamesEnum,
    FilterFieldDateNamesEnum,
    PepEntityRiskScoreEnum,
} from '@models/enums';
import { FilterOrganizationNames } from '@models/enums';
import type { AutocompleteOption, DateRangeValue, FilterMenuProps } from '@models/types';
import { createSlice } from '@reduxjs/toolkit';
import { getFiltersReducer } from '@utils/filters/util';

export type OrganizationApplicantsFiltersState = {
    [FilterFieldDateNamesEnum.DOB_DATE_RANGE]: DateRangeValue;
    [FilterFieldArrayOptionNamesEnum.CITIZENSHIPS]: Array<AutocompleteOption<string>>;
    [FilterFieldArrayOptionNamesEnum.APPLICANT_VERIFICATION_STATUSES]: Array<
        AutocompleteOption<ApplicantVerificationStatusEnum>
    >;
    [FilterFieldArrayOptionNamesEnum.RISK_SCORES]: Array<
        AutocompleteOption<PepEntityRiskScoreEnum>
    >;
    [FilterFieldBooleanNamesEnum.ONGOING]: boolean | null;
};

export const organizationApplicantsFiltersInitState: OrganizationApplicantsFiltersState = {
    dobDateRange: null,
    citizenships: [],
    applicantVerificationStatuses: [],
    riskScores: [],
    ongoing: null,
};

export type OrganizationApplicantsFiltersSliceState =
    FilterMenuProps<OrganizationApplicantsFiltersState>;

const initialState: OrganizationApplicantsFiltersSliceState = {
    isOpen: false,
    applyCount: 0,
    filters: organizationApplicantsFiltersInitState,
};

export const organizationApplicantsFiltersSlice = createSlice({
    name: FilterOrganizationNames.ORGANIZATION_APPLICANTS_FILTERS_SLICE,
    initialState,
    reducers: getFiltersReducer<
        OrganizationApplicantsFiltersState,
        OrganizationApplicantsFiltersSliceState
    >(organizationApplicantsFiltersInitState),
});
