export enum FilterDocsVerNames {
    APPLICANT_DOCS_VERIFICATION_FILTERS_SLICE = 'applicantDocsVerificationFiltersSlice',
    DOCS_VERIFICATION_FILTERS_SLICE = 'docsVerificationFiltersSlice',
    ADMIN_DOCS_VERIFICATION_FILTERS_SLICE = 'adminDocsVerificationFiltersSlice',
}

export enum FilterAmlNames {
    PEP_HISTORY_FILTERS_SLICE = 'pepHistoryFiltersSlice',
    APPLICANT_PEP_HISTORY_FILTERS_SLICE = 'applicantPepHistoryFiltersSlice',
}

export enum FilterApplicantPersonNames {
    SEARCH_APPLICANT_FILTERS_SLICE = 'searchApplicantFiltersSlice',
    APPLICANT_PERSON_FILTERS_SLICE = 'applicantPersonFiltersSlice',
}

export enum FilterApplicantOrganizationNames {
    APPLICANT_ORGANIZATION_FILTERS_SLICE = 'applicantOrganizationFiltersSlice',
}

export enum FilterApplicantAlertsNames {
    APPLICANT_ALERTS_FILTERS_SLICE = 'applicantAlertsFiltersSlice',
}

export enum FilterApplicantDocsNames {
    APPLICANT_DOCS_FILTERS_SLICE = 'applicantDocsFiltersSlice',
}

export enum FilterBillingNames {
    BILLING_PAYMENTS_FILTERS_SLICE = 'billingPaymentsFiltersSlice',
    BILLING_OPERATIONS_FILTERS_SLICE = 'billingOperationsFiltersSlice',
}

export enum FilterOrganizationNames {
    ORGANIZATION_FILTERS_SLICE = 'organizationFiltersSlice',
    ORGANIZATION_ACCOUNTS_FILTERS_SLICE = 'organizationAccountsFiltersSlice',
    ORGANIZATION_APPLICANTS_FILTERS_SLICE = 'organizationApplicantsFiltersSlice',
    ORGANIZATION_AML_CHECKS_FILTERS_SLICE = 'organizationAmlChecksFiltersSlice',
    ORGANIZATION_EVENTS_FILTERS_SLICE = 'organizationEventsFiltersSlice',
}

export enum FilterAccountNames {
    ACCOUNT_EVENT_FILTERS_SLICE = 'accountEventFiltersSlice',
    ACCOUNT_FILTERS_SLICE = 'accountFiltersSlice',
}

export enum FilterDataSourcesNames {
    DATA_SOURCES_FILTERS_SLICE = 'dataSourcesFiltersSlice',
}
