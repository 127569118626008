import React from 'react';
import type { IconProps } from '@models/types';

export const CopyIcon: React.FC<IconProps> = ({ className }) => (
    <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={className}
    >
        <path
            d='M5 0C4.44772 0 4 0.447715 4 1C4 1.55228 4.44772 2 5 2H17C17.5523 2 18 2.44772 18 3V15C18 15.5523 18.4477 16 19 16C19.5523 16 20 15.5523 20 15V3C20 1.34315 18.6569 0 17 0H5Z'
            fill='currentColor'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M3 4C1.34315 4 0 5.34315 0 7V17C0 18.6569 1.34315 20 3 20H13C14.6569 20 16 18.6569 16 17V7C16 5.34315 14.6569 4 13 4H3ZM2 7C2 6.44772 2.44772 6 3 6H13C13.5523 6 14 6.44772 14 7V17C14 17.5523 13.5523 18 13 18H3C2.44772 18 2 17.5523 2 17V7Z'
            fill='currentCOlor'
        />
    </svg>
);
