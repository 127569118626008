import { createGlobalStyle } from 'styled-components';

export const DeleteConfirmationPopupGlobalStyles = createGlobalStyle`
  .delete-confirmation-popup {
    min-width: 220px;
    background-color: ${(props) => props.theme.palette.primaryA6};
    border-radius: 8px;
    padding: 16px;

    .confirmation-text {
      text-align: center;
    }

    .actions {
      margin-top: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;

      button {
        width: 90px;
        height: 24px;
        min-height: 24px;
        border-radius: 6px;
      }
    }
  }
`;
