import React from 'react';
import type { IconProps } from '@models/types';

export const ArrowMenuIcon: React.FC<IconProps> = ({ className }) => (
    <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={className}
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M19.75 7C19.75 6.58579 19.4142 6.25 19 6.25C18.5858 6.25 18.25 6.58579 18.25 7V17C18.25 17.4142 18.5858 17.75 19 17.75C19.4142 17.75 19.75 17.4142 19.75 17V7ZM5.31065 11.25L9.06065 7.49999L7.99999 6.43933L2.43933 12L7.99999 17.5607L9.06065 16.5L5.31065 12.75H16.25V11.25H5.31065Z'
            fill='currentColor'
        />
    </svg>
);
