import { apiLogger } from '@hooks/use-logger';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { RootState } from '@store/root-reducer';
import { logOutAdmin } from '@store/slices/shared/logout-action';
import { baseQueryRequest } from '@utils/base-query-request';

import { AdminApiTagEnum } from './enums';

const baseQuery = fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_BASE_URL}`,
    credentials: 'include',
});

const baseQueryWithInterceptor: ReturnType<typeof fetchBaseQuery> = async (
    args,
    api,
    extraOptions,
) => {
    const state = api.getState() as RootState;

    const result = await baseQueryRequest({ state, baseQuery, args, api, extraOptions });

    if (result.error) {
        await apiLogger(state, result);
    }

    if (result.error && result.error.status === 401) {
        await baseQueryRequest({
            state,
            baseQuery,
            args: { url: 'webapi/wl/account/logout', method: 'POST' },
            api,
            extraOptions,
        });
        api.dispatch(logOutAdmin());
    }

    return result;
};

export const adminPanelApi = createApi({
    reducerPath: 'adminPanelApi',
    baseQuery: baseQueryWithInterceptor,
    endpoints: () => ({}),
    tagTypes: [
        AdminApiTagEnum.ACCOUNTS,
        AdminApiTagEnum.ORGANIZATIONS,
        AdminApiTagEnum.ORGANIZATION_ACCOUNTS,
        AdminApiTagEnum.PRODUCT_SETTINGS,
        AdminApiTagEnum.ORGANIZATION_BALANCE,
        AdminApiTagEnum.WL_SETTINGS,
    ],
});
