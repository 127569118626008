import type {
    CreatePaymentModel,
    CreatePaymentRequest,
    CreatePaymentXamasRequest,
    CreatePaymentXamaxModel,
    PaymentHistoryModel,
} from '@models';
import type { PaymentBonusModel } from '@models/payments/payment-bonus-model';
import type { PaymentListRequest } from '@models/payments/payment-list-request';
import type { PaginationListResponse } from '@models/types';

import { dashboardApi } from './dashboard-base-api';

const URL_PREFIX = 'webapi/v3/payments';

export const paymentApiSlice = dashboardApi.injectEndpoints({
    endpoints: (build) => ({
        getPaymentList: build.query<
            PaginationListResponse<PaymentHistoryModel>,
            PaymentListRequest
        >({
            query: ({
                params: {
                    organizationId,
                    page,
                    limit = 20,
                    created_from,
                    created_to,
                    paymentMethod,
                    status,
                },
            }) => ({
                url: `${URL_PREFIX}/${organizationId}/history-filters`,
                params: {
                    page,
                    limit,
                    ...(created_from && created_to && { created_from, created_to }),
                    paymentMethod,
                    status,
                },
            }),
        }),

        getPaymentBonuses: build.query<PaymentBonusModel[], string>({
            query: (organizationId) => ({
                url: `${URL_PREFIX}/${organizationId}/bonuses`,
            }),
        }),
        createPaymentRevolut: build.mutation<CreatePaymentModel, CreatePaymentRequest>({
            query: ({ body, params }) => ({
                method: 'POST',
                url: `${URL_PREFIX}/${params.organizationId}/checkout/revolut`,
                body,
            }),
        }),
        createPaymentXamax: build.mutation<CreatePaymentXamaxModel, CreatePaymentXamasRequest>({
            query: ({ body, params }) => ({
                method: 'POST',
                url: `${URL_PREFIX}/${params.organizationId}/checkout/xamax`,
                body,
            }),
        }),
        getPaymentXamaxInfo: build.query<
            PaymentHistoryModel,
            { organizationId: string; paymentId: string }
        >({
            query: ({ organizationId, paymentId }) =>
                `${URL_PREFIX}/${organizationId}/xamax/${paymentId}`,
        }),
    }),
});

export const {
    useLazyGetPaymentListQuery,
    useGetPaymentBonusesQuery,
    useCreatePaymentRevolutMutation,
    useCreatePaymentXamaxMutation,
    useLazyGetPaymentXamaxInfoQuery,
} = paymentApiSlice;
