export enum FilterFieldArrayOptionNamesEnum {
    DOCUMENT_TYPES = 'documentTypes',
    PROFILES = 'profiles',
    STATUSES = 'statuses',
    RISK_SCORES = 'riskScores',
    PAYMENT_METHODS = 'paymentMethods',
    PAYMENT_STATUSES = 'paymentStatuses',
    ORG_ROLES = 'orgRoles',
    COUNTRIES = 'countries',
    CITIZENSHIPS = 'citizenships',
    APPLICANT_VERIFICATION_STATUSES = 'applicantVerificationStatuses',
    EVENT_TYPES = 'eventTypes',
    DOCUMENT_TYPES_PHOTO = 'documentTypesPhoto',
    TAGS = 'tags',
    APPLICANT_ALERT_TYPE = 'applicantAlertTypes',
}

// export enum FilterFieldStringNamesEnum {
//     COUNTRY_AUTOCOMPLETE = 'countryAutocomplete',
// }

export enum FilterFieldOptionNamesEnum {
    COUNTRY = 'country',
    ACCOUNT_EMAIL = 'accountEmail',
    OPERATION_TYPE = 'operationType',
}

export enum FilterFieldDateNamesEnum {
    DATE_RANGE = 'dateRange',
    DATE = 'dateOfBirth',

    // can I change this to dateRange?, - it is better to be changed
    CREATED_AT_DATE_RANGE = 'createdAtDateRange',
    LAST_VISIT_RANGE = 'lastVisitRange',
    DOB_DATE_RANGE = 'dobDateRange',
}

export enum FilterFieldBooleanNamesEnum {
    ONGOING = 'ongoing',
    ONLY_DOC_VER_MANUAL_STATUS = 'onlyDocVerManualStatus',
}
