import type { FilterFieldArrayOptionNamesEnum, FilterFieldDateNamesEnum } from '@models/enums';
import { FilterAccountNames } from '@models/enums';
import type { AutocompleteOption, DateRangeValue, FilterMenuProps } from '@models/types';
import { createSlice } from '@reduxjs/toolkit';
import { getFiltersReducer } from '@utils/filters/util';

import type { EventTypeEnum } from '../../models';

import type { OrganizationEventsFiltersState } from './organization-events-filters-slice';

export type AccountEventsFiltersState = {
    [FilterFieldDateNamesEnum.DATE_RANGE]: DateRangeValue;
    [FilterFieldArrayOptionNamesEnum.EVENT_TYPES]: Array<AutocompleteOption<EventTypeEnum>>;
};

export type AccountEventFiltersSliceState = FilterMenuProps<AccountEventsFiltersState>;

export const accountEventsFiltersInitState: OrganizationEventsFiltersState = {
    dateRange: null,
    eventTypes: [],
};

const initialState: AccountEventFiltersSliceState = {
    isOpen: false,
    applyCount: 0,
    filters: accountEventsFiltersInitState,
};

export const accountEventFiltersSlice = createSlice({
    name: FilterAccountNames.ACCOUNT_EVENT_FILTERS_SLICE,
    initialState,
    reducers: getFiltersReducer<AccountEventsFiltersState, AccountEventFiltersSliceState>(
        accountEventsFiltersInitState,
    ),
});
