import React from 'react';
import type { IconProps } from '@models/types';

export const RefreshIcon: React.FC<IconProps> = (props) => (
    <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M10.2021 3.06106C9.8804 3.0515 9.55738 3.064 9.23534 3.09892C9.19659 3.10312 9.15798 3.10445 9.11979 3.10307C8.783 3.09086 8.47906 2.86708 8.40652 2.53113C8.32504 2.15378 8.56624 1.77921 8.94934 1.7316C8.99466 1.72597 9.04001 1.7207 9.08537 1.71581C9.45056 1.67641 9.81679 1.66096 10.1817 1.66909C11.562 1.69983 12.9235 2.06792 14.1387 2.75356L14.1548 2.76264C15.9344 3.7718 17.2732 5.392 17.915 7.31272L17.9191 7.32481C18.5593 9.25082 18.4539 11.342 17.6232 13.1961L17.6167 13.2106C17.19 14.1578 16.5892 15.0086 15.8527 15.7247H16.2481C16.8004 15.7247 17.2481 16.1724 17.2481 16.7247C17.2481 17.277 16.8004 17.7247 16.2481 17.7247H13.4682C13.3892 17.7247 13.3123 17.7155 13.2386 17.6982C13.2331 17.697 13.2276 17.6956 13.2221 17.6943C12.7131 17.568 12.3951 17.0728 12.4809 16.5646C12.4875 16.5237 12.4966 16.4836 12.508 16.4446L13.1928 13.7463C13.3287 13.2108 13.8733 12.8845 14.4091 13.0174C14.945 13.1503 15.2692 13.6922 15.1333 14.2277L15.0544 14.5386C15.5811 13.9835 16.0144 13.3412 16.331 12.6345L16.3375 12.62C17.0199 11.0874 17.1065 9.36115 16.5808 7.76969L16.5768 7.75759C16.0469 6.16354 14.9394 4.81781 13.4661 3.97717L13.45 3.96805C12.4525 3.40238 11.3357 3.09474 10.2021 3.06106Z'
            fill='currentColor'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M9.79641 16.9399C10.118 16.9495 10.4411 16.937 10.7631 16.9021C10.8019 16.8979 10.8405 16.8966 10.8787 16.898C11.2155 16.9102 11.5194 17.134 11.5919 17.47C11.6734 17.8473 11.4321 18.2219 11.049 18.2695C11.0037 18.2751 10.9584 18.2803 10.913 18.2852C10.5478 18.3246 10.1815 18.34 9.81667 18.3319C8.39129 18.3 6.98669 17.9084 5.74298 17.18L5.72677 17.1705C3.92371 16.109 2.59392 14.4166 2.00295 12.4317L1.99965 12.4206C1.41034 10.4308 1.60668 8.29524 2.54928 6.44211L2.55711 6.42676C2.96822 5.62274 3.50684 4.89803 4.14634 4.27621H3.75095C3.19867 4.27621 2.75095 3.82849 2.75095 3.27621C2.75095 2.72392 3.19867 2.27621 3.75095 2.27621H6.53085C6.61097 2.27621 6.68888 2.28563 6.76355 2.30342C6.76801 2.30447 6.77247 2.30554 6.77693 2.30664C7.28588 2.43289 7.60391 2.92805 7.51811 3.43628C7.51153 3.47719 7.50247 3.51727 7.49108 3.55637L6.80626 6.25457C6.67035 6.79007 6.12577 7.11642 5.58992 6.9835C5.05406 6.85058 4.72985 6.30872 4.86576 5.77322L4.94465 5.46239C4.49471 5.93649 4.11213 6.47521 3.81208 7.06511L3.80428 7.08047C3.02977 8.61275 2.86837 10.3759 3.35225 12.02L3.35553 12.0311C3.84328 13.6779 4.94299 15.0831 6.43525 15.967L6.45144 15.9766C7.47265 16.5777 8.62524 16.905 9.79641 16.9399Z'
            fill='currentColor'
        />
    </svg>
);
