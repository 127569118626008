import { createGlobalStyle } from 'styled-components';

export const SwitchFilterTooltipGlobalStyles = createGlobalStyle`
  .switch-filter-tooltip {
    &.MuiTooltip-popper {
      .MuiTooltip-tooltip {
        ${(props) => props.theme.typography.smallText};
        background: ${(props) => props.theme.palette.primaryA7};
        padding: 8px 12px;
        max-width: 265px;

      }
      .MuiTooltip-arrow {
        color: ${(props) => props.theme.palette.primaryA7};
      }
    }
  }
`;
