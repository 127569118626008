import type { DateRangeValue, FilterMenuProps } from '@models/types';
import { createSlice } from '@reduxjs/toolkit';
import { getFiltersReducer } from '@utils/filters/util';

export type PaymentHistoryFiltersState = {
    dateRange: DateRangeValue;
};

export type PaymentHistoryFiltersSliceState = FilterMenuProps<PaymentHistoryFiltersState>;

export const paymentHistoryFiltersInitState: PaymentHistoryFiltersState = {
    dateRange: null,
};

const initialState: PaymentHistoryFiltersSliceState = {
    isOpen: false,
    applyCount: 0,
    filters: paymentHistoryFiltersInitState,
};

export const paymentHistoryFiltersSlice = createSlice({
    name: 'paymentHistoryFiltersSlice',
    initialState,
    reducers: getFiltersReducer<PaymentHistoryFiltersState, PaymentHistoryFiltersSliceState>(
        paymentHistoryFiltersInitState,
    ),
});
