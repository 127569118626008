import type { OperationHistoryGraphLastMonth, OperationHistoryTotalModel } from '@models';
import { operationApiSlice } from '@modules/dashboard/api/operation-api';
import { createSlice } from '@reduxjs/toolkit';
import { operationsHistoryMonth } from '@utils/operations';

import { sessionSlice } from './session-slice';

export type OperationHistoryState = {
    operationsHistoryTotal?: OperationHistoryTotalModel;
    operationHistoryGraphLastMonth?: OperationHistoryGraphLastMonth;
};

const initialState: OperationHistoryState = {
    operationsHistoryTotal: undefined,
    operationHistoryGraphLastMonth: undefined,
};

export const operationHistorySlice = createSlice({
    name: 'operationHistory',
    initialState,
    reducers: {
        clearSession: (state) => {
            state.operationsHistoryTotal = undefined;
            state.operationHistoryGraphLastMonth = undefined;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(sessionSlice.actions.clearSession, (state) => {
            state.operationsHistoryTotal = undefined;
            state.operationHistoryGraphLastMonth = undefined;
        });
        builder.addMatcher(
            operationApiSlice.endpoints.getOperationsHistoryLastMonth.matchFulfilled,
            (state, action) => {
                if (action.payload) {
                    const { data } = action.payload;
                    const { operationHistoryGraphLastMonth, operationHistoryTotal } =
                        operationsHistoryMonth(data);

                    state.operationsHistoryTotal = operationHistoryTotal;
                    state.operationHistoryGraphLastMonth = operationHistoryGraphLastMonth;
                }
            },
        );
    },
});
