import React from 'react';
import type { IconProps } from '@models/types';

export const GovernmentIcon: React.FC<IconProps> = (props) => (
    <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M12 2C11.4477 2 11 2.44772 11 3V7.05994C9.14697 7.28011 7.33287 8.09375 5.90923 9.28273C4.22911 10.6859 3 12.6961 3 15C3 15.5523 3.44772 16 4 16H5V21H3C2.44772 21 2 21.4477 2 22C2 22.5523 2.44772 23 3 23L21 23C21.5523 23 22 22.5523 22 22C22 21.4477 21.5523 21 21 21H19V16H20C20.5523 16 21 15.5523 21 15C21 12.6961 19.7709 10.6859 18.0908 9.28273C16.6671 8.09375 14.853 7.28011 13 7.05994V7H17C17.5523 7 18 6.55228 18 6V3C18 2.44772 17.5523 2 17 2H12ZM11 21H13V16H11V21ZM9 16V21H7V16H9ZM18.8858 14C18.6137 12.8106 17.8695 11.7037 16.8087 10.8178C15.4545 9.68677 13.6771 9 12 9C10.3229 9 8.54549 9.68677 7.19127 10.8178C6.13045 11.7037 5.38634 12.8106 5.1142 14H18.8858ZM17 16V21H15V16H17ZM16 5H13V4H16V5Z'
            fill='currentColor'
        />
    </svg>
);
