import type {
    AuthChangePassword,
    AuthForgotPassword,
    AuthGoogleLogin,
    AuthLogin,
    AuthRegistration,
    AuthUserInfo,
    Message,
} from '../../auth/store';

import { dashboardApi } from './dashboard-base-api';

const URL_PREFIX = 'webapi/account/';

export const dashboardAccountApiSlice = dashboardApi.injectEndpoints({
    endpoints: (build) => ({
        getUserInfo: build.query<AuthUserInfo, void>({
            query: () => `${URL_PREFIX}info`,
            transformResponse: (authInfo: AuthUserInfo) => {
                if (authInfo?.logo_url) {
                    return {
                        ...authInfo,
                        logo_url: `${authInfo.logo_url}?t=${new Date().getTime()}`,
                    };
                }

                return authInfo;
            },
        }),
        uploadUserPhoto: build.mutation<{ message: string }, File>({
            query: (file) => {
                const formData = new FormData();

                formData.append('file', file);

                return {
                    method: 'PUT',
                    url: `${URL_PREFIX}settings/logo`,
                    formData: true,
                    body: formData,
                };
            },
        }),
        deleteUserPhoto: build.mutation<Message, void>({
            query: () => ({
                method: 'DELETE',
                url: `${URL_PREFIX}settings/logo`,
            }),
        }),
        changeUserFullName: build.mutation<Message, { full_name: string }>({
            query: (body) => ({
                method: 'POST',
                url: `${URL_PREFIX}settings/change-name`,
                body,
            }),
        }),
        changeUserPassword: build.mutation<
            Message,
            { old_password?: string; new_password: string }
        >({
            query: (body) => ({
                method: 'POST',
                url: `${URL_PREFIX}reset`,
                body,
            }),
        }),
        registerAccount: build.mutation<Message | AuthUserInfo, AuthRegistration>({
            query: (body) => ({
                method: 'POST',
                url: `${URL_PREFIX}register`,
                body,
            }),
        }),
        login: build.mutation<AuthUserInfo, AuthLogin>({
            query: (body) => ({
                method: 'POST',
                url: `${URL_PREFIX}login`,
                body,
            }),
        }),
        googleLogin: build.mutation<AuthUserInfo, AuthGoogleLogin>({
            query: (body) => ({
                method: 'POST',
                url: `${URL_PREFIX}google/oauth`,
                body,
            }),
        }),
        resetPasswordRequest: build.mutation<Message, AuthForgotPassword>({
            query: (body) => ({
                method: 'POST',
                url: `${URL_PREFIX}reset/request`,
                body,
            }),
        }),
        changePasswordByToken: build.mutation<Message, AuthChangePassword>({
            query: (body) => ({
                method: 'POST',
                url: `${URL_PREFIX}reset`,
                body,
            }),
        }),
        logout: build.mutation<Message, void>({
            query: (body) => ({
                method: 'POST',
                url: `${URL_PREFIX}logout`,
                body,
            }),
        }),
    }),
});

export const {
    useLazyGetUserInfoQuery,
    useUploadUserPhotoMutation,
    useDeleteUserPhotoMutation,
    useChangeUserFullNameMutation,
    useChangeUserPasswordMutation,
    useRegisterAccountMutation,
    useResetPasswordRequestMutation,
    useChangePasswordByTokenMutation,
    useLoginMutation,
    useGoogleLoginMutation,
    useLogoutMutation,
} = dashboardAccountApiSlice;
