import React from 'react';
import type { IconProps, ThemeProps } from '@models/types';
import useTheme from '@mui/styles/useTheme';

export const PlusCircleIcon: React.FC<IconProps & { fillColor?: string }> = ({
    className,
    fillColor,
    ...rest
}) => {
    const theme = useTheme<ThemeProps>();

    return (
        <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            className={className}
            {...rest}
        >
            <rect width='24' height='24' rx='12' fill={fillColor || theme.palette.primaryA1} />
            <path
                d='M13 17.6576C13 18.2099 12.5523 18.6576 12 18.6576C11.4477 18.6576 11 18.2099 11 17.6576V13.0008L6.34315 13.0008C5.79086 13.0008 5.34315 12.553 5.34315 12.0008C5.34315 11.4485 5.79086 11.0008 6.34315 11.0008L11 11.0008L11 6.3439C11 5.79162 11.4477 5.3439 12 5.3439C12.5523 5.3439 13 5.79162 13 6.3439L13 11.0008L17.6569 11.0008C18.2091 11.0008 18.6569 11.4485 18.6569 12.0008C18.6569 12.553 18.2091 13.0008 17.6569 13.0008L13 13.0008V17.6576Z'
                fill='currentColor'
            />
        </svg>
    );
};
