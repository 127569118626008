import React from 'react';
import type { IconProps } from '@models/types';

export const DashboardIcon: React.FC<IconProps> = ({ ...props }) => (
    <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M2 7C2 5.11438 2 4.17157 2.58579 3.58579C3.17157 3 4.11438 3 6 3H18C19.8856 3 20.8284 3 21.4142 3.58579C22 4.17157 22 5.11438 22 7C22 7.94281 22 8.41421 21.7071 8.70711C21.4142 9 20.9428 9 20 9H4C3.05719 9 2.58579 9 2.29289 8.70711C2 8.41421 2 7.94281 2 7ZM11 11H20C20.9428 11 21.4142 11 21.7071 11.2929C22 11.5858 22 12.0572 22 13V18C22 19.8856 22 20.8284 21.4142 21.4142C20.8284 22 19.8856 22 18 22H11V11ZM2.29289 11.2929C2 11.5858 2 12.0572 2 13V18C2 19.8856 2 20.8284 2.58579 21.4142C3.17157 22 4.11438 22 6 22H9V11H4C3.05719 11 2.58579 11 2.29289 11.2929Z'
            fill='currentColor'
        />
    </svg>
);
