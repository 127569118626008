import type { FC } from 'react';
import React from 'react';
import type { IconProps } from '@models/types';

export const FolderSearchIcon: FC<IconProps> = () => (
    <svg width='98' height='91' viewBox='0 0 98 91' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M82.2945 4.42322H34.8633V12.0684H82.2945V4.42322Z' fill='black' />
        <path
            d='M0.799805 67.8139V3.14837V0.599976H31.9962L39.9544 12.0678H85.1573V67.8139H0.799805Z'
            fill='white'
            stroke='black'
            strokeWidth='0.853358'
        />
        <rect
            width='10.2008'
            height='27.3554'
            transform='matrix(0.744487 -0.667637 0.670633 0.741789 57.9395 62.2327)'
            fill='black'
        />
        <rect
            width='7.6506'
            height='3.84685'
            transform='matrix(0.744487 -0.667637 0.670633 0.741789 56.3115 58.5322)'
            fill='white'
            stroke='black'
            strokeWidth='0.8'
        />
        <rect
            width='5.94992'
            height='5.12855'
            transform='matrix(0.744487 -0.667637 0.670633 0.741789 53.5039 54.1561)'
            fill='white'
            stroke='black'
            strokeWidth='0.8'
        />
        <path
            d='M41.9792 57.3379C32.9921 55.2589 27.3316 46.3002 29.3612 37.3676C31.3908 28.435 40.3537 22.859 49.3408 24.938C58.3279 27.0171 63.9884 35.9758 61.9588 44.9084C59.9293 53.841 50.9663 59.417 41.9792 57.3379ZM49.1783 25.6533C40.588 23.6661 32.021 28.9957 30.0811 37.5339C28.1411 46.0721 33.5515 54.6351 42.1418 56.6223C50.7321 58.6095 59.2991 53.2799 61.2391 44.7417C63.179 36.2035 57.7686 27.6405 49.1783 25.6533Z'
            fill='black'
        />
        <path
            d='M49.1783 25.6533C40.588 23.6661 32.021 28.9957 30.0811 37.5339C28.1411 46.0721 33.5515 54.6351 42.1418 56.6223C50.7321 58.6095 59.2991 53.2799 61.2391 44.7417C63.179 36.2035 57.7686 27.6405 49.1783 25.6533Z'
            fill='white'
        />
    </svg>
);
