import {
    FilterApplicantPersonNames,
    FilterFieldDateNamesEnum,
    FilterFieldOptionNamesEnum,
} from '@models/enums';
import type { AutocompleteOption, DatePickerValue, FilterMenuProps } from '@models/types';
import { createSlice } from '@reduxjs/toolkit';
import { getFiltersReducer } from '@utils/filters/util';

export type ApplicantPersonFiltersState = {
    [FilterFieldOptionNamesEnum.COUNTRY]?: AutocompleteOption<string>;
    [FilterFieldDateNamesEnum.DATE]?: DatePickerValue;
};

export type ApplicantPersonFiltersSliceState = FilterMenuProps<ApplicantPersonFiltersState>;

export const applicantPersonFiltersInitState: ApplicantPersonFiltersState = {
    [FilterFieldOptionNamesEnum.COUNTRY]: undefined,
    [FilterFieldDateNamesEnum.DATE]: null,
};

const initialState: ApplicantPersonFiltersSliceState = {
    isOpen: false,
    applyCount: 0,
    filters: applicantPersonFiltersInitState,
    searchQuery: '',
};

export const applicantPersonFiltersSlice = createSlice({
    name: FilterApplicantPersonNames.APPLICANT_PERSON_FILTERS_SLICE,
    initialState,
    reducers: getFiltersReducer<ApplicantPersonFiltersState, ApplicantPersonFiltersSliceState>(
        applicantPersonFiltersInitState,
    ),
});
