export const getPropertyStartWith = (obj: Record<string, unknown> = {}, str: string) => {
    let prop = '';

    Object.keys(obj).forEach((key) => {
        if (key.includes(str)) {
            prop = key;
        }
    });

    return prop;
};
