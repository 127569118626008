import React from 'react';
import type { IconProps } from '@models/types';

export const EditIcon: React.FC<IconProps> = ({ className }) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        className={className}
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M18.6851 1.51729C18.3604 1.45615 18.0265 1.55931 17.7929 1.79291L9.29292 10.2929C9.18315 10.4027 9.10044 10.5365 9.05134 10.6838L7.55134 15.1838C7.43157 15.5431 7.52509 15.9393 7.79292 16.2071C8.06075 16.475 8.45692 16.5685 8.81626 16.4487L13.3163 14.9487C13.4635 14.8996 13.5974 14.8169 13.7071 14.7071L22.2071 6.20712C22.4305 5.98375 22.5352 5.66796 22.4895 5.35538C22.34 4.33239 22.0726 3.41689 21.3905 2.7246C20.7162 2.04015 19.7833 1.72408 18.6851 1.51729ZM10.8741 11.5402L18.8214 3.59286C19.4614 3.75444 19.7772 3.93677 19.9659 4.12829C20.1458 4.31095 20.302 4.59524 20.4246 5.16127L12.4598 13.126L10.0812 13.9189L10.8741 11.5402Z'
            fill='currentColor'
        />
        <path
            d='M6 2.00004H10C11.5 2.00004 11.5 4.00004 10 4.00004H6C4.89543 4.00004 4 4.89547 4 6.00004V18C4 19.1046 4.89543 20 6 20H18C19.1046 20 20 19.1046 20 18V16C20 14.5 22 14.5 22 16V18C22 20.2092 20.2091 22 18 22H6C3.79086 22 2 20.2092 2 18V6.00004C2 3.7909 3.79086 2.00004 6 2.00004Z'
            fill='currentColor'
        />
    </svg>
);
