import type { OrganizationRoleEnum } from '@models/enums';

export enum RequestStatus {
    LOADING = 'loading',
    SUCCESS = 'success',
    ERROR = 'error',
    EXPECTATION = 'expectation',
    REDIRECT = 'redirect',
    REDIRECT_RESET_PASSWORD = 'redirect_reset_password',
}

export type BaseAuthData = {
    ref_code?: string;
    domain_referer?: string;
    utm_medium?: string;
    utm_campaign?: string;
    utm_source?: string;
    utm_term?: string;
    utm_content?: string;
    gclid?: string;
};

export type AuthRegistration = BaseAuthData & {
    email: string;
    password: string;
    full_name: string;
};
export type AuthForgotPassword = Required<Pick<AuthRegistration, 'email'>>;

export type AuthChangePassword = {
    new_password: string;
    token?: string;
};

export type AuthSetupPassword = Required<Pick<AuthRegistration, 'password'>>;

export type AuthLogin = Required<Pick<AuthRegistration, 'email' | 'password'>>;

export type AuthGoogleLogin = BaseAuthData & {
    token: string;
};

export type ErrorProps = {
    data: {
        message?: string;
    };
    status: number;
};

export type PayloadActionError = {
    type: string;
    payload?: ErrorProps;
    meta: {
        arg: {
            originalArgs: Record<string, string>;
        };
    };
    error?: object;
};

export type Message = {
    message: string;
};

enum RegTypeEnum {
    MANUAL = 'MANUAL',
    INVITE = 'INVITE',
    GMAIL = 'GMAIL',
}

export type AuthUserInfo = {
    account_id?: string;
    email?: string;
    full_name?: string;
    logo_url?: string;
    global_role?: OrganizationRoleEnum;
    created_at: string;
    last_login_at: string;
    no_password?: boolean;
    reg_type: RegTypeEnum;
};

export type AuthUserRegistrationSliceState = {
    status: RequestStatus;
    message?: string;
    email?: string;
};

export type AuthUserInfoSliceState = {
    status: RequestStatus;
    info: AuthUserInfo | null;
};

export type AuthUserGoogleLoginSliceState = {
    status: RequestStatus;
};

export type AuthUserSliceState = {
    authUserGoogle: AuthUserGoogleLoginSliceState;
    registration: AuthUserRegistrationSliceState;
    user: AuthUserInfoSliceState;
};
