import type {
    FilterFieldArrayOptionNamesEnum,
    FilterFieldDateNamesEnum,
    OrganizationRoleEnum,
} from '@models/enums';
import { FilterOrganizationNames } from '@models/enums';
import type { AutocompleteOption, DateRangeValue, FilterMenuProps } from '@models/types';
import { createSlice } from '@reduxjs/toolkit';
import { getFiltersReducer } from '@utils/filters/util';

export type OrganizationAccountsFiltersState = {
    [FilterFieldDateNamesEnum.LAST_VISIT_RANGE]: DateRangeValue;
    [FilterFieldArrayOptionNamesEnum.ORG_ROLES]: Array<AutocompleteOption<OrganizationRoleEnum>>;
    [FilterFieldArrayOptionNamesEnum.COUNTRIES]: Array<AutocompleteOption<string>>;
};

export type OrganizationAccountsFiltersStateSlice =
    FilterMenuProps<OrganizationAccountsFiltersState>;

export const organizationAccountsFiltersInitState = {
    lastVisitRange: null,
    orgRoles: [],
    countries: [],
};

const initialState: OrganizationAccountsFiltersStateSlice = {
    isOpen: false,
    applyCount: 0,
    filters: organizationAccountsFiltersInitState,
};

export const organizationAccountsFiltersSlice = createSlice({
    name: FilterOrganizationNames.ORGANIZATION_ACCOUNTS_FILTERS_SLICE,
    initialState,
    reducers: getFiltersReducer<
        OrganizationAccountsFiltersState,
        OrganizationAccountsFiltersStateSlice
    >(organizationAccountsFiltersInitState),
});
