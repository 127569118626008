import React from 'react';
import type { IconProps } from '@models/types';

export const ClockIcon: React.FC<IconProps> = (props) => (
    <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
    >
        <circle cx='10' cy='10' r='9' stroke='currentColor' strokeWidth='2' />
        <path
            d='M10 6V10.8L13 14'
            stroke='currentColor'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);
