import type { CreatePaymentModel, CreatePaymentXamaxModel, OrganizationInfoModel } from '@models';
import type { OperationBalanceStatusEnum } from '@models/enums';
import { OrganizationRoleEnum } from '@models/enums';
import type { OperationBalanceModel } from '@models/operation/operation-balance-model';
import { operationApiSlice } from '@modules/dashboard/api/operation-api';
import { organizationsApiSlice } from '@modules/dashboard/api/organizations-api';
import { paymentApiSlice } from '@modules/dashboard/api/payment-api';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { logOut } from './shared/logout-action';

export const INIT_DEPOSIT = 1000;

export type SessionState = {
    organizationId: string;
    requestedOrganizationId: string;
    organizationsUpdateDate: string;
    token: string;
    revolutPayment: Partial<CreatePaymentModel> & {
        amount?: number;
    };
    xamaxPayment: {
        data?: CreatePaymentXamaxModel;
        amount?: number;
    };
    balanceStatus?: OperationBalanceStatusEnum;
    organizationsList: OrganizationInfoModel[];
    organizationsOwnedCount: number;
    organizationInfo?: OrganizationInfoModel;
    organizationCreatedDate?: string;
    billingContractDueDate?: string;
    hasOperations?: boolean;
    hasDeposits?: boolean;
    isSandbox: boolean;
    errorGlobalText?: string;
};

const initialState: SessionState = {
    organizationId: '',
    requestedOrganizationId: localStorage.getItem('dashboard-organizationId') || '',
    organizationsUpdateDate: new Date().getTime().toString(),
    token: '',
    balanceStatus: undefined,
    revolutPayment: {
        amount: undefined,
        id: undefined,
        expires_at: undefined,
        items: undefined,
    },
    xamaxPayment: {},
    organizationInfo: undefined,
    organizationsList: [],
    organizationsOwnedCount: 0,
    hasOperations: undefined,
    hasDeposits: undefined,
    billingContractDueDate: undefined,
    isSandbox: localStorage.getItem('dashboard-is-sandbox') === 'true',
    errorGlobalText: '',
};

export const sessionSlice = createSlice({
    name: 'dashboardSessionSlice',
    initialState,
    reducers: {
        clearSession: (state) => {
            localStorage.clear();
            localStorage.setItem('dashboard-organizationId', state.organizationId);

            state.organizationId = '';
            state.token = '';
            state.revolutPayment = {
                amount: undefined,
                id: undefined,
                expires_at: undefined,
                items: undefined,
            };
            state.xamaxPayment = {};
            state.organizationInfo = undefined;
            state.balanceStatus = undefined;
            state.organizationCreatedDate = undefined;
            state.hasOperations = undefined;
            state.hasDeposits = undefined;
            state.organizationsList = [];
            state.organizationsOwnedCount = 0;
            state.billingContractDueDate = undefined;
            state.isSandbox = false;
            state.errorGlobalText = '';
        },
        setOrganizationInfo: (state, { payload }: PayloadAction<OrganizationInfoModel>) => {
            state.organizationInfo = payload;
        },
        setRequestedOrganizationId: (state, { payload }: PayloadAction<string>) => {
            state.requestedOrganizationId = payload;
        },
        setOrganizationId: (state, { payload }: PayloadAction<string>) => {
            localStorage.setItem('dashboard-organizationId', payload);
            state.organizationId = payload;
        },
        setOrganizationsList: (state, action: PayloadAction<OrganizationInfoModel[]>) => {
            state.organizationsList = action.payload;
            let count = 0;

            if (Array.isArray(state.organizationsList) && state.organizationsList.length) {
                // eslint-disable-next-line no-restricted-syntax
                for (const organization of state.organizationsList) {
                    if (organization.organization_role === OrganizationRoleEnum.OWNER) {
                        count += 1;
                    }
                }
            }
            state.organizationsOwnedCount = count;
        },
        setIsSandbox: (state, { payload }: PayloadAction<boolean>) => {
            state.isSandbox = payload;
            localStorage.setItem('dashboard-is-sandbox', payload.toString());
        },
        setRevolutPaymentAmount: (state, { payload }: PayloadAction<number>) => {
            state.revolutPayment.amount = payload;
        },
        setXamaxPaymentAmount: (state, { payload }: PayloadAction<number>) => {
            state.xamaxPayment.amount = payload;
        },
        clearXamaxPayment: (state) => {
            state.xamaxPayment = {};
        },
        clearRevolutPayment: (state) => {
            state.revolutPayment = {
                amount: undefined,
                id: undefined,
                expires_at: undefined,
                items: undefined,
            };
        },
        setErrorGlobalText: (state, { payload }: PayloadAction<string>) => {
            state.errorGlobalText = payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(logOut.type, (state) => {
            sessionSlice.caseReducers.clearSession(state);
        });
        builder.addMatcher(
            organizationsApiSlice.endpoints.getInitialOrganizationInfo.matchFulfilled,
            (state, action) => {
                if (action.payload) {
                    sessionSlice.caseReducers.setOrganizationId(state, {
                        ...action,
                        payload: action.payload.organization_id,
                    });
                    sessionSlice.caseReducers.setOrganizationInfo(state, action);
                }
            },
        );
        builder.addMatcher(
            isAnyOf(
                organizationsApiSlice.endpoints.createOrganization.matchFulfilled,
                organizationsApiSlice.endpoints.updateOrganization.matchFulfilled,
                organizationsApiSlice.endpoints.acceptInvitation.matchFulfilled,
                organizationsApiSlice.endpoints.leaveFromOrganization.matchFulfilled,
            ),
            (state) => {
                state.organizationsUpdateDate = new Date().getTime().toString();
            },
        );
        builder.addMatcher(
            isAnyOf(
                operationApiSlice.endpoints.getInitialBalance.matchFulfilled,
                operationApiSlice.endpoints.getBalance.matchFulfilled,
            ),
            // eslint-disable-next-line complexity
            (state, action: PayloadAction<OperationBalanceModel>) => {
                if (!action.payload) {
                    return;
                }
                const balanceData = action.payload;

                state.hasOperations = balanceData.created_at !== balanceData.updated_at;
                state.hasDeposits = state.hasOperations || balanceData.balance > INIT_DEPOSIT;
                state.balanceStatus = balanceData.status;
                state.organizationCreatedDate = balanceData.created_at;
            },
        );

        builder.addMatcher(
            paymentApiSlice.endpoints.createPaymentRevolut.matchFulfilled,
            (state, action: PayloadAction<CreatePaymentModel>) => {
                if (action.payload != null) {
                    state.revolutPayment.id = action.payload.id;
                    state.revolutPayment.expires_at = action.payload.expires_at;
                    state.revolutPayment.items = action.payload.items;
                }
            },
        );
        builder.addMatcher(
            paymentApiSlice.endpoints.createPaymentXamax.matchFulfilled,
            (state, action: PayloadAction<CreatePaymentXamaxModel>) => {
                if (action.payload != null) {
                    state.xamaxPayment.data = action.payload;
                }
            },
        );
    },
});
