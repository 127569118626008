import React from 'react';
import type { IconProps } from '@models/types';

export const ExternalLinkIcon: React.FC<IconProps> = (props) => (
    <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
    >
        <path
            d='M9 2C7.5 2 6 2 6 2C3.79086 2 2 3.79086 2 6V18C2 20.2091 3.79086 22 6 22H18C20.2091 22 22 20.2091 22 18C22 18 22 16.5 22 15C22 13.5 20 13.5 20 15C20 16.5 20 18 20 18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18V6C4 4.89543 4.89543 4 6 4C6 4 7.5 4 9 4C10.5 4 10.5 2 9 2Z'
            fill='currentColor'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M19.9998 9.00012C19.9998 9.55241 20.4476 10.0001 20.9998 10.0001C21.5521 10.0001 21.9998 9.55242 21.9998 9.00013L21.9999 3.00002L21.9999 2.00001L20.9999 2.00002L14.9999 2.00005C14.4476 2.00006 13.9999 2.44777 13.9999 3.00006C13.9999 3.55234 14.4476 4.00006 14.9999 4.00005L18.615 4.00003L10.5204 12.4502C10.1384 12.849 10.152 13.482 10.5508 13.8641C10.9496 14.2461 11.5827 14.2325 11.9647 13.8337L19.9999 5.44559L19.9998 9.00012Z'
            fill='currentColor'
        />
    </svg>
);
