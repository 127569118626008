export const ROUTES = {
    DEFAULT: '/',
    DEFAULT_EMPTY: '*',
    SIGN_IN: '/sign-in',
    SIGN_UP: '/sign-up',
    FORGOT_PASSWORD: '/forgot-password',
    CHANGE_PASSWORD: '/change-password',
    SETUP_PASSWORD: '/setup-password',
    VERIFY_EMAIL: '/verify',
    RESET_EMAIL: '/reset',
    AUTH_REST: '/auth/reset/',
    AUTH_ACTIVATED: '/auth/activated',
    DASHBOARD: '/dashboard',
    ONBOARDING: '/onboarding',
    CONTACT_US: '/contact-us',
    API: {
        ROOT: '/api/',
        SETTINGS: '/api/settings',
        DOCS: '/api/docs',
    },
    APPLICANTS: {
        ROOT: '/applicants/',
        CREATE_APPLICANT: '/applicants/create-applicant',
        DETAILS: '/applicants/details/',
        REPORT: '/applicants/report',
        BULK_UPLOAD: '/applicants/bulk-upload',
        DOCVER_RESULT: '/applicants/docver-request/',
    },
    DOCVER: {
        ROOT: '/docs-verification/',
        VERIFICATION: '/docs-verification/verification/',
        RESULTS: '/docs-verification/results',
        RESULT: '/docs-verification/result/',
        CREATE: '/docs-verification/create',
        PROFILES: '/docs-verification/verification-profiles',
        PROFILES_CREATE: '/docs-verification/verification-profiles/create',
        PROFILES_EDIT: '/docs-verification/verification-profiles/edit/',
    },
    PROFILES: {
        ROOT: '/profiles',
        CREATE: '/profiles/create',
        EDIT: '/profiles/edit/',
    },
    PEPS: {
        ROOT: '/pep-sanctions/',
        REPORTS: '/pep-sanctions/reports',
        REPORTS_RESULT: '/pep-sanctions/reports/search-results/',
        HISTORY: '/pep-sanctions/request-history/',
        DATA_SOURCES: '/pep-sanctions/data-sources',
        CREATE_REPORT: '/pep-sanctions/create-report',
    },
    POA_GEN: {
        ROOT: '/poa-gen',
        CREATE: '/poa-gen/create',
        HISTORY: '/poa-gen/history',
    },
    BILLING: {
        ROOT: '/billing',
        TOPUP: '/billing/topup',
        CRYPTO: '/billing/crypto',
    },
    SETTINGS: {
        ROOT: '/settings/',
        COMPANY_SETTINGS: '/settings/company-settings',
        USER_SETTINGS: '/settings/user-settings',
        ADD_ORGANIZATION: '/settings/company-settings/add-organization',
        INVITE_TEAM_MEMBERS: '/settings/company-settings/invite-team-members',
        EDIT_ORGANIZATION: '/settings/company-settings/edit-organization',
    },
    ORGANIZATIONS: {
        ROOT: '/organizations/',
        CREATE: '/organizations/create/',
    },
    ACCOUNTS: {
        ROOT: '/accounts/',
    },
};
