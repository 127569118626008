import React from 'react';
import type { IconProps } from '@models/types';

export const LoaderIcon: React.FC<IconProps> = (iconProps) => (
    <svg
        {...iconProps}
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z'
            fill='#EAF1FE'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C12.5523 20 13 20.4477 13 21C13 21.5523 12.5523 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C12.5523 2 13 2.44772 13 3C13 3.55228 12.5523 4 12 4Z'
            fill='url(#paint0_linear_10901_42980)'
        />
        <defs>
            <linearGradient
                id='paint0_linear_10901_42980'
                x1='7.5'
                y1='3'
                x2='7.5'
                y2='21'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='currentColor' />
                <stop offset='1' stopColor='currentColor' stopOpacity='0' />
            </linearGradient>
        </defs>
    </svg>
);
