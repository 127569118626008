import * as React from 'react';
import type { SpinnerProps } from '@components/atoms/spinner/types';
import type { ThemeProps } from '@models/types';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import useTheme from '@mui/material/styles/useTheme';

export const Spinner: React.FC<SpinnerProps> = ({ size, color, className, style }) => {
    const theme = useTheme<ThemeProps>();

    return (
        <Stack spacing={2} direction='row' className={className} style={style}>
            <CircularProgress size={size} style={{ color: color || theme.palette.primaryA1 }} />
        </Stack>
    );
};
