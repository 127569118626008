import type { PepEntityRiskScoreEnum } from '@models/enums';
import {
    FilterFieldArrayOptionNamesEnum,
    FilterFieldDateNamesEnum,
    FilterFieldOptionNamesEnum,
} from '@models/enums';
import { FilterAmlNames } from '@models/enums/filters';
import type { AutocompleteOption, DateRangeValue, FilterMenuProps } from '@models/types';
import { createSlice } from '@reduxjs/toolkit';
import { getFiltersReducer } from '@utils/filters/util';

export type ApplicantPepHistoryFiltersState = {
    [FilterFieldDateNamesEnum.DATE_RANGE]: DateRangeValue;
    [FilterFieldArrayOptionNamesEnum.RISK_SCORES]: Array<
        AutocompleteOption<PepEntityRiskScoreEnum>
    >;
    [FilterFieldOptionNamesEnum.ACCOUNT_EMAIL]: AutocompleteOption<string> | null;
};

export type ApplicantPepHistoryFiltersSliceState = FilterMenuProps<ApplicantPepHistoryFiltersState>;

export const applicantPepHistoryFiltersInitState: ApplicantPepHistoryFiltersState = {
    [FilterFieldDateNamesEnum.DATE_RANGE]: null,
    [FilterFieldArrayOptionNamesEnum.RISK_SCORES]: [],
    [FilterFieldOptionNamesEnum.ACCOUNT_EMAIL]: null,
};

const initialState: ApplicantPepHistoryFiltersSliceState = {
    isOpen: false,
    applyCount: 0,
    filters: applicantPepHistoryFiltersInitState,
    searchQuery: '',
};

export const applicantPepHistoryFiltersSlice = createSlice({
    name: FilterAmlNames.APPLICANT_PEP_HISTORY_FILTERS_SLICE,
    initialState,
    reducers: getFiltersReducer<
        ApplicantPepHistoryFiltersState,
        ApplicantPepHistoryFiltersSliceState
    >(applicantPepHistoryFiltersInitState),
});
