import React from 'react';
import type { IconProps } from '@models/types';

export const EmailIcon: React.FC<IconProps> = (props) => (
    <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M3 4C1.89543 4 1 4.89543 1 6V18C1 19.1046 1.89543 20 3 20H21C22.1046 20 23 19.1046 23 18V6C23 4.89543 22.1046 4 21 4H3ZM7.58579 12L3 16.5858V7.41421L7.58579 12ZM4.41421 18L9 13.4142L10.5858 15C11.3668 15.7811 12.6332 15.781 13.4142 15L15 13.4142L19.5858 18H4.41421ZM21 16.5858V7.41421L16.4142 12L21 16.5858ZM19.5858 6L4.41421 6L12 13.5858L19.5858 6Z'
            fill='currentColor'
        />
    </svg>
);
