import React, { useCallback, useEffect, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from '@components/atoms/button';
import { Link } from '@components/atoms/link';
import { Typography } from '@components/atoms/typography';
import { GearsIcon } from '@components/icons';
import type { ErrorBoundaryContentProps } from '@components/moleculs/error-boundary-content/types';
import { ROUTES } from '@constants';
import { useGetErrorText } from '@hooks/use-get-error-text';
import { useLogger } from '@hooks/use-logger';
import { useToast } from '@hooks/use-toast';
import type { RtkQueryResult } from '@models';
import Box from '@mui/material/Box';
import type { RootState } from '@store/root-reducer';
import cn from 'classnames';

import { ErrorBoundaryContainer } from './styles';

export const ErrorBoundaryContent: React.FC<ErrorBoundaryContentProps> = ({
    message,
    data,
    className,
    handleReload,
}) => {
    const { t } = useTranslation();
    const { showToast } = useToast();

    const errorGlobalText = useSelector(
        (state: RootState) => state.dashboard.session.errorGlobalText,
    );

    const errorText = useGetErrorText(data?.error as RtkQueryResult['error']);
    const serviceEmail = useSelector(
        (state: RootState) => state.domain.settings.other_settings.service_email,
    );
    const isDS = useSelector((state: RootState) => state.domain.settings.is_ds);

    const actualText = useMemo(() => {
        if (errorGlobalText) {
            return errorGlobalText;
        }

        return errorText.text;
    }, [errorText, errorGlobalText]);
    const { logger } = useLogger();
    const navigate = useNavigate();

    const request = useCallback(async () => {
        showToast({
            variant: 'error',
            message: data?.error?.data?.message || t('common.pleaseTryAgainLater'),
        });

        // send to logger only if message is provided
        if (message) {
            await logger.error({
                message,
                data,
            });
        }
    }, [showToast, data, t, logger, message]);

    useEffect(() => {
        request().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ErrorBoundaryContainer className={cn('error-boundary-container', className)}>
            <GearsIcon />
            <Typography variant='h3' tag='h1'>
                {t(errorText.title)}
            </Typography>
            <Box display='flex' flexDirection='column' alignItems='center' gap='4px'>
                <Typography variant='text' color='text-light' tag='p' className='text'>
                    {actualText}
                </Typography>
                {isDS && (
                    <Typography variant='text' color='text-light' tag='p' className='text'>
                        <Trans
                            i18nKey='errorBoundary.text.contactUs'
                            components={{
                                link1: (
                                    // eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/control-has-associated-label
                                    <Link
                                        href={ROUTES.CONTACT_US}
                                        rel='noreferrer'
                                        target='_blank'
                                    />
                                ),
                            }}
                        />
                    </Typography>
                )}

                {serviceEmail && !isDS && (
                    <React.Fragment>
                        <Typography variant='text' color='text-light' tag='p' className='text'>
                            {t('errorBoundary.text.serviceEmail')}{' '}
                            <a href={`mailto:${serviceEmail}`} target='_blank' rel='noreferrer'>
                                {serviceEmail}{' '}
                            </a>
                        </Typography>
                        <Typography variant='text' color='text-light' tag='p' className='text'>
                            {t('errorBoundary.subtext.serviceEmail')}
                        </Typography>
                    </React.Fragment>
                )}

                <Button
                    className='button'
                    variant='primary'
                    onClick={() => {
                        if (handleReload) {
                            handleReload();
                        } else {
                            navigate(0);
                        }
                    }}
                >
                    {t(errorText.button)}
                </Button>
            </Box>
        </ErrorBoundaryContainer>
    );
};
