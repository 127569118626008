import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTrackSandbox } from '@hooks/url/use-track-sandbox';
import type { RtkQueryResult } from '@models';
import { SandboxErrorVariantEnum } from '@models/enums';

export const useGetErrorText = (error?: RtkQueryResult['error']) => {
    const { t } = useTranslation();
    const { trackSandboxErrorMassage } = useTrackSandbox();

    const { errorCode, errorMessage } = useMemo(
        () => ({
            errorCode: error?.status,
            errorMessage: error?.data?.message,
        }),
        [error?.data?.message, error?.status],
    );

    const errorText = useMemo(() => {
        switch (errorCode) {
            case 500: {
                return {
                    title: t('errorBoundary.title.500'),
                    text: t('errorBoundary.text1.500'),
                    button: t('errorBoundary.reload'),
                };
            }
            case 404: {
                const sandboxError = trackSandboxErrorMassage(errorMessage);
                let title = t('errorBoundary.title.404');
                let text = t('errorBoundary.text1.404');
                let button = t('errorBoundary.reload');

                const err = sandboxError.isSandbox
                    ? t(`errorBoundary.sandbox.turnOn`, { error: errorMessage })
                    : t(`errorBoundary.sandbox.turnOff`, { error: errorMessage });

                // sandbox error
                if (sandboxError.sandboxVariant !== SandboxErrorVariantEnum.ANOTHER_ERROR) {
                    title = t(`errorBoundary.sandbox.title.entity.${sandboxError.entity}`) || title;
                    text = err || text;
                    button = sandboxError.isSandbox
                        ? t('errorBoundary.sandbox.reload.turnOn')
                        : t('errorBoundary.sandbox.reload.turnOff');
                }

                return {
                    title,
                    text,
                    button,
                };
            }
            case 403: {
                return {
                    title: t('errorBoundary.title.403'),
                    text: t('errorBoundary.text1.403'),
                    button: t('errorBoundary.reload'),
                };
            }
            default: {
                return {
                    title: t('errorBoundary.title.500'),
                    text: error?.data?.message || t('errorBoundary.text1.500'),
                    button: t('errorBoundary.reload'),
                };
            }
        }
    }, [error?.data?.message, errorCode, errorMessage, t, trackSandboxErrorMassage]);

    return errorText;
};
