import { apiLogger } from '@hooks/use-logger';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { RootState } from '@store/root-reducer';

const baseQuery = fetchBaseQuery();

const baseQueryWithInterceptor: ReturnType<typeof fetchBaseQuery> = async (
    args,
    api,
    extraOptions,
) => {
    const result = await baseQuery(args, api, extraOptions);

    if (result.error) {
        const state = api.getState() as RootState;

        await apiLogger(state, result);
    }

    return result;
};

export const publicApi = createApi({
    reducerPath: 'publicApi',
    baseQuery: baseQueryWithInterceptor,
    tagTypes: [],
    endpoints: () => ({}),
});
