import React from 'react';
import type { IconProps } from '@models/types';

export const PersonSIcon: React.FC<IconProps> = ({ ...props }) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        {...props}
    >
        <g clipPath='url(#clip0_1738_49341)'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M7.50007 8.33325C7.50007 6.95254 8.61936 5.83325 10.0001 5.83325C11.3808 5.83325 12.5001 6.95254 12.5001 8.33325C12.5001 9.71396 11.3808 10.8333 10.0001 10.8333C8.61936 10.8333 7.50007 9.71396 7.50007 8.33325ZM10.0001 7.49992C9.53984 7.49992 9.16674 7.87301 9.16674 8.33325C9.16674 8.79349 9.53984 9.16659 10.0001 9.16659C10.4603 9.16659 10.8334 8.79349 10.8334 8.33325C10.8334 7.87301 10.4603 7.49992 10.0001 7.49992Z'
                fill='currentColor'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M15.8334 3.33325C14.4527 3.33325 13.3334 4.45254 13.3334 5.83325C13.3334 7.21396 14.4527 8.33325 15.8334 8.33325C17.2141 8.33325 18.3334 7.21396 18.3334 5.83325C18.3334 4.45254 17.2141 3.33325 15.8334 3.33325ZM15.0001 5.83325C15.0001 5.37301 15.3732 4.99992 15.8334 4.99992C16.2936 4.99992 16.6667 5.37301 16.6667 5.83325C16.6667 6.29349 16.2936 6.66659 15.8334 6.66659C15.3732 6.66659 15.0001 6.29349 15.0001 5.83325Z'
                fill='currentColor'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M1.66674 5.83325C1.66674 4.45254 2.78603 3.33325 4.16674 3.33325C5.54745 3.33325 6.66674 4.45254 6.66674 5.83325C6.66674 7.21396 5.54745 8.33325 4.16674 8.33325C2.78603 8.33325 1.66674 7.21396 1.66674 5.83325ZM4.16674 4.99992C3.7065 4.99992 3.33341 5.37301 3.33341 5.83325C3.33341 6.29349 3.7065 6.66659 4.16674 6.66659C4.62698 6.66659 5.00007 6.29349 5.00007 5.83325C5.00007 5.37301 4.62698 4.99992 4.16674 4.99992Z'
                fill='currentColor'
            />
            <path
                d='M10.0001 11.6666C9.47807 11.6666 8.6219 11.7706 7.78862 12.362C6.94214 12.9627 6.22198 13.9932 5.85325 15.6525C5.75341 16.1018 6.03669 16.5469 6.48597 16.6467C6.93524 16.7466 7.38039 16.4633 7.48023 16.014C7.77817 14.6733 8.308 14.0371 8.7532 13.7212C9.21158 13.3959 9.68875 13.3333 10.0001 13.3333C10.3266 13.3333 10.8924 13.4019 11.392 13.735C11.8539 14.0429 12.3628 14.644 12.5052 15.9253C12.556 16.3827 12.968 16.7123 13.4254 16.6615C13.8829 16.6107 14.2125 16.1986 14.1616 15.7412C13.9707 14.0225 13.2296 12.9569 12.3165 12.3482C11.4411 11.7646 10.5069 11.6666 10.0001 11.6666Z'
                fill='currentColor'
            />
            <path
                d='M13.5306 9.92893C14.3914 9.27865 15.2922 9.16659 15.8334 9.16659C16.3403 9.16659 17.2745 9.26463 18.1499 9.84821C19.0629 10.4569 19.804 11.5225 19.995 13.2412C20.0458 13.6986 19.7162 14.1107 19.2588 14.1615C18.8014 14.2123 18.3894 13.8827 18.3385 13.4253C18.1962 12.144 17.6873 11.5429 17.2254 11.235C16.7257 10.9019 16.16 10.8333 15.8334 10.8333C15.5106 10.8333 15.0097 10.9004 14.5352 11.2588C14.1679 11.5362 13.6453 11.4634 13.3679 11.0962C13.0905 10.7289 13.1633 10.2063 13.5306 9.92893Z'
                fill='currentColor'
            />
            <path
                d='M4.16674 9.16659C4.70803 9.16659 5.60873 9.27865 6.4696 9.92893C6.83684 10.2063 6.90966 10.7289 6.63226 11.0962C6.35485 11.4634 5.83227 11.5362 5.46503 11.2588C4.99051 10.9004 4.48954 10.8333 4.16674 10.8333C3.84022 10.8333 3.27445 10.9019 2.77482 11.235C2.31289 11.5429 1.804 12.144 1.66164 13.4253C1.61082 13.8827 1.1988 14.2123 0.74138 14.1615C0.283958 14.1107 -0.0456545 13.6986 0.00517042 13.2412C0.196145 11.5225 0.937257 10.4569 1.85032 9.84821C2.7257 9.26463 3.65993 9.16659 4.16674 9.16659Z'
                fill='currentColor'
            />
        </g>
        <defs>
            <clipPath id='clip0_1738_49341'>
                <rect width='20' height='20' fill='white' />
            </clipPath>
        </defs>
    </svg>
);
