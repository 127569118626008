import React from 'react';
import type { IconProps } from '@models/types';

export const FileIcon: React.FC<IconProps> = (props) => (
    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg" { ...props }>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 0C1.34315 0 0 1.34315 0 3V17C0 18.6569 1.34315 20 3 20H13C14.6569 20 16 18.6569 16 17V7.35571C16 6.51222 15.6449 5.70771 15.0217 5.13928L10.2466 0.783571C9.69394 0.279459 8.97289 0 8.22486 0H3ZM2 3C2 2.44772 2.44772 2 3 2H8V5C8 6.65685 9.34315 8 11 8H14V17C14 17.5523 13.5523 18 13 18H3C2.44772 18 2 17.5523 2 17V3ZM12.9976 6L10 3.26569V5C10 5.55228 10.4477 6 11 6H12.9976Z"
            fill="currentColor"
        />
    </svg>
);
