import React from 'react';
import type { IconProps } from '@models/types';

export const CheckMarkWithoutBgIcon: React.FC<IconProps> = ({ className }) => (
    <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg" className={ className }>
        <path
            d="M5.14323 8.216C4.75295 8.59587 4.12342 8.59449 3.73487 8.21291L0.320746 4.80711C-0.115747 4.37845 -0.105396 3.68037 0.343619 3.26437C0.771776 2.86769 1.44265 2.87436 1.86254 3.27947L4.43748 5.81533L10.094 0.318539C10.5303 -0.106454 11.234 -0.10614 11.6699 0.319244C12.1104 0.749114 12.11 1.4491 11.6689 1.87841L5.14323 8.216Z"
            fill="currentColor"
        />
    </svg>
);
