import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export type AppState = {
    isMenuOpen: boolean;
    selectedMenu: string;
    openedSubMenus: string[];
};

const localStorageMenuKey = 'isAdminPanelMenuOpen';

const initialState: AppState = {
    isMenuOpen: localStorage.getItem(localStorageMenuKey) === 'true',
    selectedMenu: '',
    openedSubMenus: [],
};

export const adminPanelSlice = createSlice({
    name: 'adminPanelApp',
    initialState,
    reducers: {
        toggleMenu: (state) => {
            const newValue = !state.isMenuOpen;

            localStorage.setItem(localStorageMenuKey, newValue.toString());
            state.isMenuOpen = newValue;
            if (!newValue) {
                state.openedSubMenus = [];
            }
        },
        setSelectedMenu: (state, { payload }: PayloadAction<string>) => {
            state.selectedMenu = payload;
        },
        hideSubMenu: (state, { payload }: PayloadAction<string>) => {
            const index = state.openedSubMenus.findIndex((item) => item === payload);

            if (index >= 0) {
                const newValue = [...state.openedSubMenus];

                newValue.splice(index, 1);
                state.openedSubMenus = newValue;
            }
        },
        toggleSubMenu: (state, { payload }: PayloadAction<string>) => {
            const index = state.openedSubMenus.findIndex((item) => item === payload);

            if (index >= 0) {
                const newValue = [...state.openedSubMenus];

                newValue.splice(index, 1);
                state.openedSubMenus = newValue;
            } else {
                // only one sub menu can be opened at the same time
                state.openedSubMenus = [payload];
            }
        },
    },
});
