import React from 'react';
import type { IconProps } from '@models/types';

export const AttentionStatusIcon: React.FC<IconProps> = ({ className }) => (
    <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={className}
    >
        <rect width='24' height='24' rx='4' fill='#FF5387' fillOpacity='0.1' />
        <path d='M12 8V13V8Z' fill='#FF5387' />
        <path
            d='M12 8V13'
            stroke='currentColor'
            strokeWidth='1.33333'
            strokeMiterlimit='10'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M12.3333 16C12.3333 16.1841 12.1841 16.3333 12 16.3333C11.8159 16.3333 11.6667 16.1841 11.6667 16C11.6667 15.8159 11.8159 15.6667 12 15.6667C12.1841 15.6667 12.3333 15.8159 12.3333 16Z'
            fill='currentColor'
            stroke='currentColor'
            strokeWidth='1.33333'
        />
    </svg>
);
