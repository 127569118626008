import { createGlobalStyle } from 'styled-components';

export const CountryOptionGlobalStyles = createGlobalStyle`
  .country-option-checkbox {
    font-size: 12px !important;
    padding: 6px 8px !important;
    
    svg {
      width: 20px;
      height: 20px;
    }

    img {
      width: 20px;
      height: 20px;
      border-radius: 50%;
    }

    .MuiCheckbox-root {
      color: ${(props) => props.theme.palette.primaryA1};
    }

    .MuiButtonBase-root {
      padding: 2px 17px 2px 0;
    }


    &[aria-selected='true'] {
      background-color: transparent;
    }
  }
`;
