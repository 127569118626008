import * as React from 'react';
import { useMemo } from 'react';
import type { LinkProps } from 'react-router-dom';
import { Link } from 'react-router-dom';
import type { ButtonProps } from '@components/atoms/button/types';
import type { ThemeProps } from '@models/types';
import useTheme from '@mui/material/styles/useTheme';
import { isExternalURL } from '@utils/url';
import cn from 'classnames';

import { Spinner } from '../spinner';

import { Button as Btn } from './styles';

export { ButtonText } from './styles';

export const Button: React.FC<ButtonProps> = ({
    className,
    children,
    size = 'm',
    leftAddon,
    active,
    rightAddon,
    fullWidth,
    to,
    target,
    loading,
    ...rest
}) => {
    const theme = useTheme<ThemeProps>();

    const [component, externalLink] = useMemo(() => {
        if (to) {
            const actualUrl = typeof to === 'string' ? to : (to.pathname as string);

            if (isExternalURL(actualUrl)) {
                return ['a', to];
            }

            return [Link, undefined];
        }

        return ['button', undefined];
    }, [to]);

    const spinnerColor = useMemo(() => {
        if (rest.variant === 'secondary' || rest.variant === 'tertiary') {
            return theme.palette.primaryA1;
        }

        if (rest.variant === 'error-secondary') {
            return theme.palette.secondaryA4;
        }

        return theme.palette.primaryA7;
    }, [theme, rest]);

    const spinnerSize = useMemo(() => {
        if (size === 's') {
            return '20px';
        }

        if (size === 'm') {
            return '22px';
        }

        return '25px';
    }, [size]);

    return (
        <Btn
            as={component}
            to={(externalLink ? undefined : to) as LinkProps['to']}
            className={cn([className, { active }])}
            // @ts-expect-error valid
            href={externalLink}
            target={target}
            size={size}
            $fullWidth={fullWidth}
            {...rest}
        >
            <span className='button-inner-container'>
                {loading && (
                    <Spinner className='button-spinner' size={spinnerSize} color={spinnerColor} />
                )}
                <React.Fragment>
                    {leftAddon && (
                        <span
                            className={cn(
                                'button-left-addon',
                                loading ? 'hidden-button-elem' : undefined,
                            )}
                        >
                            {leftAddon}
                        </span>
                    )}
                    {children && (
                        <span
                            className={cn(
                                'button-text',
                                loading ? 'hidden-button-elem' : undefined,
                            )}
                        >
                            {children}
                        </span>
                    )}
                    {rightAddon && (
                        <span
                            className={cn(
                                'button-right-addon',
                                loading ? 'hidden-button-elem' : undefined,
                            )}
                        >
                            {rightAddon}
                        </span>
                    )}
                </React.Fragment>
            </span>
        </Btn>
    );
};
