export enum DocsVerificationStatusEnum {
    INITIAL = 'initial',
    PENDING = 'pending',
    IN_PROGRESS = 'in_progress',
    VERIFIED = 'verified',
    FAILED = 'failed',
    EXPIRED = 'expired',
    CANCELED = 'canceled',
    VERIFIED_MANUALLY = 'verified_manually',
    FAILED_MANUALLY = 'failed_manually',
}
