import type { ErrorInfo, ReactNode } from 'react';
import React, { Component } from 'react';
import type { RtkQueryResult } from '@models';

import { ErrorBoundaryContent } from '../error-boundary-content';

interface Props {
    children?: ReactNode;
}

interface State {
    hasError: boolean;
    error?: Error | null;
}
class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false,
        error: null,
    };

    public static getDerivedStateFromError(error: Error): State {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // eslint-disable-next-line no-console
        console.error('Uncaught error:', error, errorInfo);
    }

    public render() {
        if (this.state?.hasError) {
            return (
                <ErrorBoundaryContent
                    message='Crash'
                    data={
                        {
                            error: {
                                data: {
                                    message: this.state?.error?.message,
                                },
                            },
                        } as {
                            error: RtkQueryResult['error'];
                        }
                    }
                />
            );
        }

        return this.props.children;
    }
}
// eslint-disable-next-line import/no-default-export
export default ErrorBoundary;
