import React from 'react';
import { MenuItemGlobalStyles } from '@components/layout/navigaiton-menu/menu-item/global-styles';
import { NavigationMenuGlobalStyles } from '@components/layout/navigaiton-menu/menu/global-styles';
import { AutocompleteGlobalStyles } from '@components/moleculs/autocomplete/global-styles';
import { CountryOptionGlobalStyles } from '@components/moleculs/country-option/global-styles';
import { DatePickerGlobalStyles } from '@components/moleculs/date-pickers/global-styles';
import { TooltipGlobalStyles } from '@components/moleculs/tooltip/global-styles';
import { CheckboxAutocompleteGlobalStyle } from '@components/organisms/checkbox-autocomplete/global-styles';
import { DeleteConfirmationPopupGlobalStyles } from '@components/organisms/delete-confirmation-popup';
import { SwitchFilterTooltipGlobalStyles } from '@components/organisms/filters/filter-switch/global-styles';
import { TagsAutocompleteGlobalStyles } from '@components/organisms/tags-autocomplete/global-styles';

import { AppGlobalStyles } from './app-global-styles';
import { Fonts } from './fonts';

export const GlobalStyles = () => (
    <React.Fragment>
        <Fonts />
        <AppGlobalStyles />
        <AutocompleteGlobalStyles />
        <TagsAutocompleteGlobalStyles />
        <CheckboxAutocompleteGlobalStyle />
        <TooltipGlobalStyles />
        <DatePickerGlobalStyles />
        <MenuItemGlobalStyles />
        <CountryOptionGlobalStyles />
        <NavigationMenuGlobalStyles />
        <DeleteConfirmationPopupGlobalStyles />
        <SwitchFilterTooltipGlobalStyles />
    </React.Fragment>
);
