import React from 'react';
import type { IconProps } from '@models/types';

export const ErrorMarkIcon: React.FC<IconProps> = ({ className }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={ className }>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM14.7071 9.29289C14.3166 8.90237 13.6834 8.90237 13.2929 9.29289L12 10.5858L10.7071 9.29289C10.3166 8.90237 9.68342 8.90237 9.29289 9.29289C8.90237 9.68342 8.90237 10.3166 9.29289 10.7071L10.5858 12L9.29289 13.2929C8.90237 13.6834 8.90237 14.3166 9.29289 14.7071C9.68342 15.0976 10.3166 15.0976 10.7071 14.7071L12 13.4142L13.2929 14.7071C13.6834 15.0976 14.3166 15.0976 14.7071 14.7071C15.0976 14.3166 15.0976 13.6834 14.7071 13.2929L13.4142 12L14.7071 10.7071C15.0976 10.3166 15.0976 9.68342 14.7071 9.29289Z"
            fill="#FF5387"
        />
    </svg>
);
