import React from 'react';
import type { IconProps } from '@models/types';

export const PlusIcon: React.FC<IconProps> = ({ className }) => (
    <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={className}
    >
        <path
            d='M13 17.6566C13 18.2089 12.5523 18.6566 12 18.6566C11.4477 18.6566 11 18.2089 11 17.6566V12.9998L6.34315 12.9998C5.79086 12.9998 5.34315 12.5521 5.34315 11.9998C5.34315 11.4475 5.79086 10.9998 6.34315 10.9998L11 10.9998L11 6.34292C11 5.79064 11.4477 5.34292 12 5.34292C12.5523 5.34292 13 5.79064 13 6.34292L13 10.9998L17.6569 10.9998C18.2091 10.9998 18.6569 11.4475 18.6569 11.9998C18.6569 12.5521 18.2091 12.9998 17.6569 12.9998L13 12.9998V17.6566Z'
            fill='currentColor'
        />
    </svg>
);
