import React from 'react';
import type { IconProps } from '@models/types';

export const ComputerIcon: React.FC<IconProps> = ({ className }) => (
    <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={className}
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M3 3H21V15.2609L3 15.2609L3 3ZM1 3C1 1.89543 1.89543 1 3 1H21C22.1046 1 23 1.89543 23 3V15.2609C23 16.3654 22.1046 17.2609 21 17.2609H15.0404L15.1709 18.3051C15.9887 18.5561 16.5833 19.3173 16.5833 20.2174V22.9C16.5833 22.9552 16.5386 23 16.4833 23H7.51667C7.46144 23 7.41667 22.9552 7.41667 22.9V20.2174C7.41667 19.3216 8.00563 18.5633 8.81746 18.3087L8.96091 17.2609H3C1.89543 17.2609 1 16.3654 1 15.2609V3ZM13.1444 18.2174L13.0248 17.2609H10.9796L10.8486 18.2174H13.1444ZM9.41667 20.2174H14.5833V21H9.41667V20.2174Z'
            fill='currentColor'
        />
    </svg>
);
