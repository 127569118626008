import React from 'react';
import type { IconProps } from '@models/types';

export const UserAvatarIcon: React.FC<IconProps> = (props) => (
    <svg
        width='28'
        height='28'
        viewBox='0 0 28 28'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
    >
        <circle cx='14' cy='14' r='14' fill='currentColor' />
        <path
            d='M14.0001 15.7003C16.8117 15.7003 19.091 13.4204 19.091 10.608C19.091 7.79555 16.8117 5.51562 14.0001 5.51562C11.1884 5.51562 8.90915 7.79555 8.90915 10.608C8.90915 13.4204 11.1884 15.7003 14.0001 15.7003Z'
            fill='white'
        />
        <path
            d='M10.3113 17.0085C8.45327 16.889 6.62256 19.2494 5.93945 20.4445C7.5789 23.9105 11.4546 24.2183 14.0001 24.1794C16.5455 24.1405 20.4212 23.9105 22.0607 20.4445C21.3776 19.2494 19.5468 16.889 17.6888 17.0085C16.5777 17.0799 16.1545 17.1856 15.7909 17.2764C15.3945 17.3754 15.0689 17.4567 14.0001 17.4567C12.9312 17.4567 12.6056 17.3754 12.2093 17.2764C11.8457 17.1856 11.4225 17.0799 10.3113 17.0085Z'
            fill='white'
        />
    </svg>
);
