import { FilterFieldDateNamesEnum, FilterFieldOptionNamesEnum } from '@models/enums';
import type { AutocompleteOption, DatePickerValue, FilterMenuProps } from '@models/types';
import { createSlice } from '@reduxjs/toolkit';
import { getFiltersReducer } from '@utils/filters/util';

export type DocVerApplicantFiltersState = {
    [FilterFieldOptionNamesEnum.COUNTRY]?: AutocompleteOption<string>;
    [FilterFieldDateNamesEnum.DATE]?: DatePickerValue;
};

export type DocVerApplicantFiltersSliceState = FilterMenuProps<DocVerApplicantFiltersState>;

export const docVerApplicantFiltersInitState: DocVerApplicantFiltersState = {
    [FilterFieldOptionNamesEnum.COUNTRY]: undefined,
    [FilterFieldDateNamesEnum.DATE]: null,
};

const initialState: DocVerApplicantFiltersSliceState = {
    isOpen: false,
    applyCount: 0,
    filters: docVerApplicantFiltersInitState,
    searchQuery: '',
};

export const searchApplicantFiltersSlice = createSlice({
    name: 'searchApplicantFiltersSlice',
    initialState,
    reducers: getFiltersReducer<DocVerApplicantFiltersState, DocVerApplicantFiltersSliceState>(
        docVerApplicantFiltersInitState,
    ),
});
