import { createGlobalStyle } from 'styled-components';

export const CheckboxAutocompleteGlobalStyle = createGlobalStyle`
  .checkboxes-popper {
    [aria-selected="true"] {
      .MuiCheckbox-root {
        color: ${(props) => props.theme.palette.primaryA1};
      }
    }

    [aria-selected="false"] {
      .MuiCheckbox-root {
        color: ${(props) => props.theme.palette.primaryA3};
      }
    }
    
    .MuiCheckbox-root {
      color: ${(props) => props.theme.palette.primaryA3};
    }

    .MuiAutocomplete-listbox {
      padding: 8px 8px 0 8px;
    }

    .MuiAutocomplete-option {
      background-color: transparent;
      padding: 0;
      margin-right: 4px;
      margin-bottom: 4px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
`;
