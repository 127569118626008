import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ReactAppTypeEnum } from '@models/enums';
import type { AuthUserInfo } from '@modules/auth/store';
import type { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import type { RootState } from '@store/root-reducer';
import type { VerificationState } from '@store/slices/docs-verification';
import { maskEmail, maskString } from '@utils/mask';
import axios from 'axios';

const baseAxios = axios.create({
    baseURL: process.env.REACT_APP_LOGS_API_BASE_URL,
});

type LogMessage = {
    message: string;
    data?: unknown;
};

type Logger = Record<'error' | 'warn' | 'log', (data: LogMessage) => Promise<unknown>>;
type LoggerParams = {
    organizationId: string;
    sdkOptions: VerificationState['sdkOptions'];
    user: AuthUserInfo | null;
    isAdmin: boolean;
};
type ApiLogger = (
    state: RootState,
    result: Extract<ReturnType<ReturnType<typeof fetchBaseQuery>>, { error: unknown }>, // narrow to Error path of response of baseQuery
) => Promise<void>;

const client_type: ReactAppTypeEnum =
    (process.env.REACT_APP_TYPE as ReactAppTypeEnum) || 'production';

export const logger = ({ organizationId, sdkOptions, user, isAdmin }: LoggerParams): Logger => {
    const isSdkLogger = !!(!organizationId && sdkOptions && sdkOptions.logsUrl);
    const isLocalEnv = client_type === ReactAppTypeEnum.LOCAL;
    const baseUrl = baseAxios.defaults.baseURL;

    if (
        // local env
        isLocalEnv ||
        // other envs, if baseUrl is not defined and not SDK logger
        (!isLocalEnv && !baseUrl && !isSdkLogger) ||
        // widget case, when logsUrl is not defined
        !isSdkLogger
    ) {
        return {
            log: async () => Promise.resolve(true),
            error: async () => Promise.resolve(true),
            warn: async () => Promise.resolve(true),
        };
    }

    const getData = (data: unknown) => ({
        ...(data && typeof data === 'object' ? data : { data }),
        path: window.location.href,
    });

    const place = isAdmin ? 'Admin Panel' : 'Dashboard';

    if (!organizationId && sdkOptions) {
        // update base url for sdk
        baseAxios.defaults.baseURL = sdkOptions.logsUrl;

        const meta = {
            verificationId: sdkOptions.id,
            settings: sdkOptions.settings,
            checks: sdkOptions.checks,
        };

        const getMessage = (type: string, message: string, data: unknown) =>
            `DocVerWidget ${type}. Message: ${message}. Data: ${JSON.stringify(
                getData(data),
            )}. Meta: ${JSON.stringify(meta)}`;

        return {
            log: async ({ message, data }) => {
                try {
                    await baseAxios.post('', {
                        msg: getMessage('Log', message, data),
                        level: 'info',
                        client_type,
                    });
                } catch {
                    /* empty */
                }
            },
            error: async ({ message, data }) => {
                try {
                    await baseAxios.post('', {
                        msg: getMessage('Error', message, data),
                        level: client_type === ReactAppTypeEnum.PRODUCTION ? 'error' : 'warning',
                        client_type,
                    });
                } catch {
                    /* empty */
                }
            },
            warn: async ({ message, data }) => {
                try {
                    await baseAxios.post('', {
                        msg: getMessage('Warning', message, data),
                        level: 'warning',
                        client_type,
                    });
                } catch {
                    /* empty */
                }
            },
        };
    }

    const meta = {
        organizationId,
        ...(client_type === ReactAppTypeEnum.PRODUCTION
            ? {
                  ...user,
                  email: user?.email ? maskEmail(user?.email) : user?.email,
                  full_name: user?.full_name ? maskString(user?.full_name) : user?.full_name,
              }
            : { user }),
    };

    const getMessage = (type: string, message: string, data: unknown) =>
        `${place} ${type}. Message: ${message}. Data: ${JSON.stringify(
            getData(data),
        )}. Meta: ${JSON.stringify(meta)}`;

    return {
        log: async ({ message, data }: LogMessage) => {
            try {
                await baseAxios.post('', {
                    msg: getMessage('Log', message, data),
                    level: 'info',
                    client_type,
                });
            } catch {
                /* empty */
            }
        },
        error: async ({ message, data }) => {
            try {
                await baseAxios.post('', {
                    msg: getMessage('Error', message, data),
                    level: client_type === ReactAppTypeEnum.PRODUCTION ? 'error' : 'warning',
                    client_type,
                });
            } catch {
                /* empty */
            }
        },
        warn: async ({ message, data }) => {
            try {
                await baseAxios.post('', {
                    msg: getMessage('Warning', message, data),
                    level: 'warning',
                    client_type,
                });
            } catch {
                /* empty */
            }
        },
    };
};

export const apiLogger: ApiLogger = async (state, result) => {
    const loggerInstance = logger({
        isAdmin: state.domain.settings.is_admin_panel,
        user: state.auth?.user?.info,
        sdkOptions: state.docsVerification.sdkOptions,
        organizationId: state.domain.settings.is_admin_panel
            ? state.adminPanel?.session?.organizationId
            : state.dashboard?.session?.organizationId,
    });

    const { error, meta } = result;

    const getData = async () => {
        let email: string | undefined;
        let body: Promise<unknown> | undefined;

        if (meta?.request.body) {
            body = await meta?.request.json();
        }

        if (body && typeof body === 'object' && 'email' in body && typeof body.email === 'string') {
            email =
                client_type === ReactAppTypeEnum.PRODUCTION ? maskEmail(body.email) : body.email;
        }

        return {
            error: {
                ...(error && typeof error === 'object' ? error : { error }),
            },
            ...((email || meta?.request?.url) && {
                additionalData: {
                    ...(email && { email }),
                    ...(meta?.request.url && { apiPath: meta?.request.url }),
                },
            }),
        };
    };

    if ([403].includes(error.status as number) || (error.status >= 500 && error.status < 600)) {
        await loggerInstance.error({
            message: `${error.status} Status`,
            data: await getData(),
        });
    }

    if (error.status === 404) {
        await loggerInstance.warn({
            message: '404 Status',
            data: await getData(),
        });
    }
};

export const useLogger = () => {
    const user = useSelector((state: RootState) => state.auth?.user?.info);
    const isAdmin = useSelector((state: RootState) => state.domain.settings.is_admin_panel);
    const organizationId = useSelector((state: RootState) =>
        isAdmin
            ? state.adminPanel?.session?.organizationId
            : state.dashboard?.session?.organizationId,
    );
    const { sdkOptions } = useSelector((state: RootState) => state.docsVerification);

    const loggerMemo = useMemo(
        () => logger({ sdkOptions, organizationId, user, isAdmin }),
        [sdkOptions, organizationId, user, isAdmin],
    );

    return {
        logger: loggerMemo,
    };
};
