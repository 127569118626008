import React from 'react';
import type { TooltipProps } from '@mui/material';
import { Tooltip as MuiTooltip } from '@mui/material';
import cn from 'classnames';

export const Tooltip: React.FC<TooltipProps> = (props) => (
    <MuiTooltip
        {...props}
        classes={{ ...props.classes, popper: cn('tooltip-popper', props.classes?.popper) }}
    />
);
