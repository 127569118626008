import { apiLogger } from '@hooks/use-logger';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { RootState } from '@store/root-reducer';
import { baseQueryRequest } from '@utils/base-query-request';

const baseQuery = fetchBaseQuery();

const baseQueryWithInterceptor: ReturnType<typeof fetchBaseQuery> = async (
    args,
    api,
    extraOptions,
) => {
    const state = api.getState() as RootState;

    const result = await baseQueryRequest({
        state,
        baseQuery,
        args,
        api,
        extraOptions,
        isUseCredentials: false,
    });

    if (result.error) {
        await apiLogger(state, result);
    }

    return result;
};

export const sdkApi = createApi({
    reducerPath: 'sdkApi',
    baseQuery: baseQueryWithInterceptor,
    endpoints: () => ({}),
});
