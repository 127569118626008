export enum DocsVerificationAddressTypeEnum {
    ALL_POA = 'poa',
    POA_UTILITY_BILL = 'poa_utility_bill',
    POA_BANK_STATEMENT = 'poa_bank_statement',
    POA_RESIDENCE_REGISTRATION = 'poa_residence_registration',
}

export enum DocsVerificationDocumentTypeEnum {
    PASSPORT = 'passport',
    ID_CARD = 'id_card',
    RESIDENCE_PERMIT = 'residence_permit',
    VISA = 'visa',
    DRIVER_LICENSE = 'driver_license',
}

export enum DocsVerificationBiometricTypeEnum {
    SELFIE = 'selfie',
    LIVENESS_PHOTO = 'liveness_photo',
    LIVENESS_PHOTO_EXTRA = 'liveness_photo_extra',
}

export enum DocsVerificationTypeEnum {
    POI = 'poi',
    LIVENESS = 'liveness_photo',
    POA = 'poa',
}

export enum RussianDocuments {
    INTERNATIONAL_PASSPORT = 'international passport',
    INTERNAL_PASSPORT = 'internal passport',
    DRIVER_LICENSE = 'driver_license',
}
