import React from 'react';

export const ApiIcon: React.FC = () => (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M6 6C6 5.05719 6 4.58579 6.29289 4.29289C6.58579 4 7.05719 4 8 4H9C9.94281 4 10.4142 4 10.7071 4.29289C11 4.58579 11 5.05719 11 6V7H6V6ZM13 6C13 5.05719 13 4.58579 13.2929 4.29289C13.5858 4 14.0572 4 15 4H16C16.9428 4 17.4142 4 17.7071 4.29289C18 4.58579 18 5.05719 18 6V7H13V6ZM4 14C4 13.4484 4 12.9506 4.00652 12.5H19.9935C20 12.9506 20 13.4484 20 14C20 16.8284 20 18.2426 19.1213 19.1213C18.2426 20 16.8284 20 14 20H10C7.17157 20 5.75736 20 4.87868 19.1213C4 18.2426 4 16.8284 4 14ZM19.9239 11H4.07612C4.17203 10.0249 4.38879 9.36857 4.87868 8.87868C5.75736 8 7.17157 8 10 8H14C16.8284 8 18.2426 8 19.1213 8.87868C19.6112 9.36857 19.828 10.0249 19.9239 11Z'
                fill='currentColor'
            />
        </g>
    </svg>
);
