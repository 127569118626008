import React from 'react';
import type { IconProps } from '@models/types';

export const MaskIcon = React.forwardRef<SVGSVGElement, IconProps>((props, ref) => (
    <svg
        width='158'
        height='223'
        viewBox='0 0 158 223'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
        ref={ref}
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M158 0.00195312L0 0V222.998H158V0.00195312ZM79.2799 3.00198C79.211 3.00225 79.133 3.00255 79.0234 3.00382C78.9139 3.00255 78.8359 3.00225 78.767 3.00198L78.7553 3.00194L78.7553 3.00795C77.6852 3.02772 74.5762 3.11744 62.9143 3.5488C53.8159 3.88534 45.8952 5.40701 38.4382 9.0268C30.9828 12.6459 24.0637 18.3266 16.912 26.8759C9.61474 35.5991 5.50283 50.0742 3.52335 67.2516C1.53826 84.4776 1.67786 104.621 3.042 124.862C5.78361 165.544 18.9727 187.643 37.1426 205.308C51.8034 219.561 70.4258 222.001 78.7508 222V222.002C87.0644 222.004 106.237 219.568 120.904 205.308C139.074 187.643 152.263 165.544 155.005 124.862C156.369 104.621 156.509 84.4776 154.524 67.2516C152.544 50.0742 148.432 35.5991 141.135 26.8759C133.983 18.3266 127.064 12.6459 119.609 9.0268C112.152 5.40701 104.231 3.88534 95.1326 3.5488C83.4706 3.11744 80.3617 3.02772 79.2916 3.00795L79.2915 3.00194L79.2799 3.00198Z'
            fill='black'
            fillOpacity='0'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M79.0234 2.00384C79.133 2.00257 79.211 2.00227 79.2799 2.002L79.2915 2.00195L79.2916 2.00797C80.3617 2.02774 83.4707 2.11746 95.1326 2.54882C104.231 2.88535 112.152 4.40703 119.609 8.02682C127.064 11.6459 133.983 17.3266 141.135 25.8759C148.432 34.5991 152.544 49.0742 154.524 66.2516C156.509 83.4776 156.369 103.621 155.005 123.862C152.263 164.544 139.074 186.643 120.904 204.308C106.237 218.568 87.0644 221.004 78.7508 221.002V221C70.4258 221.001 51.8034 218.561 37.1426 204.308C18.9727 186.643 5.78361 164.544 3.04201 123.862C1.67787 103.621 1.53827 83.4776 3.52335 66.2516C5.50284 49.0742 9.61475 34.5991 16.912 25.8759C24.0637 17.3266 30.9828 11.6459 38.4382 8.02682C45.8952 4.40703 53.8159 2.88535 62.9143 2.54882C74.5762 2.11746 77.6852 2.02774 78.7553 2.00797L78.7553 2.00195L78.767 2.002C78.8359 2.00227 78.9139 2.00257 79.0234 2.00384Z'
            stroke='currentColor'
            strokeWidth='1'
            strokeLinejoin='round'
        />
    </svg>
));
