import type {
    FilterFieldDateNamesEnum,
    FilterFieldOptionNamesEnum,
    OperationTypeEnum,
} from '@models/enums';
import { FilterBillingNames } from '@models/enums';
import type { AutocompleteOption, DateRangeValue, FilterMenuProps } from '@models/types';
import { createSlice } from '@reduxjs/toolkit';
import { getFiltersReducer } from '@utils/filters/util';

export type BillingOperationsFiltersState = {
    [FilterFieldDateNamesEnum.DATE_RANGE]?: DateRangeValue;
    [FilterFieldOptionNamesEnum.OPERATION_TYPE]: AutocompleteOption<OperationTypeEnum> | null;
    [FilterFieldOptionNamesEnum.ACCOUNT_EMAIL]: AutocompleteOption<string> | null;
};

export type BillingOperationsFiltersSliceState = FilterMenuProps<BillingOperationsFiltersState>;

export const getBillingOperationsFiltersInitState: () => BillingOperationsFiltersState = () => {
    const today = new Date();
    const priorDate = new Date();

    priorDate.setDate(today.getDate() - 30);

    return {
        dateRange: [priorDate, today],
        operationType: null,
        accountEmail: null,
    };
};

const billingOperationsFiltersInitState = getBillingOperationsFiltersInitState();

const initialState: BillingOperationsFiltersSliceState = {
    isOpen: false,
    applyCount: 0,
    filters: billingOperationsFiltersInitState,
    searchQuery: '',
};

export const billingOperationsFiltersSlice = createSlice({
    name: FilterBillingNames.BILLING_OPERATIONS_FILTERS_SLICE,
    initialState,
    reducers: getFiltersReducer<BillingOperationsFiltersState, BillingOperationsFiltersSliceState>(
        billingOperationsFiltersInitState,
    ),
});
