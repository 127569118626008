import type { FC } from 'react';
import React, { lazy, Suspense } from 'react';
import { FullScreenSpinner } from '@components/moleculs/full-screen-spinner';
import { useLoadDomainSettings } from '@hooks/domain/use-load-domain-settings';
import { useLoadedApp } from '@hooks/use-loaded-app';
import { useReplaceFavicon } from '@hooks/use-replace-favicon';
import { getApiBaseUrl } from '@utils/get-api-base-url';

import ErrorBoundary from './components/moleculs/error-boundary';

const AdminPanelApp = lazy(() => import('@modules/admin-panel/app'));
const DashboardApp = lazy(() => import('@modules/dashboard/app'));
const WidgetApp = lazy(() => import('@modules/widget/app'));
const ApiDocsApp = lazy(() => import('@modules/api-docs/app'));
const PolicyApp = lazy(() => import('@modules/policy/app'));

export const App: FC = () => {
    const { isAdmin, isWidget, isApiDocs, isPolicy } = useLoadedApp();
    const { isLoadingDomainSettings } = useLoadDomainSettings({
        url: getApiBaseUrl(),
    });

    useReplaceFavicon();

    if (isLoadingDomainSettings) {
        return <FullScreenSpinner />;
    }

    if (isAdmin) {
        return (
            <Suspense fallback={<FullScreenSpinner />}>
                <ErrorBoundary>
                    <AdminPanelApp />
                </ErrorBoundary>
            </Suspense>
        );
    }

    if (isWidget) {
        return (
            <Suspense fallback={<FullScreenSpinner />}>
                <WidgetApp />
            </Suspense>
        );
    }

    if (isApiDocs) {
        return (
            <Suspense fallback={<FullScreenSpinner />}>
                <ApiDocsApp />
            </Suspense>
        );
    }

    if (isPolicy) {
        return (
            <Suspense fallback={<FullScreenSpinner />}>
                <PolicyApp />
            </Suspense>
        );
    }

    return (
        <Suspense fallback={<FullScreenSpinner />}>
            <ErrorBoundary>
                <DashboardApp />
            </ErrorBoundary>
        </Suspense>
    );
};
