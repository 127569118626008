import React from 'react';
import type { IconProps } from '@models/types';

export const PepSanctionsIcon: React.FC<IconProps> = ({ ...props }) => (
    <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M2.39282 6.68805C2 7.81065 2 9.2071 2 12C2 14.7929 2 16.1893 2.39282 17.312C3.0964 19.3227 4.67732 20.9036 6.68805 21.6072C7.81065 22 9.2071 22 12 22C14.7929 22 16.1893 22 17.312 21.6072C19.3227 20.9036 20.9036 19.3227 21.6072 17.312C22 16.1893 22 14.7929 22 12C22 9.2071 22 7.81065 21.6072 6.68805C20.9036 4.67732 19.3227 3.0964 17.312 2.39282C16.1893 2 14.7929 2 12 2C9.2071 2 7.81065 2 6.68805 2.39282C4.67732 3.0964 3.0964 4.67732 2.39282 6.68805ZM16.9995 10.3628C16.9688 8.51964 15.4804 7.03124 13.6372 7.00047C13.5285 7.00008 13.4049 6.99998 13.2632 6.99998V4.99998L13.3484 4.99997L13.579 5.00005V4.99998L13.619 5.00012C14.0786 5.00114 14.4176 5.00803 14.7209 5.05222C16.9097 5.37112 18.6289 7.09029 18.9478 9.27911C18.992 9.58237 18.9989 9.92141 18.9999 10.381L19 10.421H18.9999L19 10.6515L19 10.7368V11.0526H17V10.7368C17 10.5951 16.9999 10.4715 16.9995 10.3628ZM7.00001 10.421C7.00001 8.53164 8.53166 6.99998 10.4211 6.99998L10.7368 6.99998V4.99998H10.4211L10.3723 4.99998C10.0282 4.99994 9.79398 4.99992 9.58711 5.01706C7.15164 5.21887 5.21889 7.15161 5.01708 9.58708C4.99994 9.79393 4.99997 10.0282 5 10.3722V10.3723V10.3723L5.00001 10.421H7.00001ZM7.00001 13.5789V13.2631H5.00001V13.5789L5 13.6276V13.6277C4.99997 13.9718 4.99994 14.206 5.01708 14.4129C5.21889 16.8483 7.15164 18.7811 9.58711 18.9829C9.79397 19 10.0282 19 10.3723 19H10.3723L10.4211 19V17C10.01 17 9.86554 16.9991 9.75227 16.9897C8.29099 16.8686 7.13134 15.709 7.01025 14.2477C7.00087 14.1345 7.00001 13.9899 7.00001 13.5789ZM17 13.2631C17 13.9828 16.9974 14.2355 16.9687 14.4325C16.7773 15.7458 15.7458 16.7773 14.4325 16.9686C14.2355 16.9973 13.9828 17 13.2632 17H12.9474V19H13.2632L13.3484 19C13.9507 19.0001 14.361 19.0002 14.7209 18.9477C16.9097 18.6288 18.6289 16.9097 18.9478 14.7208C19.0002 14.361 19.0001 13.9507 19 13.3484V13.3484L19 13.2631H17ZM14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 9.99999 12 9.99999C13.1046 9.99999 14 10.8954 14 12Z'
            fill='currentColor'
        />
    </svg>
);
