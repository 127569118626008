import React from 'react';
import type { IconProps } from '@models/types';

export const LockIcon: React.FC<IconProps> = ({ style }) => (
    <svg
        width='16'
        height='20'
        viewBox='0 0 16 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        style={style}
    >
        <rect
            x='1'
            y='9.33398'
            width='14'
            height='9.66667'
            rx='2'
            stroke='currentColor'
            strokeWidth='2'
        />
        <path
            d='M3.52344 5.47368C3.52344 3.00294 5.52637 1 7.99712 1C10.4679 1 12.4708 3.00294 12.4708 5.47368V9H3.52344V5.47368Z'
            stroke='currentColor'
            strokeWidth='2'
        />
    </svg>
);
