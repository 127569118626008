import type {
    OperationHistoryModel,
    OperationHistoryVerificationModel,
    OperationHistoryVerificationResultModel,
} from '@models';
import { ApiTagEnum, DocsVerificationStatusEnum } from '@models/enums';
import type { OperationBalanceModel } from '@models/operation/operation-balance-model';
import type { OperationListModel } from '@models/operation/operation-list-model';
import type { OperationListRequest } from '@models/operation/operation-list-request';
import type { OperationTotalModel } from '@models/operation/operation-total-model';
import type { WithPaginationRequest } from '@models/requests';
import type { PaginationListResponse } from '@models/types';
import { getSharePercentage } from '@utils/util';

import { dashboardApi } from './dashboard-base-api';

const URL_PREFIX = 'webapi/v1';
const URL_PREFIX_NEW = 'webapi/operations';

export const operationApiSlice = dashboardApi.injectEndpoints({
    endpoints: (build) => ({
        getOperationsList: build.query<
            PaginationListResponse<OperationListModel>,
            OperationListRequest
        >({
            query: ({
                params: { organizationId, page, limit = 20, from, to, checkType, email },
            }) => ({
                url: `${URL_PREFIX_NEW}/${organizationId}/list`,
                params: {
                    page,
                    limit,
                    ...(from && to && { from, to }),
                    checkType,
                    email,
                },
            }),
        }),
        getOperationsListTotals: build.query<OperationTotalModel[], OperationListRequest>({
            query: ({ params: { organizationId, from, to, checkType, email } }) => ({
                url: `${URL_PREFIX_NEW}/${organizationId}/totals`,
                params: {
                    ...(from && to && { from, to }),
                    checkType,
                    email,
                },
            }),
        }),
        getBalance: build.query<OperationBalanceModel, string>({
            query: (organizationId) => ({
                url: `${URL_PREFIX_NEW}/${organizationId}/balance`,
            }),
            providesTags: [ApiTagEnum.CURRENT_BILLING_ORGANIZATION],
        }),

        getInitialBalance: build.query<OperationBalanceModel, string>({
            query: (organizationId) => ({
                url: `${URL_PREFIX_NEW}/${organizationId}/balance`,
            }),
        }),
        getOperationsHistoryLastMonth: build.query<{ data: OperationHistoryModel[] }, string>({
            // last 30 days by default
            query: (organizationId) => `${URL_PREFIX}/operations/${organizationId}/history`,
        }),
        getDocVerInfoLastMonth: build.query<
            OperationHistoryVerificationResultModel,
            { organizationId: string } & WithPaginationRequest
        >({
            query: ({ organizationId }) => `webapi/organization/${organizationId}/docver-info`,
            transformResponse: (response: { data: OperationHistoryVerificationModel }) => {
                const data = Array.isArray(response?.data) ? response.data : [];

                const totalCount = data?.reduce((acc, item) => acc + item.count, 0);

                const resultVerStatus = {
                    [DocsVerificationStatusEnum.INITIAL]: {
                        count: 0,
                        percentage: '0%',
                    },
                    [DocsVerificationStatusEnum.FAILED]: {
                        count: 0,
                        percentage: '0%',
                    },
                    [DocsVerificationStatusEnum.VERIFIED]: {
                        count: 0,
                        percentage: '0%',
                    },
                    [DocsVerificationStatusEnum.IN_PROGRESS]: {
                        count: 0,
                        percentage: '0%',
                    },
                    [DocsVerificationStatusEnum.EXPIRED]: {
                        count: 0,
                        percentage: '0%',
                    },
                    [DocsVerificationStatusEnum.CANCELED]: {
                        count: 0,
                        percentage: '0%',
                    },
                };

                // eslint-disable-next-line no-restricted-syntax
                for (const verificationItem of data) {
                    resultVerStatus[verificationItem.status as keyof typeof resultVerStatus] = {
                        count: verificationItem.count,
                        percentage: getSharePercentage(totalCount, verificationItem.count),
                    };
                }

                const result: OperationHistoryVerificationResultModel = {
                    totalCount,
                    ...resultVerStatus,
                };

                return result;
            },
        }),
    }),
});

export const {
    useLazyGetOperationsListQuery,
    useGetBalanceQuery,
    useLazyGetInitialBalanceQuery,
    useLazyGetOperationsListTotalsQuery,
    useGetOperationsListTotalsQuery,
    useLazyGetOperationsHistoryLastMonthQuery,
    useLazyGetDocVerInfoLastMonthQuery,
} = operationApiSlice;
