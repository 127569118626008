import React from 'react';
import type { IconProps } from '@models/types';

export const CloseCircleThinIcon: React.FC<IconProps> = (props) => (
    <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
    >
        <circle cx='12' cy='12' r='9' stroke='#747391' strokeWidth='2' />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M8.46492 15.5357C8.0744 15.1452 8.0744 14.512 8.46492 14.1215L14.1218 8.46465C14.5123 8.07413 15.1455 8.07413 15.536 8.46465C15.9265 8.85518 15.9265 9.48834 15.536 9.87887L9.87913 15.5357C9.48861 15.9262 8.85544 15.9262 8.46492 15.5357Z'
            fill='currentColor'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M8.46477 8.46443C8.85529 8.07391 9.48846 8.07391 9.87898 8.46443L15.5358 14.1213C15.9264 14.5118 15.9264 15.145 15.5358 15.5355C15.1453 15.926 14.5121 15.926 14.1216 15.5355L8.46477 9.87864C8.07424 9.48812 8.07424 8.85496 8.46477 8.46443Z'
            fill='currentColor'
        />
    </svg>
);
