import React from 'react';
import type { IconProps } from '@models/types';

export const UploadIcon: React.FC<IconProps> = ({ className }) => (
    <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={className}
    >
        <path
            d='M11 15.5001C11 16.0524 11.4477 16.5001 12 16.5001C12.5523 16.5001 13 16.0524 13 15.5001L13 6.41421L14.7929 8.20711C15.1834 8.59763 15.8166 8.59763 16.2071 8.20711C16.5976 7.81658 16.5976 7.18342 16.2071 6.79289L12.7071 3.29289C12.3166 2.90237 11.6834 2.90237 11.2929 3.29289L7.79289 6.79289C7.40237 7.18342 7.40237 7.81658 7.79289 8.20711C8.18342 8.59763 8.81658 8.59763 9.20711 8.20711L11 6.41421V15.5001Z'
            fill='currentColor'
        />
        <path
            d='M5 14C5 13.4477 4.55228 13 4 13C3.44772 13 3 13.4477 3 14V17C3 19.2091 4.79086 21 7 21H17C19.2091 21 21 19.2091 21 17V14C21 13.4477 20.5523 13 20 13C19.4477 13 19 13.4477 19 14V17C19 18.1046 18.1046 19 17 19H7C5.89543 19 5 18.1046 5 17V14Z'
            fill='currentColor'
        />
    </svg>
);
