import type {
    FilterFieldArrayOptionNamesEnum,
    PepEntityRiskScoreEnum,
    PepTagEnum,
} from '@models/enums';
import { FilterDataSourcesNames } from '@models/enums';
import type { AutocompleteOption, FilterMenuProps } from '@models/types';
import { createSlice } from '@reduxjs/toolkit';
import { getFiltersReducer } from '@utils/filters/util';

export type DataSourcesFiltersState = {
    [FilterFieldArrayOptionNamesEnum.TAGS]: Array<AutocompleteOption<PepTagEnum>>;
    [FilterFieldArrayOptionNamesEnum.RISK_SCORES]: Array<
        AutocompleteOption<PepEntityRiskScoreEnum>
    >;
};

export type DataSourcesFiltersSliceState = FilterMenuProps<DataSourcesFiltersState>;

const dataSourcesFiltersInitState: DataSourcesFiltersState = {
    tags: [],
    riskScores: [],
};

const initialState: DataSourcesFiltersSliceState = {
    isOpen: false,
    applyCount: 0,
    filters: dataSourcesFiltersInitState,
    searchQuery: '',
};

export const dataSourcesFiltersSlice = createSlice({
    name: FilterDataSourcesNames.DATA_SOURCES_FILTERS_SLICE,
    initialState,
    reducers: getFiltersReducer<DataSourcesFiltersState, DataSourcesFiltersSliceState>(
        dataSourcesFiltersInitState,
    ),
});
