import { createGlobalStyle, css } from 'styled-components';

const datepickerSelector = '.react-datepicker';
const styles = css`
    .react-datepicker-popper {
        &.mobile-hidden {
            display: none;
        }

        z-index: 1000 !important;

        .datepicker-header {
            &__main {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 12px;
                height: 36px;
                padding: 4px 8px 8px 8px;
            }

            &__controls {
                border-top: 1px solid ${(props) => props.theme.palette.primaryA4};
                display: flex;
                gap: 8px;
                padding: 8px;
            }

            &__year {
                width: 140px;
            }

            &__button {
                width: 24px;
                height: 24px;
                position: relative;
                text-indent: -999em;
                background: none;

                &:hover {
                    &:before {
                        border-color: ${(props) => props.theme.palette.primaryA1};
                    }
                }

                &:before {
                    border-color: ${(props) => props.theme.palette.textMain};
                    border-style: solid;
                    border-width: 3px 3px 0 0;
                    width: 10px;
                    height: 10px;
                    top: 9px;
                    content: '';
                    display: block;
                    position: absolute;
                }

                &--next {
                    &:before {
                        transform: rotate(45deg);
                        left: 5px;
                    }
                }

                &--previous {
                    &:before {
                        transform: rotate(-135deg);
                        left: 7px;
                    }
                }
            }
        }

        ${datepickerSelector} {
            box-shadow: none;
            border: 1px solid ${(props) => props.theme.palette.primaryA3};
            border-radius: 8px;

            &__triangle {
                display: none;
            }

            &__year-read-view--down-arrow,
            &__month-read-view--down-arrow,
            &__month-year-read-view--down-arrow,
            &__navigation-icon::before {
                width: 10px;
                height: 10px;
                top: 12px;
            }

            &__navigation-icon:before {
                border-color: ${(props) => props.theme.palette.textMain};
            }

            &__navigation {
                &:hover {
                    ${datepickerSelector}__navigation-icon:before {
                        border-color: ${(props) => props.theme.palette.primaryA1};
                    }
                }
            }

            &__header {
                border-top-left-radius: 8px;
                border-top-right-radius: 8px !important;
                background-color: ${(props) => props.theme.palette.primaryA6};
                border-bottom: none;
            }

            &__month {
                background-color: ${(props) => props.theme.palette.primaryA6};
                margin: 0;
                padding: 0.4rem;
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
            }

            &__current-month {
                ${(props) => props.theme.typography.smallTextBold};
                height: 28px;
                border-bottom: 1px solid ${(props) => props.theme.palette.primaryA4};
            }

            &__header__dropdown {
                display: flex;
                gap: 8px;
                align-items: center;
                flex-wrap: wrap;
                padding: 0.4rem;

                color: ${(props) => props.theme.palette.textLight};
                ${(props) => props.theme.typography.smallTextBold};
            }

            &__month-dropdown-container,
            &__year-dropdown-container,
            &__month-year-dropdown-container {
                position: relative;
                padding: 4px 12px 4px 12px;
                border-radius: 8px;
                border: 1px solid ${(props) => props.theme.palette.primaryA4};
                justify-content: space-between;
                margin: 0;
                flex-grow: 1;

                &:hover {
                    border: 1px solid ${(props) => props.theme.palette.primaryA1};

                    ${datepickerSelector}__year-read-view--down-arrow,
                    ${datepickerSelector}__month-read-view--down-arrow,
					${datepickerSelector}__month-year-read-view--down-arrow {
                        border-color: ${(props) => props.theme.palette.primaryA1};
                    }
                }
            }

            &__month-dropdown-container {
                flex-grow: 2;
            }

            &__month-read-view,
            &__year-read-view,
            &__month-year-read-view {
                text-align: left;

                &--down-arrow {
                    top: 1px;
                    right: 0;
                }
            }

            &__month-dropdown,
            &__year-dropdown,
            &__month-year-dropdown {
                background-color: ${(props) => props.theme.palette.primaryA6};
                border-color: ${(props) => props.theme.palette.primaryA1};
                // need to cover parent border, width 100% does not include border of parentr
                left: -1px;
                right: -1px;
                top: -1px;
                width: auto;
                border-radius: 8px;
                overflow: hidden;
                max-height: 288px;
                overflow-y: auto;
                scrollbar-width: thin;
            }

            &__month-option,
            &__year-option,
            &__month-year-option {
                text-align: left;
                color: ${(props) => props.theme.palette.textMain};
                ${(props) => props.theme.typography.smallTextBold};
                padding: 8px 12px;
                position: relative;

                &--selected {
                    right: 9px;
                    left: auto;
                }

                ${datepickerSelector}__navigation {
                    width: 24px;
                    height: 24px;

                    &:before {
                        content: '';
                        position: absolute;
                        border-style: solid;

                        left: 5px;
                        border-width: 3px 3px 0 0;
                        width: 10px;
                        height: 10px;
                        border-color: #ccc;
                    }
                }

                ${datepickerSelector}__navigation--years-upcoming {
                    &:before {
                        transform: rotate(-45deg);
                        top: 14px;
                    }
                }

                ${datepickerSelector}__navigation--years-previous {
                    &:before {
                        transform: rotate(135deg);
                        top: 0;
                    }
                }

                &:hover {
                    background-color: ${(props) => props.theme.palette.primaryA4};
                    color: ${(props) => props.theme.palette.primaryA1};
                }
            }

            &__day-name {
                color: ${(props) => props.theme.palette.textLight};
                ${(props) => props.theme.typography.smallTextBold};
            }

            &__week
                ${datepickerSelector}__day--in-range,
                &__week
                ${datepickerSelector}__day--in-selecting-range:not(${datepickerSelector}__day--in-range) {
                &:first-child {
                    &:before {
                        border-bottom-left-radius: 50%;
                        border-top-left-radius: 50%;
                    }
                }

                &:last-child {
                    &:before {
                        border-bottom-right-radius: 50%;
                        border-top-right-radius: 50%;
                    }
                }
            }

            &__day {
                color: ${(props) => props.theme.palette.textLight};
                ${(props) => props.theme.typography.smallTextBold};
                width: 2rem;
                line-height: 2rem;
                margin: 0.1rem 0;

                &:hover {
                    border-radius: 50%;
                    background-color: ${(props) => props.theme.palette.primaryA1};
                    color: ${(props) => props.theme.palette.primaryA7};
                }

                &--outside-month {
                    color: ${(props) => props.theme.palette.primaryA3};
                }

                &--disabled {
                    color: ${(props) => props.theme.palette.secondaryA8};
                    background: none;
                    border: none;
                    cursor: not-allowed;

                    &:hover {
                        color: ${(props) => props.theme.palette.secondaryA8};
                        background: none;
                    }
                }

                &--today:not(&--in-selecting-range):not(&--in-range):not(&--selected) {
                    font-weight: normal;
                    border-radius: 50%;
                    outline: 1px solid ${(props) => props.theme.palette.primaryA1};
                    background-color: transparent;

                    &:hover {
                        border-radius: 50%;
                        background-color: ${(props) => props.theme.palette.primaryA1};
                        color: ${(props) => props.theme.palette.primaryA7};
                    }
                }

                &--keyboard-selected {
                    border-radius: 50%;
                    background-color: ${(props) => props.theme.palette.primaryA6};
                }

                &--selected {
                    border-radius: 50%;
                    background-color: ${(props) => props.theme.palette.primaryA1};
                    color: ${(props) => props.theme.palette.primaryA7};
                }

                &--in-range,
                &--in-selecting-range:not(&--in-range) {
                    background: transparent;
                    position: relative;
                    z-index: 0;

                    &:before {
                        content: '';
                        position: absolute;
                        height: 100%;
                        width: 100%;
                        background: ${(props) => props.theme.palette.primaryA4};
                        z-index: -2;
                        left: 0;
                    }

                    &:hover {
                        &:after {
                            content: '';
                            position: absolute;
                            height: 100%;
                            width: 100%;
                            left: 0;
                            border-radius: 50%;
                            background-color: ${(props) => props.theme.palette.primaryA1};
                            z-index: -1;
                        }
                    }
                }

                &--in-range${datepickerSelector}__day--range-start,
                    &--in-selecting-range:not(
                        &--in-range
                    )${datepickerSelector}__day--selecting-range-start {
                    &:before {
                        border-top-left-radius: 50%;
                        border-bottom-left-radius: 50%;
                    }
                }

                &--in-range${datepickerSelector}__day--range-end,
                    &--in-selecting-range:not(
                        &--in-range
                    )${datepickerSelector}__day--selecting-range-end {
                    &:before {
                        border-top-right-radius: 50%;
                        border-bottom-right-radius: 50%;
                    }
                }

                &--in-range${datepickerSelector}__day--range-start,
                    &--in-range${datepickerSelector}__day--range-end,
                    &--in-selecting-range:not(
                        &--in-range
                    )${datepickerSelector}__day--selecting-range-start,
                    &--in-selecting-range:not(
                        &--in-range
                    )${datepickerSelector}__day--selecting-range-end {
                    color: ${(props) => props.theme.palette.primaryA7};
                    &:after {
                        content: '';
                        position: absolute;
                        height: 100%;
                        width: 100%;
                        left: 0;
                        border-radius: 50%;
                        background-color: ${(props) => props.theme.palette.primaryA1};
                        z-index: -1;
                    }
                }
            }
        }
    }
`;

export const DatePickerGlobalStyles = createGlobalStyle`${styles}`;
