import React from 'react';
import { Tooltip } from '@components/moleculs/tooltip';
import { useResponsiveTooltip } from '@hooks/use-responsive-tooltip';
import type { TooltipProps } from '@mui/material/Tooltip';

export const ResponsiveTooltip: React.FC<TooltipProps> = (props) => {
    const { isOpen, toggleTooltip, isMdOrTouch } = useResponsiveTooltip();

    return (
        <Tooltip
            arrow={true}
            open={isMdOrTouch ? isOpen : undefined}
            onClick={toggleTooltip}
            {...props}
        />
    );
};
