import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import type { RootState } from '@store/root-reducer';

export const useReplaceFavicon = () => {
    const faviconUrl = useSelector(
        (state: RootState) => state.domain.settings.logo_settings.favicon_url,
    );

    useEffect(() => {
        if (faviconUrl) {
            let link: HTMLLinkElement | null = document.querySelector("link[rel~='icon']");

            if (!link) {
                link = document.createElement('link');
                link.rel = 'icon';
                document.querySelector('head')?.appendChild(link);
            }
            link.href = faviconUrl;
        }
    }, [faviconUrl]);
};
