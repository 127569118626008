import type { MouseEvent } from 'react';
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@components/atoms/button';
import { Typography } from '@components/atoms/typography';
import { ClickAwayListener } from '@components/moleculs/click-away-listener';
import { Grow } from '@components/moleculs/grow';
import { Paper } from '@components/moleculs/papper';
import type { DeleteConfirmationPopupProps } from '@components/organisms/delete-confirmation-popup/types';
import Popper from '@mui/material/Popper';

import { DeleteConfirmationPopupContainer } from './styles';

export { DeleteConfirmationPopupGlobalStyles } from './global-styles';

export const DeleteConfirmationPopup: React.FC<DeleteConfirmationPopupProps> = ({
    title,
    onConfirm,
    children,
    placement = 'bottom-start',
    onCancel,
}) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef(null);
    const toggleOpen = useCallback(
        (e: React.MouseEvent) => {
            e.stopPropagation();
            setIsOpen(!isOpen);
        },
        [isOpen],
    );

    const handleClickAway = useCallback(() => {
        setIsOpen(false);
    }, []);

    const handleCancelButtonClick = useCallback(
        (e: MouseEvent) => {
            setIsOpen(false);
            onCancel?.(e);
        },
        [onCancel],
    );

    return (
        <DeleteConfirmationPopupContainer>
            <ClickAwayListener
                onClickAway={handleClickAway}
                touchEvent={isOpen ? undefined : false}
                mouseEvent={isOpen ? undefined : false}
            >
                <span>
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                    <span
                        className='icon-container'
                        ref={ref}
                        onClick={toggleOpen}
                        data-delete-confirmation-role='button'
                    >
                        {children}
                    </span>
                    {isOpen && (
                        <Popper anchorEl={ref.current} placement={placement} open={isOpen}>
                            <Grow
                                in={true}
                                style={{
                                    transformOrigin: 'right top',
                                }}
                            >
                                <div>
                                    <Paper>
                                        <div className='delete-confirmation-popup'>
                                            <Typography
                                                variant='small-text-bold'
                                                color='text-main'
                                                tag='div'
                                                className='confirmation-text'
                                            >
                                                {title || ''}
                                            </Typography>
                                            <div className='actions'>
                                                <Button
                                                    variant='tertiary'
                                                    size='s'
                                                    onClick={handleCancelButtonClick}
                                                >
                                                    {t('common.cancel')}
                                                </Button>
                                                <Button
                                                    variant='primary'
                                                    size='s'
                                                    onClick={onConfirm}
                                                >
                                                    {t('common.yes')}
                                                </Button>
                                            </div>
                                        </div>
                                    </Paper>
                                </div>
                            </Grow>
                        </Popper>
                    )}
                </span>
            </ClickAwayListener>
        </DeleteConfirmationPopupContainer>
    );
};
