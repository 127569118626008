import type {
    ApplicantDocModel,
    ApplicantModel,
    DataSourceModel,
    DocsVerificationResultModel,
    PaymentHistoryModel,
    PepReportHistoryModel,
    PepReportModel,
    PoaGenHistoryModel,
    RtkQueryResult,
    TeamMemberModel,
    VerificationProfileModel,
    WebhookHistoryModel,
} from '@models';
import { InfiniteScrollTableNamesEnum } from '@models/enums';
import type { OperationListModel } from '@models/operation/operation-list-model';
import type {
    AccountModel,
    AccountOrganizationModel,
    EventModel,
    OrganizationAccountModel,
    OrganizationAmlCheckModel,
    OrganizationApplicantModel,
    OrganizationModel,
    OrganizationVerificationProfileModel,
} from '@modules/admin-panel/models';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

type TableData<T> = {
    data: T[];
    currentPage: number;
    isInitialized: boolean;
    hasNextPage: boolean;
    requestId?: string;
    endpointName?: string;
    isError?: boolean;
    isLoading?: boolean;
    error: RtkQueryResult['error'];
    status?: number;
    total?: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    params?: any;
    isDefaultData?: boolean;
};

export type InfiniteScrollTableState = {
    [InfiniteScrollTableNamesEnum.WEBHOOKS_HISTORY]: TableData<WebhookHistoryModel>;
    [InfiniteScrollTableNamesEnum.OPERATIONS_HISTORY]: TableData<OperationListModel>;
    [InfiniteScrollTableNamesEnum.PAYMENTS_HISTORY]: TableData<PaymentHistoryModel>;
    [InfiniteScrollTableNamesEnum.DATA_SOURCES]: TableData<DataSourceModel>;
    teamMembers: TableData<TeamMemberModel>;
    [InfiniteScrollTableNamesEnum.APPLICANT_PERSON]: TableData<ApplicantModel>;
    [InfiniteScrollTableNamesEnum.APPLICANT_ORGANIZATION]: TableData<ApplicantModel>;
    [InfiniteScrollTableNamesEnum.APPLICANT_ALERTS]: TableData<ApplicantModel>;
    [InfiniteScrollTableNamesEnum.SEARCH_APPLICANT]: TableData<ApplicantModel>;
    [InfiniteScrollTableNamesEnum.APPLICANT_DOCS_VERIFICATION_RESULTS]: TableData<ApplicantModel>;
    [InfiniteScrollTableNamesEnum.APPLICANT_PEP_HISTORY_RESULTS]: TableData<ApplicantModel>;
    [InfiniteScrollTableNamesEnum.PEP_REPORT]: TableData<PepReportModel>;
    [InfiniteScrollTableNamesEnum.PEP_HISTORY]: TableData<PepReportHistoryModel>;
    [InfiniteScrollTableNamesEnum.DOCS_VERIFICATION_RESULTS]: TableData<DocsVerificationResultModel>;
    [InfiniteScrollTableNamesEnum.APPLICANT_DOCS]: TableData<ApplicantDocModel>;
    verificationProfiles: TableData<VerificationProfileModel>;
    accounts: TableData<AccountModel>;
    organizations: TableData<OrganizationModel>;
    organizationAccounts: TableData<OrganizationAccountModel>;
    whiteLabelOrganizationApplicants: TableData<OrganizationApplicantModel>;
    [InfiniteScrollTableNamesEnum.ADMIN_DOCS_VERIFICATION_RESULTS]: TableData<DocsVerificationResultModel>;
    whiteLabelOrganizationAmlChecks: TableData<OrganizationAmlCheckModel>;
    whiteLabelOrganizationVerificationProfiles: TableData<OrganizationVerificationProfileModel>;
    organizationPaymentHistory: TableData<PaymentHistoryModel>;
    accountOrganizations: TableData<AccountOrganizationModel>;
    accountEvents: TableData<EventModel>;
    poaGen: TableData<PoaGenHistoryModel>;
};

const defaultValue = {
    data: [],
    currentPage: 0,
    isInitialized: false,
    hasNextPage: false,
    requestId: '',
    endpointName: '',
    isError: false,
    isLoading: false,
    error: undefined,
    status: undefined,
    params: undefined,
    isDefaultData: undefined,
};

const initialState: InfiniteScrollTableState = {
    [InfiniteScrollTableNamesEnum.WEBHOOKS_HISTORY]: defaultValue,
    [InfiniteScrollTableNamesEnum.OPERATIONS_HISTORY]: defaultValue,
    [InfiniteScrollTableNamesEnum.PAYMENTS_HISTORY]: defaultValue,
    [InfiniteScrollTableNamesEnum.DATA_SOURCES]: defaultValue,
    teamMembers: defaultValue,
    [InfiniteScrollTableNamesEnum.APPLICANT_PERSON]: defaultValue,
    [InfiniteScrollTableNamesEnum.APPLICANT_ORGANIZATION]: defaultValue,
    [InfiniteScrollTableNamesEnum.APPLICANT_ALERTS]: defaultValue,
    [InfiniteScrollTableNamesEnum.SEARCH_APPLICANT]: defaultValue,
    [InfiniteScrollTableNamesEnum.APPLICANT_DOCS_VERIFICATION_RESULTS]: defaultValue,
    [InfiniteScrollTableNamesEnum.APPLICANT_PEP_HISTORY_RESULTS]: defaultValue,
    [InfiniteScrollTableNamesEnum.PEP_HISTORY]: defaultValue,
    [InfiniteScrollTableNamesEnum.DOCS_VERIFICATION_RESULTS]: defaultValue,
    [InfiniteScrollTableNamesEnum.PEP_REPORT]: defaultValue,
    [InfiniteScrollTableNamesEnum.APPLICANT_DOCS]: defaultValue,
    verificationProfiles: defaultValue,

    accounts: defaultValue,
    organizations: defaultValue,
    organizationAccounts: defaultValue,
    whiteLabelOrganizationApplicants: defaultValue,
    [InfiniteScrollTableNamesEnum.ADMIN_DOCS_VERIFICATION_RESULTS]: defaultValue,
    whiteLabelOrganizationAmlChecks: defaultValue,
    whiteLabelOrganizationVerificationProfiles: defaultValue,
    organizationPaymentHistory: defaultValue,
    accountOrganizations: defaultValue,
    accountEvents: defaultValue,
    poaGen: defaultValue,
};

export const infiniteScrollTableSlice = createSlice({
    name: 'infiniteScrollTable',
    initialState,
    reducers: {
        setTableData: <T extends keyof InfiniteScrollTableState>(
            state: InfiniteScrollTableState,
            {
                payload,
            }: PayloadAction<{
                name: T;
                data: InfiniteScrollTableState[T]['data'];
                currentPage?: number;
                hasNextPage?: boolean;
                isInitialized?: boolean;
                requestId?: string;
                endpointName?: string;
                isError?: boolean;
                isLoading?: boolean;
                error?: RtkQueryResult['error'];
                status?: number;
                total?: number;
                params?: unknown;
            }>,
        ) => {
            if (payload.isInitialized === undefined) {
                state[payload.name].isInitialized = true;
            } else {
                state[payload.name].isInitialized = payload.isInitialized;
            }
            state[payload.name].data = payload?.data || [];
            if (payload.currentPage !== undefined) {
                state[payload.name].currentPage = payload.currentPage;
            }
            if (payload.hasNextPage !== undefined) {
                state[payload.name].hasNextPage = payload.hasNextPage;
            }

            if (payload.requestId) {
                state[payload.name].requestId = payload.requestId;
            }

            if (state[payload.name].isDefaultData === undefined) {
                state[payload.name].isDefaultData = !!payload?.data?.length;
            }

            if (state[payload.name].isDefaultData === false && !!payload?.data?.length) {
                state[payload.name].isDefaultData = true;
            }

            state[payload.name].endpointName = payload.endpointName;
            state[payload.name].isError = payload.isError;
            state[payload.name].isLoading = payload.isLoading;
            state[payload.name].params = payload.params;
            state[payload.name].error = payload.error;
            state[payload.name].status = payload.status;
            state[payload.name].total = payload.total;
        },
        appendTableData: <T extends keyof InfiniteScrollTableState>(
            state: InfiniteScrollTableState,
            {
                payload,
            }: PayloadAction<{
                name: T;
                data: InfiniteScrollTableState[T]['data'];
                currentPage?: number;
                hasNextPage?: boolean;
                requestId?: string;
                endpointName?: string;
                isError?: boolean;
                isLoading?: boolean;
                error?: RtkQueryResult['error'];
                status?: number;
                total?: number;
                params?: unknown;
            }>,
        ) => {
            state[payload.name].data = [
                ...state[payload.name].data,
                ...payload.data,
            ] as InfiniteScrollTableState[T]['data'];
            state[payload.name].isInitialized = true;
            if (payload.currentPage !== undefined) {
                state[payload.name].currentPage = payload.currentPage;
            }
            if (payload.hasNextPage !== undefined) {
                state[payload.name].hasNextPage = payload.hasNextPage;
            }
            if (payload.requestId) {
                state[payload.name].requestId = payload.requestId;
            }

            state[payload.name].endpointName = payload.endpointName;
            state[payload.name].isError = payload.isError;
            state[payload.name].isLoading = payload.isLoading;
            state[payload.name].params = payload.params;
            state[payload.name].error = payload.error;
            state[payload.name].status = payload.status;
            state[payload.name].total = payload.total;
        },
        updateRecordData: <T extends keyof InfiniteScrollTableState>(
            state: InfiniteScrollTableState,
            {
                payload,
            }: PayloadAction<{
                name: T;
                index: number;
                data: InfiniteScrollTableState[T]['data'][number];
            }>,
        ) => {
            state[payload.name].data[payload.index] = payload.data;
        },
        removeRecordByIndex: <T extends keyof InfiniteScrollTableState>(
            state: InfiniteScrollTableState,
            {
                payload,
            }: PayloadAction<{
                name: T;
                index: number;
            }>,
        ) => {
            const { data } = state[payload.name];

            const newValue = [...data];

            newValue.splice(payload.index, 1);

            state[payload.name].data = newValue as InfiniteScrollTableState[T]['data'];
        },
        setCurrentPage: <T extends keyof InfiniteScrollTableState>(
            state: InfiniteScrollTableState,
            {
                payload,
            }: PayloadAction<{
                name: T;
                currentPage: number;
            }>,
        ) => {
            state[payload.name].currentPage = payload.currentPage;
        },
        reset: <T extends keyof InfiniteScrollTableState>(
            state: InfiniteScrollTableState,
            {
                payload,
            }: PayloadAction<{
                name: T;
                data?: InfiniteScrollTableState[T]['data'];
                currentPage?: number;
            }>,
        ) => {
            state[payload.name].data = payload?.data ?? [];
            state[payload.name].isInitialized = false;
            state[payload.name].currentPage = 0;
            state[payload.name].requestId = '';
            state[payload.name].endpointName = '';
            state[payload.name].isError = false;
            state[payload.name].isLoading = false;
            state[payload.name].error = undefined;
            state[payload.name].status = undefined;
            state[payload.name].params = undefined;
            state[payload.name].isDefaultData = undefined;
        },
    },
});
