import type {
    FilterFieldArrayOptionNamesEnum,
    FilterFieldDateNamesEnum,
    OrganizationRoleEnum,
} from '@models/enums';
import { FilterAccountNames } from '@models/enums';
import type { AutocompleteOption, DateRangeValue, FilterMenuProps } from '@models/types';
import { createSlice } from '@reduxjs/toolkit';
import { getFiltersReducer } from '@utils/filters/util';

import type { OrganizationModel } from '../../models';

export type AccountFiltersState = {
    [FilterFieldDateNamesEnum.LAST_VISIT_RANGE]: DateRangeValue;
    [FilterFieldArrayOptionNamesEnum.ORG_ROLES]: Array<AutocompleteOption<OrganizationRoleEnum>>;
    orgId: AutocompleteOption<string> | null;
    [FilterFieldArrayOptionNamesEnum.COUNTRIES]: Array<AutocompleteOption<string>>;
    organizations: OrganizationModel[];
};

export type AccountFiltersSliceState = FilterMenuProps<AccountFiltersState>;

export const accountFiltersInitState: AccountFiltersState = {
    lastVisitRange: null,
    orgRoles: [],
    orgId: null,
    countries: [],
    organizations: [],
};

const initialState: AccountFiltersSliceState = {
    isOpen: false,
    applyCount: 0,
    filters: accountFiltersInitState,
};

export const accountFiltersSlice = createSlice({
    name: FilterAccountNames.ACCOUNT_FILTERS_SLICE,
    initialState,
    reducers: getFiltersReducer<AccountFiltersState, AccountFiltersSliceState>(
        accountFiltersInitState,
    ),
});
