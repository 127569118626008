import React from 'react';
import type { IconProps } from '@models/types';

export const DotsIcon: React.FC<IconProps> = ({ className }) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='currentColor'
        className={className}
    >
        <path
            d='M14.7148 4C14.7148 5.10457 13.8194 6 12.7148 6C11.6103 6 10.7148 5.10457 10.7148 4C10.7148 2.89543 11.6103 2 12.7148 2C13.8194 2 14.7148 2.89543 14.7148 4Z'
            fill='currentColor'
        />
        <path
            d='M14.7148 12C14.7148 13.1046 13.8194 14 12.7148 14C11.6103 14 10.7148 13.1046 10.7148 12C10.7148 10.8954 11.6103 10 12.7148 10C13.8194 10 14.7148 10.8954 14.7148 12Z'
            fill='currentColor'
        />
        <path
            d='M14.7148 20C14.7148 21.1046 13.8194 22 12.7148 22C11.6103 22 10.7148 21.1046 10.7148 20C10.7148 18.8954 11.6103 18 12.7148 18C13.8194 18 14.7148 18.8954 14.7148 20Z'
            fill='currentColor'
        />
    </svg>
);
