import React from 'react';
import type { IconProps } from '@models/types';

export const UsdtIcon: React.FC<IconProps> = (props) => (
    <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
    >
        <path
            d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z'
            fill='#50AF95'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M13.3871 12.6881C13.3096 12.6938 12.9093 12.7172 12.0163 12.7172C11.306 12.7172 10.8017 12.6963 10.6248 12.6881C7.87999 12.5702 5.83121 12.1033 5.83121 11.5443C5.83121 10.9854 7.87999 10.5191 10.6248 10.3993V12.2232C10.8043 12.2358 11.3183 12.2654 12.0286 12.2654C12.8809 12.2654 13.3077 12.2307 13.3845 12.2238V10.4005C16.1235 10.5198 18.1678 10.9866 18.1678 11.5443C18.1678 12.102 16.1242 12.5689 13.3845 12.6875L13.3871 12.6881ZM13.3871 10.2119V8.57978H17.2096V6.09091H6.80233V8.57978H10.6242V10.2113C7.51775 10.3507 5.18164 10.9519 5.18164 11.6724C5.18164 12.3929 7.51775 12.9935 10.6242 13.1336V18.3636H13.3864V13.1317C16.4858 12.9922 18.818 12.3916 18.818 11.6718C18.818 10.9519 16.4877 10.3513 13.3864 10.2113L13.3871 10.2119Z'
            fill='white'
        />
    </svg>
);
