export enum DocsVerificationTypeFieldVisaEnum {
    V = 'V',
    C = 'C',
}
export enum DocsVerificationTypeFieldIdCardEnum {
    I = 'I',
    A = 'A',
    ID = 'id',
}

export enum DocsVerificationTypeFieldPassportEnum {
    P = 'P',
}
export enum DocsVerificationTypeFieldResidencePermitEnum {
    IR = 'IR',
}

export enum DocsVerificationTypeFieldDriverLicenseEnum {
    driver_license = 'driver_license',
}
