import type { FC } from 'react';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { LinkStyled } from '@components/atoms/link/styles';
import type { LinkProps } from '@components/atoms/link/types';
import cn from 'classnames';

export const Link: FC<LinkProps> = ({ href, to, dashed, ...rest }) => {
    if (to) {
        return <LinkStyled as={RouterLink} className={cn({ dashed })} to={to} {...rest} />;
    }

    if (href) {
        return <LinkStyled href={href} className={cn({ dashed })} {...rest} />;
    }

    return <LinkStyled className={cn({ dashed })} {...rest} />;
};
