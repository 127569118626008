import type { AnyAction, PayloadAction } from '@reduxjs/toolkit';

import type { AuthUserInfo, AuthUserSliceState, PayloadActionError } from './types';
import { RequestStatus } from './types';

export const registrationSuccess = (state: AuthUserSliceState, action: AnyAction) => {
    state.registration.status = RequestStatus.REDIRECT;
    state.registration.email = action.meta.arg?.originalArgs?.email;
};

export const registrationPending = (state: AuthUserSliceState) => {
    state.registration.status = RequestStatus.LOADING;
};

export const registrationRejected = (state: AuthUserSliceState, action: PayloadAction<unknown>) => {
    const { payload } = action as PayloadActionError;

    state.registration.message = payload?.data?.message || 'Something went wrong';
    state.registration.status = RequestStatus.ERROR;
};

export const loginSuccess = (state: AuthUserSliceState, action: AnyAction) => {
    state.registration.status = RequestStatus.SUCCESS;
    state.user.info = action.payload;
};

export const loginPending = (state: AuthUserSliceState) => {
    state.registration.status = RequestStatus.LOADING;
    state.registration.message = undefined;
    state.registration.email = undefined;
};

export const loginRejected = (state: AuthUserSliceState, action: PayloadAction<unknown>) => {
    const { payload, meta } = action as PayloadActionError;

    state.registration.message = payload?.data?.message || 'Something went wrong';

    if (
        payload?.status === 403 &&
        payload?.data?.message === 'Please activate your account first'
    ) {
        state.registration.status = RequestStatus.REDIRECT;
        state.registration.email = meta.arg?.originalArgs?.email;
    } else {
        state.registration.status = RequestStatus.ERROR;
    }
};

export const googleLoginSuccess = (state: AuthUserSliceState, action: AnyAction) => {
    state.authUserGoogle.status = RequestStatus.SUCCESS;
    state.user.status = RequestStatus.EXPECTATION;
    state.user.info = action.payload;
};

export const googleLoginPending = (state: AuthUserSliceState) => {
    state.authUserGoogle.status = RequestStatus.LOADING;
};

export const googleLoginReject = (state: AuthUserSliceState) => {
    state.authUserGoogle.status = RequestStatus.ERROR;
};

export const resetPasswordSuccess = (state: AuthUserSliceState, action: AnyAction) => {
    state.registration.status = RequestStatus.REDIRECT_RESET_PASSWORD;
    state.registration.message = action.payload?.message;
    state.registration.email = action.meta.arg?.originalArgs?.email;
};

export const resetPasswordPending = (state: AuthUserSliceState) => {
    state.registration.status = RequestStatus.LOADING;
    state.registration.message = undefined;
    state.registration.email = undefined;
};

export const resetPasswordRejected = (
    state: AuthUserSliceState,
    action: PayloadAction<unknown>,
) => {
    const { payload, meta } = action as PayloadActionError;

    state.registration.message = payload?.data?.message;
    state.registration.status = RequestStatus.ERROR;
    state.registration.email = meta?.arg?.originalArgs?.email;
};

export const changePasswordSuccess = (state: AuthUserSliceState, action: AnyAction) => {
    state.registration.status = RequestStatus.SUCCESS;
    state.registration.message = action.payload?.message;
};

export const changePasswordPending = (state: AuthUserSliceState) => {
    state.registration.status = RequestStatus.LOADING;
    state.registration.message = undefined;
    state.registration.email = undefined;
};

export const changePasswordRejected = (
    state: AuthUserSliceState,
    action: PayloadAction<unknown>,
) => {
    const { payload } = action as PayloadActionError;

    state.registration.message = payload?.data?.message;
    state.registration.status = RequestStatus.ERROR;
};

export const logoutSuccess = (state: AuthUserSliceState) => {
    state.user.status = RequestStatus.EXPECTATION;
    state.user.info = null;
};

export const infoSuccess = (state: AuthUserSliceState, action: PayloadAction<AuthUserInfo>) => {
    state.user.status = RequestStatus.SUCCESS;
    state.user.info = action.payload;
};

export const infoPending = (state: AuthUserSliceState) => {
    state.user.status = RequestStatus.LOADING;
};

export const infoRejected = (state: AuthUserSliceState) => {
    state.user.status = RequestStatus.ERROR;
    state.user.info = null;
};
