import React from 'react';
import type { IconProps } from '@models/types';

export const MinusIcon: React.FC<IconProps> = ({ className }) => (
    <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={className}
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M19 12C19 12.5523 18.5523 13 18 13L6 13C5.44771 13 5 12.5523 5 12C5 11.4477 5.44771 11 6 11H18C18.5523 11 19 11.4477 19 12Z'
            fill='currentColor'
        />
    </svg>
);
