import React from 'react';

export const MobileMenuIcon: React.FC = () => (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M21 17C21 17.5523 20.5523 18 20 18L4 18C3.44772 18 3 17.5523 3 17C3 16.4477 3.44772 16 4 16L20 16C20.5523 16 21 16.4477 21 17Z'
            fill='currentColor'
        />
        <path
            d='M14 12C14 12.5523 13.5523 13 13 13L4 13C3.44772 13 3 12.5523 3 12C3 11.4477 3.44772 11 4 11L13 11C13.5523 11 14 11.4477 14 12Z'
            fill='currentColor'
        />
        <path
            d='M20 8C20.5523 8 21 7.55229 21 7C21 6.44771 20.5523 6 20 6L4 6C3.44772 6 3 6.44771 3 7C3 7.55229 3.44772 8 4 8L20 8Z'
            fill='currentColor'
        />
    </svg>
);
