export enum PoaGenTemplateFieldsEnum {
    TITLE = 'title',
    FIRST_NAME = 'first_name',
    LAST_NAME = 'last_name',
    ADDRESS = 'address',
    AREA = 'area',
    CITY = 'city',
    POSTCODE = 'postcode',
    ACCOUNT_OPENING_DATE = 'account_opening_date',
    STATEMENT_DATE = 'statement_date',
    ACCOUNT_NUMBER = 'account_number',
    STATEMENT_NUMBER = 'statement_number',
}
