import React from 'react';
import type { IconProps } from '@models/types';

export const EyeOpenIcon: React.FC<IconProps> = (props) => (
    <svg
        width='18'
        height='11'
        viewBox='0 0 18 11'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
    >
        <path
            d='M9 0C2.34 0 0 5.5 0 5.5C0 5.5 2.934 11 9 11C15.066 11 18 5.5 18 5.5C18 5.5 15.66 0 9 0ZM9 8.8C7.218 8.8 5.76 7.315 5.76 5.5C5.76 3.685 7.218 2.2 9 2.2C10.782 2.2 12.24 3.685 12.24 5.5C12.24 7.315 10.782 8.8 9 8.8Z'
            fill='currentColor'
        />
    </svg>
);
