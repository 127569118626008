import type {
    OperationHistoryGraphLastMonth,
    OperationHistoryModel,
    OperationHistoryTotalModel,
} from '@models';
import { OperationTypeEnum } from '@models/enums';
import type { OperationDayCountType } from '@models/types';

export const operationsHistoryMonth = (data?: OperationHistoryModel[]) => {
    const operationHistoryTotal: OperationHistoryTotalModel = {} as OperationHistoryTotalModel;
    let operationHistoryGraphLastMonth: OperationHistoryGraphLastMonth = {
        operationDays: [] as string[],
        operationsByDay: {
            [OperationTypeEnum.AML_CHECK_ONEOFF]: [],
            [OperationTypeEnum.DOCVER_ONEOFF]: [],
            [OperationTypeEnum.AML_MONITORING_ONGOING]: [],
            [OperationTypeEnum.DOCVER_W_AML_MONITORING_INITIAL]: [],
            [OperationTypeEnum.AML_MONITORING_INITIAL]: [],
            [OperationTypeEnum.DEPOSIT]: [],
        },
    };

    if (!data) {
        return {
            operationHistoryGraphLastMonth,
            operationHistoryTotal,
        };
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const key of Object.values(OperationTypeEnum)) {
        operationHistoryTotal[key] = {
            count: 0,
            balanceChange: 0,
        };
    }

    if (data == null || !Array.isArray(data)) {
        operationHistoryGraphLastMonth = {
            operationDays: [] as string[],
            operationsByDay: {
                [OperationTypeEnum.AML_CHECK_ONEOFF]: [] as string[],
                [OperationTypeEnum.DOCVER_ONEOFF]: [] as string[],
                [OperationTypeEnum.AML_MONITORING_ONGOING]: [] as string[],
                [OperationTypeEnum.DOCVER_W_AML_MONITORING_INITIAL]: [] as string[],
                [OperationTypeEnum.AML_MONITORING_INITIAL]: [] as string[],
                [OperationTypeEnum.DEPOSIT]: [] as string[],
            },
        };
    }
    const operationTuple = [
        OperationTypeEnum.AML_CHECK_ONEOFF,
        OperationTypeEnum.DOCVER_ONEOFF,
        OperationTypeEnum.AML_MONITORING_ONGOING,
        OperationTypeEnum.DOCVER_W_AML_MONITORING_INITIAL,
        OperationTypeEnum.AML_MONITORING_INITIAL,
        OperationTypeEnum.DEPOSIT,
    ];

    operationHistoryGraphLastMonth = [...data].reverse().reduce(
        (acc, item) => {
            acc.operationDays = [...acc.operationDays, item.date];

            const operationDayCount: OperationDayCountType = {
                [OperationTypeEnum.AML_CHECK_ONEOFF]: 0,
                [OperationTypeEnum.DOCVER_ONEOFF]: 0,
                [OperationTypeEnum.AML_MONITORING_ONGOING]: 0,
                [OperationTypeEnum.DOCVER_W_AML_MONITORING_INITIAL]: 0,
                [OperationTypeEnum.AML_MONITORING_INITIAL]: 0,
                [OperationTypeEnum.DEPOSIT]: 0,
            };

            if (item.operations?.length) {
                // eslint-disable-next-line no-restricted-syntax
                for (const operationDay of item.operations) {
                    operationDayCount[operationDay.type as keyof typeof operationDayCount] =
                        operationDay.count;
                }
            }

            // eslint-disable-next-line no-restricted-syntax
            for (const operation in operationDayCount) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                if (operationTuple.includes(operation)) {
                    acc.operationsByDay[operation as keyof typeof acc.operationsByDay] = [
                        ...acc.operationsByDay[operation as keyof typeof acc.operationsByDay],
                        operationDayCount[operation as keyof typeof operationDayCount].toString(),
                    ];
                }
            }

            return acc;
        },
        {
            operationDays: [] as string[],
            operationsByDay: {
                [OperationTypeEnum.AML_CHECK_ONEOFF]: [],
                [OperationTypeEnum.DOCVER_ONEOFF]: [],
                [OperationTypeEnum.AML_MONITORING_ONGOING]: [],
                [OperationTypeEnum.DOCVER_W_AML_MONITORING_INITIAL]: [],
                [OperationTypeEnum.AML_MONITORING_INITIAL]: [],
                [OperationTypeEnum.DEPOSIT]: [],
            },
        } as OperationHistoryGraphLastMonth,
    );
    // eslint-disable-next-line no-restricted-syntax
    for (const day of data) {
        if (!day.operations) {
            // eslint-disable-next-line no-continue
            continue;
        }
        // eslint-disable-next-line no-restricted-syntax
        for (const operation of day.operations) {
            if (Object.keys(operationHistoryTotal).includes(operation.type)) {
                operationHistoryTotal[operation.type].count += operation?.count || 0;
                operationHistoryTotal[operation.type].balanceChange += operation.balance_change;
            }
        }
    }

    return {
        operationHistoryGraphLastMonth,
        operationHistoryTotal,
    };
};
