import type { OperationBalanceModel } from '@models/operation/operation-balance-model';
import type { OperationListModel } from '@models/operation/operation-list-model';
import type { OperationListRequest } from '@models/operation/operation-list-request';
import type { OperationTotalModel } from '@models/operation/operation-total-model';
import type { PaginationListResponse } from '@models/types';
import { AdminApiTagEnum } from '@modules/admin-panel/api/enums';

import { adminPanelApi } from './admin-panel-base-api';

const URL_PREFIX = '/webapi/wl/operations';

export const adminPanelOperationsApiSlice = adminPanelApi.injectEndpoints({
    endpoints: (build) => ({
        getBalance: build.query<OperationBalanceModel, { organizationId: string; id?: string }>({
            query: ({ organizationId, id }) =>
                id
                    ? `${URL_PREFIX}/${organizationId}/${id}/balance`
                    : `${URL_PREFIX}/${organizationId}/${organizationId}/balance`,
            providesTags: [AdminApiTagEnum.ORGANIZATION_BALANCE],
        }),
        getOperationsList: build.query<
            PaginationListResponse<OperationListModel>,
            OperationListRequest
        >({
            query: ({
                params: { organizationId, id, page, limit = 20, from, to, checkType, email },
            }) => ({
                url: `${URL_PREFIX}/${organizationId}/${id}/list`,
                params: {
                    page,
                    limit,
                    ...(from && to && { from, to }),
                    checkType,
                    email,
                },
            }),
        }),
        getOperationsListTotals: build.query<OperationTotalModel[], OperationListRequest>({
            query: ({ params: { organizationId, id, from, to, checkType, email } }) => ({
                url: `${URL_PREFIX}/${organizationId}/${id}/totals`,
                params: {
                    ...(from && to && { from, to }),
                    checkType,
                    email,
                },
            }),
        }),
    }),
});

export const { useGetBalanceQuery } = adminPanelOperationsApiSlice;
