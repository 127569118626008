import React from 'react';
import type { IconProps } from '@models/types';

export const ArrowTailIcon: React.FC<IconProps> = ({ ...props }) => (
    <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='currentColor'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M11.4939 12.7441C11.1684 13.0695 11.1684 13.5971 11.4939 13.9226C11.8193 14.248 12.3469 14.248 12.6724 13.9226L16.0057 10.5892L16.595 9.99999L16.0057 9.41073L12.6724 6.0774C12.3469 5.75196 11.8193 5.75196 11.4939 6.0774C11.1684 6.40284 11.1684 6.93048 11.4939 7.25591L13.4046 9.16666L4.16666 9.16666C3.70643 9.16666 3.33333 9.53975 3.33333 9.99999C3.33333 10.4602 3.70643 10.8333 4.16666 10.8333L13.4046 10.8333L11.4939 12.7441Z'
            fill='currentColor'
        />
    </svg>
);
