import React from 'react';
import type { IconProps } from '@models/types';

export const PersonIcon: React.FC<IconProps> = ({ className }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={ className }>
        <path
            d="M12.0056 10.5C11.3847 10.5 10.7887 10.3505 10.2672 10.0764C9.07514 9.42857 8.2556 8.18272 8.23077 6.76246C8.23077 4.66944 9.91951 3 11.9808 3C14.0669 3 15.7308 4.69435 15.7308 6.76246C15.7308 8.20764 14.9361 9.45349 13.744 10.0764C13.2225 10.3505 12.6265 10.5 12.0056 10.5Z"
            fill="currentColor"
        />
        <path
            d="M18.7092 15.3956L18.992 18.4208C19.0691 19.1707 18.5807 19.8429 17.8609 19.9981L13.7736 20.8255C12.6168 21.0582 11.4086 21.0582 10.2518 20.8255L6.16445 19.9981C5.44467 19.8429 4.93054 19.1707 5.00765 18.4208L5.29043 15.3956C5.49608 13.3271 6.85852 11.6464 8.70939 11C9.58342 11.8016 10.7402 12.267 11.9998 12.267C13.2594 12.267 14.4162 11.7757 15.2903 11C17.1411 11.6464 18.5036 13.3271 18.7092 15.3956Z"
            fill="currentColor"
        />
    </svg>
);
