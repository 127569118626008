import React from 'react';
import type { IconProps } from '@models/types';

export const CheckMarkIcon: React.FC<IconProps> = ({ className }) => (
    <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={className}
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM16.2071 10.7071C16.5976 10.3166 16.5976 9.68342 16.2071 9.29289C15.8166 8.90237 15.1834 8.90237 14.7929 9.29289L11 13.0858L9.20711 11.2929C8.81658 10.9024 8.18342 10.9024 7.79289 11.2929C7.40237 11.6834 7.40237 12.3166 7.79289 12.7071L10.2929 15.2071C10.4804 15.3946 10.7348 15.5 11 15.5C11.2652 15.5 11.5196 15.3946 11.7071 15.2071L16.2071 10.7071Z'
            fill='currentColor'
        />
    </svg>
);
