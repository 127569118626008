import React from 'react';
import type { IconProps } from '@models/types';

export const TimeIcon: React.FC<IconProps> = ({ className }) => (
    <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={className}
    >
        <circle cx='12' cy='12' r='9' stroke='currentColor' strokeWidth='2' />
        <path
            d='M12 8V12.8L15 16'
            stroke='currentColor'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);
