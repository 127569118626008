import React from 'react';
import type { ThemeProps } from '@models/types';
import useTheme from '@mui/styles/useTheme';

export const CsvFileIcon: React.FC = () => {
    const theme = useTheme<ThemeProps>();

    return (
        <svg
            width='46'
            height='58'
            viewBox='0 0 46 58'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M0 6C0 2.68629 2.68629 0 6 0H34L40 6L46 12V52C46 55.3137 43.3137 58 40 58H6C2.68629 58 0 55.3137 0 52V6Z'
                fill={theme.palette.primaryA4}
            />
            <path
                d='M46 12L34 0V6C34.4013 9.49263 34.9643 10.9124 38.6424 11.9071C38.8792 11.9711 39.124 12 39.3693 12H46Z'
                fill='currentColor'
            />
            <line
                x1='15'
                y1='19'
                x2='15'
                y2='39'
                stroke='currentColor'
                strokeWidth='2'
                strokeLinecap='round'
            />
            <line
                x1='23'
                y1='19'
                x2='23'
                y2='39'
                stroke='currentColor'
                strokeWidth='2'
                strokeLinecap='round'
            />
            <line
                x1='31'
                y1='19'
                x2='31'
                y2='39'
                stroke='currentColor'
                strokeWidth='2'
                strokeLinecap='round'
            />
            <line
                x1='11'
                y1='23'
                x2='35'
                y2='23'
                stroke='currentColor'
                strokeWidth='2'
                strokeLinecap='round'
            />
            <line
                x1='11'
                y1='29'
                x2='35'
                y2='29'
                stroke='currentColor'
                strokeWidth='2'
                strokeLinecap='round'
            />
            <line
                x1='11'
                y1='35'
                x2='35'
                y2='35'
                stroke='currentColor'
                strokeWidth='2'
                strokeLinecap='round'
            />
        </svg>
    );
};
