import type { FilterFieldArrayOptionNamesEnum, FilterFieldDateNamesEnum } from '@models/enums';
import { FilterOrganizationNames } from '@models/enums';
import type { AutocompleteOption, DateRangeValue, FilterMenuProps } from '@models/types';
import { createSlice } from '@reduxjs/toolkit';
import { getFiltersReducer } from '@utils/filters/util';

import type { EventTypeEnum } from '../../models';

export type OrganizationEventsFiltersState = {
    [FilterFieldDateNamesEnum.DATE_RANGE]: DateRangeValue;
    [FilterFieldArrayOptionNamesEnum.EVENT_TYPES]: Array<AutocompleteOption<EventTypeEnum>>;
};

export type OrganizationEventsFiltersSliceState = FilterMenuProps<OrganizationEventsFiltersState>;

export const organizationEventsFiltersInitState: OrganizationEventsFiltersState = {
    dateRange: null,
    eventTypes: [],
};

const initialState: OrganizationEventsFiltersSliceState = {
    isOpen: false,
    applyCount: 0,
    filters: organizationEventsFiltersInitState,
};

export const organizationEventsFiltersSlice = createSlice({
    name: FilterOrganizationNames.ORGANIZATION_EVENTS_FILTERS_SLICE,
    initialState,
    reducers: getFiltersReducer<
        OrganizationEventsFiltersState,
        OrganizationEventsFiltersSliceState
    >(organizationEventsFiltersInitState),
});
