export const maskString = (data: string) => {
    const skipFirstChars = 3;
    const firstThreeChar = data.slice(0, skipFirstChars);

    const lastSybmolsStart = data.length - 2;
    let maskedEmail = data.slice(skipFirstChars, lastSybmolsStart);

    maskedEmail = maskedEmail.replace(/./g, '*');
    const lastSymmols = data.slice(lastSybmolsStart, data.length);

    return firstThreeChar.concat(maskedEmail).concat(lastSymmols);
};

export const maskEmail = (email: string) => {
    if (!email.includes('@')) {
        return maskString(email);
    }
    const skipFirstChars = 3;
    const firstThreeChar = email.slice(0, skipFirstChars);

    const domainIndexStart = email.lastIndexOf('@');
    let maskedEmail = email.slice(skipFirstChars, domainIndexStart - 1);

    maskedEmail = maskedEmail.replace(/./g, '*');
    const domainPlusPreviousChar = email.slice(domainIndexStart - 1, email.length);

    return firstThreeChar.concat(maskedEmail).concat(domainPlusPreviousChar);
};
