import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { ToastProps } from '@models/types';
import type { RootState } from '@store/root-reducer';
import { toastSlice } from '@store/slices/toast-slice';
import { v4 as uuidv4 } from 'uuid';

export const useToast = () => {
    const toasts = useSelector((state: RootState) => state.toast.items);
    const dispatch = useDispatch();

    const showToast = useCallback(
        (data: Omit<ToastProps, 'id'>) => {
            const id = uuidv4();

            dispatch(
                toastSlice.actions.addToast({
                    id,
                    ...data,
                }),
            );
        },
        [dispatch],
    );

    const hideToast = useCallback(
        (id: string) => {
            dispatch(toastSlice.actions.hideToast(id));
        },
        [dispatch],
    );

    return { showToast, hideToast, toasts };
};
