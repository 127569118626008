import React from 'react';
import type { IconProps } from '@models/types';

export const SearchFolderIcon: React.FC<IconProps> = (props) => (
    <svg
        {...props}
        width='120'
        height='100'
        viewBox='0 0 120 100'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <g clipPath='url(#clip0_10653_4606)'>
            <path d='M95.2945 13.4238H47.8633V21.069H95.2945V13.4238Z' fill='black' />
            <path
                d='M13.8008 76.8135V12.148V9.59961H44.9971L52.9554 21.0674H98.1583V76.8135H13.8008Z'
                fill='white'
                stroke='currentColor'
                strokeWidth='0.853358'
            />
            <rect
                width='10.2008'
                height='27.3554'
                transform='matrix(0.744487 -0.667637 0.670633 0.741789 70.9395 71.2324)'
                fill='currentColor'
            />
            <rect
                width='7.6506'
                height='3.84685'
                transform='matrix(0.744487 -0.667637 0.670633 0.741789 69.3125 67.5332)'
                fill='white'
                stroke='currentColor'
                strokeWidth='0.8'
            />
            <rect
                width='5.94992'
                height='5.12855'
                transform='matrix(0.744487 -0.667637 0.670633 0.741789 66.5039 63.1562)'
                fill='white'
                stroke='currentColor'
                strokeWidth='0.8'
            />
            <path
                d='M54.9802 66.3383C45.9931 64.2593 40.3326 55.3005 42.3622 46.3679C44.3918 37.4353 53.3547 31.8594 62.3418 33.9384C71.3289 36.0174 76.9894 44.9762 74.9598 53.9088C72.9302 62.8414 63.9673 68.4173 54.9802 66.3383ZM62.1793 34.6537C53.589 32.6665 45.022 37.9961 43.0821 46.5343C41.1421 55.0725 46.5525 63.6355 55.1428 65.6227C63.7331 67.6099 72.3001 62.2803 74.24 53.7421C76.18 45.2039 70.7696 36.6409 62.1793 34.6537Z'
                fill='currentColor'
            />
            <path
                d='M62.1793 34.6537C53.589 32.6665 45.022 37.9961 43.0821 46.5343C41.1421 55.0725 46.5525 63.6355 55.1428 65.6227C63.7331 67.6099 72.3001 62.2803 74.24 53.7421C76.18 45.2039 70.7696 36.6409 62.1793 34.6537Z'
                fill='white'
            />
        </g>
        <defs>
            <clipPath id='clip0_10653_4606'>
                <rect width='120' height='100' fill='white' />
            </clipPath>
        </defs>
    </svg>
);
