import { createGlobalStyle } from 'styled-components';

export const NavigationMenuGlobalStyles = createGlobalStyle`
  .navigation-menu-tooltip {
    &.MuiTooltip-popper {
      .MuiTooltip-tooltip {
        position: relative;
        bottom: 8px;
        padding: 8px 12px;
        font-size: 12px;
        line-height: 16px;
        width: 326px;
        max-width: 326px;
        background-color: ${(props) => props.theme.palette.primaryA5};
        color: ${(props) => props.theme.palette.textLight} !important;
        
        @media (max-width: ${(props) => props.theme.breakPoints.md}) {
          bottom: 12px;
          width: calc(100vw - 64px);
          white-space: normal;
        }
      }

      .MuiTooltip-arrow {
        color: ${(props) => props.theme.palette.primaryA5};
      }
    }
  }
`;
