import React from 'react';
import type { IconProps } from '@models/types';

export const ArrowIcon: React.FC<IconProps> = ({ className }) => (
    <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={className}
    >
        <path d='M14 6L8 12L14 18' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' />
    </svg>
);
