import { createGlobalStyle } from 'styled-components';

export const MenuItemGlobalStyles = createGlobalStyle`
  .sub-menu-popper-container .MuiTooltip-tooltip {
    padding: 0;
    margin-left: 32px;
    margin-top: 5px !important;
    border-radius: 8px;
    background-color: transparent;
  }
`;
