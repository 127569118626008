import { createGlobalStyle } from 'styled-components';

export const AutocompleteGlobalStyles = createGlobalStyle`
  .autocomplete-popper {
    .MuiPaper-root {
      position: relative;
      background-color: ${(props) => props.theme.palette.primaryA6};
      box-shadow: none;
      border: 1px solid ${(props) => props.theme.palette.primaryA1};
      border-top: none;
      border-radius: 0 0 8px 8px;
      bottom: 6px;
    }

    .MuiAutocomplete-listbox {
      padding: 0 1px;
    }

    &[data-popper-placement="top"] .MuiPaper-root {
      border: 1px solid ${(props) => props.theme.palette.primaryA1};
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    .MuiAutocomplete-option {
      ${(props) => props.theme.typography.smallText}
      padding: 12px 12px;
      background-color: ${(props) => props.theme.palette.primaryA6};
      color: ${(props) => props.theme.palette.textMain};
      cursor: pointer;

      &:hover {
        color: ${(props) => props.theme.palette.primaryA1};
        background: ${(props) => props.theme.palette.primaryA4};
      }

      &[aria-selected="true"] {
        color: ${(props) => props.theme.palette.primaryA1};
        background: ${(props) => props.theme.palette.primaryA4};
      }
    }

    .autocomplete-filter {
      .MuiAutocomplete-listbox {
        display: flex;
        flex-wrap: wrap
      }

      .MuiAutocomplete-option {
        padding: 0 0 0 4px;
      }
    }

    .country-checkbox {
      .MuiCheckbox-root {
        padding: 0 8px 0 0;
      }

      .Mui-checked {
        .MuiSvgIcon-root {
          color: ${(props) => props.theme.palette.primaryA1};
        }
      }
    }
  }
`;
