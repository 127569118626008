import React from 'react';

export const MessageIcon: React.FC = () => (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M4.47231 6.11441C4 6.99805 4 8.16537 4 10.5V13.5C4 15.433 5.567 17 7.5 17C8.125 17 8.125 17.375 8.125 18.5C8.125 19.625 7.5 20 7.5 20C8.22596 20 8.92797 19.7403 9.47916 19.2679L12.125 17H13.5C15.8346 17 17.0019 17 17.8856 16.5277C18.5833 16.1548 19.1548 15.5833 19.5277 14.8856C20 14.0019 20 12.8346 20 10.5C20 8.16537 20 6.99805 19.5277 6.11441C19.1548 5.4167 18.5833 4.84525 17.8856 4.47231C17.0019 4 15.8346 4 13.5 4H10.5C8.16537 4 6.99805 4 6.11441 4.47231C5.4167 4.84525 4.84525 5.4167 4.47231 6.11441ZM13 7.5C13 8.05228 12.5523 8.5 12 8.5C11.4477 8.5 11 8.05228 11 7.5C11 6.94772 11.4477 6.5 12 6.5C12.5523 6.5 13 6.94772 13 7.5ZM13 10V14.5H11V10H13Z'
            fill='currentColor'
        />
    </svg>
);
