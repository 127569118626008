import { useCallback, useMemo, useState } from 'react';
import type { ThemeProps } from '@models/types';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/styles/useTheme';
import { isTouchDevice } from '@utils/util';

export const useResponsiveTooltip = () => {
    const theme = useTheme<ThemeProps>();
    const matchesMobileSm = useMediaQuery(`(max-width:${theme.breakPoints.sm})`);
    const isMdOrTouch = useMemo(() => matchesMobileSm || isTouchDevice(), [matchesMobileSm]);
    const [isOpen, setIsOpen] = useState(false);

    const toggleTooltip = useCallback(() => {
        if (isMdOrTouch) {
            setIsOpen(!isOpen);
        }
    }, [isOpen, isMdOrTouch]);

    return {
        matchesMobileSm,
        isMdOrTouch,
        isOpen,
        toggleTooltip,
    };
};
