import type { ApplicantModel } from '@models';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

interface funcCallback {
    (applicant: ApplicantModel): void;
}
export type ApplicantApplyModalState = {
    applicant?: ApplicantModel;
    updateCallback: funcCallback;
    isLoading: boolean;
    errorText: string;
};

const initialState: ApplicantApplyModalState = {
    applicant: undefined,
    updateCallback: () => {},
    isLoading: false,
    errorText: '',
};

export const applicantApplyModalSlice = createSlice({
    name: 'applicantApplyModal',
    initialState,
    reducers: {
        setOptionsApplicant: (
            state,
            {
                payload,
            }: PayloadAction<{
                applicant: ApplicantModel;
                updateCallback: funcCallback;
            }>,
        ) => {
            state.applicant = payload.applicant;
            state.updateCallback = payload.updateCallback;
        },
        resetOptionsApplicant: (state) => {
            state.applicant = undefined;
            state.updateCallback = () => {};
            state.isLoading = false;
            state.errorText = '';
        },
        setIsLoadingApplicant: (state, { payload }: PayloadAction<boolean>) => {
            state.isLoading = payload;
        },
        setTextErrorApplicant: (state, { payload }: PayloadAction<string>) => {
            state.errorText = payload;
        },
    },
});
