import { createGlobalStyle } from 'styled-components';

export const TooltipGlobalStyles = createGlobalStyle`
  .MuiTooltip-popper {
    &.tooltip-popper {
      .MuiTooltip-tooltip {
        background-color: ${(props) => props.theme.palette.primaryA6};
        color: ${(props) => props.theme.palette.textMain};
        ${(props) => props.theme.typography.smallTextBold};
        padding: 8px 12px;
        border-radius: 8px;

        ul {
          padding-left: 12px;
          margin-bottom: 0;
        }
      }

      .MuiTooltip-arrow {
        color: ${(props) => props.theme.palette.primaryA6};
      }
    }
  }
`;
