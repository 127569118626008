import type { ToastProps } from '@models/types';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export type ToastState = {
    items: ToastProps[];
};

const initialState: ToastState = {
    items: [],
};

export const toastSlice = createSlice({
    name: 'toast',
    initialState,
    reducers: {
        setToasts: (state, { payload }: PayloadAction<ToastProps[]>) => {
            state.items = payload;
        },
        addToast: (state, { payload }: PayloadAction<ToastProps>) => {
            state.items = [...state.items, payload];
        },
        hideToast: (state, { payload }: PayloadAction<string>) => {
            const index = state.items.findIndex((item) => item.id === payload);

            if (index >= 0) {
                const copy = [...state.items];

                copy.splice(index, 1);
                state.items = copy;
            }
        },
    },
});
