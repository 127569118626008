import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { lightTheme as adminPanelLightTheme } from '@modules/admin-panel/theme';
import { useLazyGetDomainSettingsQuery } from '@modules/shared/api/whitelabel-api';
import { domainSlice } from '@modules/shared/slices/domain-slice';
import type { RootState } from '@store/root-reducer';
import { themeSlice } from '@store/slices/theme-slice';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';

import { lightTheme as dashboardLightTheme } from '../../theme';

export const useLoadDomainSettings = ({ url }: { url: string }) => {
    const isInitialized = useSelector((state: RootState) => state.domain.isInitialized);
    const isLoadingDomainSettings = useSelector((state: RootState) => state.domain.isLoading);
    const dispatch = useDispatch();
    const [getDomainSettings] = useLazyGetDomainSettingsQuery();

    useEffect(() => {
        if (isInitialized || isLoadingDomainSettings || url == null) {
            return;
        }

        dispatch(domainSlice.actions.setLoading(true));
        getDomainSettings({ url, domain: process.env.REACT_APP_PUBLIC_SETTINGS_DOMAIN })
            .unwrap()
            .then((domainSettings) => {
                if (domainSettings) {
                    dispatch(domainSlice.actions.setSettings(domainSettings));
                    if (domainSettings.ui_settings) {
                        const merged = cloneDeep(
                            domainSettings.is_admin_panel
                                ? adminPanelLightTheme
                                : dashboardLightTheme,
                        );

                        merge(merged, cloneDeep(domainSettings.ui_settings));
                        dispatch(themeSlice.actions.setTheme(merged));
                    } else {
                        dispatch(
                            themeSlice.actions.setTheme(
                                domainSettings.is_admin_panel
                                    ? adminPanelLightTheme
                                    : dashboardLightTheme,
                            ),
                        );
                    }
                }
            })
            .catch((err) => {
                // eslint-disable-next-line no-console
                console.error(err);
            })
            .finally(() => {
                dispatch(domainSlice.actions.setLoading(false));
                dispatch(domainSlice.actions.setInitialized(true));
            });
    }, [dispatch, getDomainSettings, isInitialized, isLoadingDomainSettings, url]);

    return { isLoadingDomainSettings };
};
