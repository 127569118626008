export enum DocumentsStepEnum {
    UPLOAD_FIRST_SIDE,
    UPLOAD_SECOND_SIDE,
    SELECT_COUNTRY,
}

export type DocumentsProps = {
    onSubmit: () => void;
    onBack: () => void;
};
