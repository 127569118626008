import React from 'react';
import type { IconProps } from '@models/types';

export const DownloadIcon: React.FC<IconProps> = (props) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        {...props}
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M12 3C12.5523 3 13 3.44772 13 4L13 13.0859L14.7929 11.293C15.1834 10.9024 15.8166 10.9024 16.2071 11.293C16.5976 11.6835 16.5976 12.3167 16.2071 12.7072L12.7071 16.2072C12.3166 16.5977 11.6834 16.5977 11.2929 16.2072L7.79289 12.7072C7.40237 12.3167 7.40237 11.6835 7.79289 11.293C8.18342 10.9024 8.81658 10.9024 9.20711 11.293L11 13.0859L11 4C11 3.44772 11.4477 3 12 3ZM4 13.0001C4.55228 13.0001 5 13.4478 5 14.0001V17.0001C5 18.1046 5.89543 19.0001 7 19.0001H17C18.1046 19.0001 19 18.1046 19 17.0001V14.0001C19 13.4478 19.4477 13.0001 20 13.0001C20.5523 13.0001 21 13.4478 21 14.0001V17.0001C21 19.2092 19.2091 21.0001 17 21.0001H7C4.79086 21.0001 3 19.2092 3 17.0001L3 14.0001C3 13.4478 3.44772 13.0001 4 13.0001Z'
            fill='currentColor'
        />
    </svg>
);
