import type { OrganizationRoleEnum } from '../../enums';

export enum AccountStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    PENDING = 'PENDING',
}

export type OrganizationAccountInfoModel = {
    organization_id: string;
    organization_role: OrganizationRoleEnum;
    organization_name: string;
    organization_website: string;
    organization_permissions: unknown[];
};

export type OrganizationInfoModel = OrganizationAccountInfoModel & {
    organization_id: string;
    organization_role: OrganizationRoleEnum;
    organization_name: string;
    organization_website?: string;
    // indicates WL organization
    is_wl?: boolean;
    account_status?: AccountStatus;
    organization_logo?: string;
    balance: number;
    invite_token?: string;
    questionnaire_required?: boolean;
    aml_product?: ProductStateModel;
    docver_product: ProductStateModel;
    poa_gen_product?: ProductStateModel;
};

export type ProductStateModel = {
    enabled: boolean;
    api_calls_left: number;
    is_free?: boolean;
    unlimited?: Date;
    monitoring_calls_left?: number;
};
