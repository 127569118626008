import React from 'react';
import type { IconProps } from '@models/types';

export const FaqIcon: React.FC<IconProps> = () => (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M12.792 13.962H10.734V13.654C10.734 12.184 11.49 11.638 12.162 11.162C12.694 10.784 13.156 10.448 13.156 9.74801C13.156 8.96402 12.554 8.48801 11.868 8.48801C11.196 8.48801 10.51 8.93601 10.51 9.79001H8.59198C8.60598 7.69001 10.244 6.64001 11.882 6.64001C13.562 6.64001 15.144 7.76001 15.144 9.74801C15.144 11.0538 14.4558 11.6039 13.8345 12.1004C13.823 12.1096 13.8115 12.1188 13.8 12.128C13.268 12.534 12.792 12.898 12.792 13.654V13.962Z'
            fill='currentColor'
        />
        <path d='M12.834 17H10.664V14.816H12.834V17Z' fill='currentColor' />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12Z'
            fill='currentColor'
        />
    </svg>
);
