import React from 'react';
import type { IconProps } from '@models/types';

export const SearchDocIcon: React.FC<IconProps> = (props) => (
    <svg
        {...props}
        width='120'
        height='100'
        viewBox='0 0 120 100'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <g clipPath='url(#clip0_10731_2965)'>
            <g clipPath='url(#clip1_10731_2965)'>
                <rect
                    width='59.6323'
                    height='78.4358'
                    transform='translate(27.5996 6.75)'
                    fill='white'
                />
                <path d='M27.8741 7.02539H86.9545V84.91H27.8741V7.02539Z' fill='white' />
                <path
                    d='M86.9559 85.1858H27.8756C27.7232 85.1858 27.5996 85.0625 27.5996 84.9102V7.02559C27.5996 6.87346 27.7232 6.75 27.8756 6.75H86.9559C87.1083 6.75 87.2319 6.87346 87.2319 7.02559V84.9102C87.2319 85.0623 87.1083 85.1858 86.9559 85.1858ZM28.1515 84.6346H86.68V7.30118H28.1515V84.6346Z'
                    fill='currentColor'
                />
                <path
                    d='M75.0401 46.6963H39.7955C39.6432 46.6963 39.5196 46.5727 39.5196 46.4204C39.5196 46.2681 39.6432 46.1445 39.7955 46.1445H75.0401C75.1924 46.1445 75.3161 46.2681 75.3161 46.4204C75.3161 46.5727 75.1924 46.6963 75.0401 46.6963Z'
                    fill='currentColor'
                />
                <path
                    d='M75.0401 55.4043H39.7955C39.6432 55.4043 39.5196 55.2807 39.5196 55.1284C39.5196 54.9761 39.6432 54.8525 39.7955 54.8525H75.0401C75.1924 54.8525 75.3161 54.9761 75.3161 55.1284C75.3161 55.2807 75.1924 55.4043 75.0401 55.4043Z'
                    fill='currentColor'
                />
                <path
                    d='M75.04 20.5586H39.7954C39.6431 20.5586 39.5195 20.435 39.5195 20.2827C39.5195 20.1304 39.6431 20.0068 39.7954 20.0068H75.04C75.1923 20.0068 75.3159 20.1304 75.3159 20.2827C75.3159 20.435 75.1923 20.5586 75.04 20.5586Z'
                    fill='currentColor'
                />
                <path
                    d='M75.0401 64.1123H39.7955C39.6432 64.1123 39.5196 63.9887 39.5196 63.8364C39.5196 63.6841 39.6432 63.5605 39.7955 63.5605H75.0401C75.1924 63.5605 75.3161 63.6841 75.3161 63.8364C75.3161 63.9887 75.1924 64.1123 75.0401 64.1123Z'
                    fill='currentColor'
                />
                <path
                    d='M75.0401 29.2666H39.7955C39.6432 29.2666 39.5196 29.143 39.5196 28.9907C39.5196 28.8384 39.6432 28.7148 39.7955 28.7148H75.0401C75.1924 28.7148 75.3161 28.8384 75.3161 28.9907C75.3161 29.143 75.1924 29.2666 75.0401 29.2666Z'
                    fill='currentColor'
                />
                <path
                    d='M75.0401 72.8208H39.7955C39.6432 72.8208 39.5196 72.6972 39.5196 72.5449C39.5196 72.3926 39.6432 72.269 39.7955 72.269H75.0401C75.1924 72.269 75.3161 72.3926 75.3161 72.5449C75.3161 72.6972 75.1924 72.8208 75.0401 72.8208Z'
                    fill='currentColor'
                />
                <path
                    d='M75.0401 37.9751H39.7955C39.6432 37.9751 39.5196 37.8515 39.5196 37.6992C39.5196 37.5469 39.6432 37.4233 39.7955 37.4233H75.0401C75.1924 37.4233 75.3161 37.5469 75.3161 37.6992C75.3161 37.8515 75.1924 37.9751 75.0401 37.9751Z'
                    fill='currentColor'
                />
            </g>
            <rect
                x='72.2383'
                y='77.2051'
                width='8.82631'
                height='23.5368'
                transform='rotate(-54.9141 72.2383 77.2051)'
                fill='currentColor'
            />
            <rect
                x='70.166'
                y='74.4033'
                width='6.61974'
                height='3.30987'
                transform='rotate(-54.9141 70.166 74.4033)'
                fill='white'
                stroke='currentColor'
                strokeWidth='1.06481'
            />
            <rect
                x='66.9756'
                y='71.2617'
                width='5.14868'
                height='4.41316'
                transform='rotate(-54.9141 66.9756 71.2617)'
                fill='white'
                stroke='currentColor'
                strokeWidth='1.06481'
            />
            <path
                d='M57.913 75.9333C49.9765 75.9333 43.5195 69.5045 43.5195 61.6026C43.5195 53.7008 49.9765 47.272 57.913 47.272C65.8495 47.272 72.3064 53.7008 72.3064 61.6026C72.3064 69.5045 65.8495 75.9333 57.913 75.9333ZM57.913 47.9047C50.3269 47.9047 44.1552 54.0495 44.1552 61.6025C44.1552 69.1555 50.3269 75.3002 57.913 75.3002C65.499 75.3002 71.6707 69.1555 71.6707 61.6025C71.6707 54.0495 65.499 47.9047 57.913 47.9047Z'
                fill='currentColor'
            />
            <path
                d='M57.913 47.9047C50.3269 47.9047 44.1552 54.0495 44.1552 61.6025C44.1552 69.1555 50.3269 75.3002 57.913 75.3002C65.499 75.3002 71.6707 69.1555 71.6707 61.6025C71.6707 54.0495 65.499 47.9047 57.913 47.9047Z'
                fill='white'
            />
        </g>
        <defs>
            <clipPath id='clip0_10731_2965'>
                <rect width='120' height='100' fill='white' />
            </clipPath>
            <clipPath id='clip1_10731_2965'>
                <rect
                    width='59.6323'
                    height='78.4358'
                    fill='white'
                    transform='translate(27.5996 6.75)'
                />
            </clipPath>
        </defs>
    </svg>
);
