import React from 'react';
import type { IconProps } from '@models/types';

export const ApplicantFolderIcon: React.FC<IconProps> = (props) => (
    <svg
        width='120'
        height='100'
        viewBox='0 0 120 100'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
    >
        <path d='M99.1482 19.3887H51.918V26.964H99.1482V19.3887Z' fill='black' />
        <path
            d='M18 82.1996V18.1247V15.5996H49.0642L56.9887 26.9626H102V82.1996H18Z'
            fill='white'
            stroke='currentColor'
            strokeWidth='1.04478'
        />
        <path
            d='M59.55 64.9494C53.5949 64.9494 48.75 60.1045 48.75 54.1495C48.75 48.1945 53.5949 43.3496 59.55 43.3496C65.5051 43.3496 70.35 48.1945 70.35 54.1495C70.35 60.1045 65.5051 64.9494 59.55 64.9494ZM59.55 43.8265C53.8578 43.8265 49.227 48.4573 49.227 54.1494C49.227 59.8415 53.8578 64.4723 59.55 64.4723C65.2421 64.4723 69.873 59.8415 69.873 54.1494C69.873 48.4573 65.2421 43.8265 59.55 43.8265Z'
            fill='black'
        />
        <path
            d='M59.5451 56.4749C61.6798 56.4749 63.4104 54.7439 63.4104 52.6085C63.4104 50.4732 61.6798 48.7422 59.5451 48.7422C57.4103 48.7422 55.6797 50.4732 55.6797 52.6085C55.6797 54.7439 57.4103 56.4749 59.5451 56.4749Z'
            fill='black'
        />
        <path
            d='M59.5492 58.1758C56.0289 58.1758 52.9812 59.3136 51.4902 60.973C53.4276 63.2581 56.3187 64.7089 59.5492 64.7089C62.7796 64.7089 65.6708 63.2581 67.6081 60.973C66.1172 59.3136 63.0694 58.1758 59.5492 58.1758Z'
            fill='black'
        />
        <path
            d='M59.5512 64.9496C56.3723 64.9496 53.3686 63.5571 51.3105 61.1293C51.2334 61.0384 51.2352 60.9044 51.315 60.8157C52.9089 59.0416 56.065 57.9395 59.5512 57.9395C63.0375 57.9395 66.1935 59.0416 67.7875 60.8157C67.8672 60.9044 67.8692 61.0384 67.792 61.1293C65.7337 63.5571 62.7302 64.9496 59.5512 64.9496ZM51.8131 60.9815C53.7754 63.2029 56.5836 64.4726 59.5512 64.4726C62.5189 64.4726 65.3271 63.2029 67.2894 60.9815C65.7402 59.3948 62.803 58.4164 59.5512 58.4164C56.2994 58.4164 53.3624 59.3948 51.8131 60.9815Z'
            fill='black'
        />
    </svg>
);
