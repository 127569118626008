import React from 'react';
import type { IconProps } from '@models/types';

export const SearchRectangleIcon: React.FC<IconProps> = (props) => (
    <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
    >
        <path
            d='M1 4C1 2.34315 2.34315 1 4 1H8C8.55228 1 9 1.44772 9 2C9 2.55228 8.55228 3 8 3H4C3.44772 3 3 3.44772 3 4V8C3 8.55228 2.55228 9 2 9C1.44772 9 1 8.55228 1 8V4Z'
            fill='currentColor'
        />
        <path
            d='M15 2C15 1.44772 15.4477 1 16 1H20C21.6569 1 23 2.34315 23 4V8C23 8.55228 22.5523 9 22 9C21.4477 9 21 8.55228 21 8V4C21 3.44772 20.5523 3 20 3H16C15.4477 3 15 2.55228 15 2Z'
            fill='currentColor'
        />
        <path
            d='M2 15C2.55228 15 3 15.4477 3 16V20C3 20.5523 3.44772 21 4 21H8C8.55228 21 9 21.4477 9 22C9 22.5523 8.55228 23 8 23H4C2.34315 23 1 21.6569 1 20V16C1 15.4477 1.44772 15 2 15Z'
            fill='currentColor'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M16.4447 16.0305C17.4111 14.9654 18 13.5515 18 12C18 8.68629 15.3137 6 12 6C8.68629 6 6 8.68629 6 12C6 15.3137 8.68629 18 12 18C13.036 18 14.0106 17.7374 14.861 17.2752L18.2929 20.7071C18.6834 21.0976 19.3166 21.0976 19.7071 20.7071C20.0976 20.3166 20.0976 19.6834 19.7071 19.2929L16.4447 16.0305ZM12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16C12.8921 16 13.7159 15.708 14.3812 15.2143C14.3938 15.2044 14.4066 15.1947 14.4196 15.1855C15.38 14.4548 16 13.2998 16 12C16 9.79086 14.2091 8 12 8Z'
            fill='currentColor'
        />
    </svg>
);
