import { apiLogger } from '@hooks/use-logger';
import { ApiTagEnum } from '@models/enums';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { FetchBaseQueryArgs } from '@reduxjs/toolkit/src/query/fetchBaseQuery';
import type { RootState } from '@store/root-reducer';
import { logOut } from '@store/slices/shared/logout-action';
import { baseQueryRequest } from '@utils/base-query-request';

const prepareHeaders: FetchBaseQueryArgs['prepareHeaders'] = (headers, api) => {
    const state = api.getState() as RootState;

    if (state.dashboard.session.isSandbox) {
        headers.set('ds-sandbox', 'true');
    }
};
const baseQuery = fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_BASE_URL}`,
    credentials: 'include',
    prepareHeaders,
});

const baseQueryWithInterceptor: ReturnType<typeof fetchBaseQuery> = async (
    args,
    api,
    extraOptions,
) => {
    const state = api.getState() as RootState;

    const result = await baseQueryRequest({
        state,
        baseQuery,
        args,
        api,
        extraOptions,
        prepareHeaders,
    });

    if (result.error) {
        await apiLogger(state, result);
    }

    if (result.error && result.error.status === 401) {
        await baseQueryRequest({
            state,
            baseQuery,
            args: { url: 'webapi/account/logout', method: 'POST' },
            api,
            extraOptions,
        });
        api.dispatch(logOut());
    }

    return result;
};

export const dashboardApi = createApi({
    reducerPath: 'dashboardApi',
    baseQuery: baseQueryWithInterceptor,
    tagTypes: [
        ApiTagEnum.ORGANIZATIONS,
        ApiTagEnum.INVITATIONS,
        ApiTagEnum.DEVELOPER_SETTINGS,
        ApiTagEnum.ORGANIZATION_INFO,
        ApiTagEnum.VERIFICATION,
        ApiTagEnum.APPLICANTS,
        ApiTagEnum.APPLICANT_ALERTS,
        ApiTagEnum.FILE_UPLOADS,
        ApiTagEnum.PEPS,
        ApiTagEnum.CONTACT_US,
        ApiTagEnum.LIST_TEAM,
        ApiTagEnum.CURRENT_ORGANIZATION,
        ApiTagEnum.CURRENT_BILLING_ORGANIZATION,
        ApiTagEnum.PEP,
        ApiTagEnum.UPDATE_PROFILE,
        ApiTagEnum.APPLICANT,
    ],
    endpoints: () => ({}),
});
