import React from 'react';
import type { IconProps } from '@models/types';

export const PasswordIconClose: React.FC<IconProps> = ({ className, onClick }) => (
    <svg
        className={className}
        onClick={onClick}
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M6.70711 5.29289C6.31658 4.90237 5.68342 4.90237 5.29289 5.29289C4.90237 5.68342 4.90237 6.31658 5.29289 6.70711L16.2929 17.7071C16.6834 18.0976 17.3166 18.0976 17.7071 17.7071C18.0976 17.3166 18.0976 16.6834 17.7071 16.2929L6.70711 5.29289Z'
            fill='currentColor'
        />
        <path
            d='M15.24 11.5C15.24 11.7798 15.2053 12.0518 15.1402 12.3118L17.8482 15.0198C19.9633 13.4434 21 11.5 21 11.5C21 11.5 18.66 6 12 6C10.9601 6 10.0256 6.13408 9.1888 6.36038L11.1456 8.31718C11.4181 8.24081 11.7046 8.2 12 8.2C13.782 8.2 15.24 9.685 15.24 11.5Z'
            fill='currentColor'
        />
        <path
            d='M3 11.5C3 11.5 3.71623 9.81656 5.48397 8.31239L8.76119 11.5896C8.80797 13.354 10.2318 14.7844 11.9714 14.7999L13.9699 16.7983C13.3545 16.9272 12.698 17 12 17C5.934 17 3 11.5 3 11.5Z'
            fill='currentColor'
        />
    </svg>
);
