import React from 'react';
import type { IconProps } from '@models/types';

export const PasswordIconOpen: React.FC<IconProps> = ({ className, onClick }) => (
    <svg
        className={className}
        onClick={onClick}
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M12 6C5.34 6 3 11.5 3 11.5C3 11.5 5.934 17 12 17C18.066 17 21 11.5 21 11.5C21 11.5 18.66 6 12 6ZM12 14.8C10.218 14.8 8.76 13.315 8.76 11.5C8.76 9.685 10.218 8.2 12 8.2C13.782 8.2 15.24 9.685 15.24 11.5C15.24 13.315 13.782 14.8 12 14.8Z'
            fill='currentColor'
        />
    </svg>
);
