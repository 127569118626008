import React from 'react';
import type { IconProps } from '@models/types';

export const DocIcon: React.FC<IconProps> = ({ className }) => (
    <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={className}
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M7 2C5.34315 2 4 3.34315 4 5V19C4 20.6569 5.34315 22 7 22H17C18.6569 22 20 20.6569 20 19V9.35571C20 8.51222 19.6449 7.70771 19.0217 7.13928L14.2466 2.78357C13.6939 2.27946 12.9729 2 12.2249 2H7ZM6 5C6 4.44772 6.44772 4 7 4H12V7C12 8.65685 13.3431 10 15 10H18V19C18 19.5523 17.5523 20 17 20H7C6.44772 20 6 19.5523 6 19V5ZM16.9976 8L14 5.26569V7C14 7.55228 14.4477 8 15 8H16.9976Z'
            fill='currentColor'
        />
    </svg>
);
