// use it by defeult
export enum DateFormatEnum {
    YEAR = 'YYYY',
    YEAR_MONTH = 'YYYY-MM',
    YEAR_MONTH_DAY = 'YYYY-MM-DD',
    DAY_MONTH_YEAR_DOT = 'DD.MM.YYYY',
    MONTH_DATE_YEAR = 'MMM DD, YYYY',
    MONTH_DATE_YEAR_TIME = 'MMM DD, YYYY HH:mm:ss',
    DAY_MONTH = 'DD MMM',
}
