import type { FilterFieldDateNamesEnum } from '@models/enums';
import { FilterOrganizationNames } from '@models/enums';
import type { DateRangeValue, FilterMenuProps } from '@models/types';
import { createSlice } from '@reduxjs/toolkit';
import { getFiltersReducer } from '@utils/filters/util';

export type OrganizationFiltersSliceState = FilterMenuProps<OrganizationFiltersState>;

export type OrganizationFiltersState = {
    [FilterFieldDateNamesEnum.CREATED_AT_DATE_RANGE]: DateRangeValue;
};

export const organizationFiltersInitState: OrganizationFiltersState = {
    createdAtDateRange: null,
};

const initialState: OrganizationFiltersSliceState = {
    isOpen: false,
    applyCount: 0,
    filters: organizationFiltersInitState,
};

export const organizationFiltersSlice = createSlice({
    name: FilterOrganizationNames.ORGANIZATION_FILTERS_SLICE,
    initialState,
    reducers: getFiltersReducer<OrganizationFiltersState, OrganizationFiltersSliceState>(
        organizationFiltersInitState,
    ),
});
