import { createGlobalStyle } from 'styled-components';

export const TagsAutocompleteGlobalStyles = createGlobalStyle`
  .tags-popper {
    .MuiAutocomplete-listbox {
      padding: 8px 8px 8px 8px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 8px;
    }

    .MuiAutocomplete-option {
      background-color: transparent;
      padding: 0;
      display: inline-block;
      min-height: auto;

      > div {
        margin: 0;
      }
    }

    [aria-selected='true'] {
      display: none;
    }
  }
`;
