import styled from 'styled-components';

export const ErrorBoundaryContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    max-width: 486px;
    text-align: center;
    margin: auto;
    height: calc(100vh - 300px);

    &.absolute {
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .text {
        margin-top: 0;
    }
    .button {
        margin-top: 16px;
    }
    a {
        color: ${(props) => props.theme.palette.primaryA1};
    }

    ${(props) => props.theme.breakpointTo.md} {
        width: 100%;
        padding: 16px;
    }
`;
