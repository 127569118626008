import React from 'react';
import type { IconProps } from '@models/types';

export const UserIcon: React.FC<IconProps> = ({ ...props }) => (
    <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M3.46447 3.46447C2 4.92893 2 7.28595 2 12C2 16.714 2 19.0711 3.46447 20.5355C4.92893 22 7.28595 22 12 22C16.714 22 19.0711 22 20.5355 20.5355C22 19.0711 22 16.714 22 12C22 7.28595 22 4.92893 20.5355 3.46447C19.0711 2 16.714 2 12 2C7.28595 2 4.92893 2 3.46447 3.46447ZM14.4914 8.49711C14.4914 9.87623 13.3734 10.9942 11.9943 10.9942C10.6152 10.9942 9.49716 9.87623 9.49716 8.49711C9.49716 7.11799 10.6152 6 11.9943 6C13.3734 6 14.4914 7.11799 14.4914 8.49711ZM16.9884 15.8785C16.9884 13.8808 16 12.3814 11.9942 12.3814C7.73249 12.3814 7 14 7 15.8785V17.1801C7 17.6568 7.33614 18.0658 7.80697 18.1401C8.74469 18.288 10.3723 18.5 12 18.5C13.4149 18.5 14.8261 18.3398 15.7846 18.2008C16.4095 18.1101 16.9884 17.5188 16.9884 16.8873V15.8785Z'
            fill='currentColor'
        />
    </svg>
);
