export type VerificationProfileManuelFieldsSettingsModel = {
    enabled: boolean;
    description?: string;
    full_name?: ManualFieldModel;
    email?: ManualFieldModel;
    phone?: ManualFieldModel;
    country?: ManualFieldModel;
    dob?: ManualFieldModel;
    gender?: ManualFieldModel;
    citizenship?: ManualFieldModel;
    address?: ManualFieldModel;
    custom_fields?: CustomManualFieldModel[];
};

export type ManualFieldModel = {
    enabled: boolean;
    caption?: string;
    order?: number;
};

export enum CustomManualFieldType {
    SELECT = 'select',
    FILE = 'file',
}

export enum CustomManualFieldFileType {
    DOCUMENT = 'document',
    VIDEO = 'video',
    IMAGE = 'image',
    ANY = 'custom',
}

export type CustomFieldFileOptionsModel = {
    type: CustomManualFieldType.FILE;
    allowed_mime_types: string[];
    attachment_type_preset: Exclude<CustomManualFieldFileType, CustomManualFieldFileType.ANY>;
    max_size: number;
};

export type CustomFieldSelectOptionsModel = {
    type: CustomManualFieldType.SELECT;
    choices: string[];
};

export type CustomManualFieldModel = {
    label: string;
    caption?: string;
    order?: number;
} & Partial<{
    options: CustomFieldFileOptionsModel | CustomFieldSelectOptionsModel;
}>;
