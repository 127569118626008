import type { PaymentStatusEnum } from '@models/enums';
import {
    FilterBillingNames,
    FilterFieldArrayOptionNamesEnum,
    FilterFieldDateNamesEnum,
} from '@models/enums';
import type { PaymentMethodEnum } from '@models/enums/payment-method-enum';
import type { AutocompleteOption, DateRangeValue, FilterMenuProps } from '@models/types';
import { createSlice } from '@reduxjs/toolkit';
import { getFiltersReducer } from '@utils/filters/util';

export type BillingPaymentsFiltersState = {
    [FilterFieldDateNamesEnum.DATE_RANGE]?: DateRangeValue;
    [FilterFieldArrayOptionNamesEnum.PAYMENT_STATUSES]: Array<
        AutocompleteOption<PaymentStatusEnum>
    >;
    [FilterFieldArrayOptionNamesEnum.PAYMENT_METHODS]: Array<AutocompleteOption<PaymentMethodEnum>>;
};

export type BillingPaymentsFiltersSliceState = FilterMenuProps<BillingPaymentsFiltersState>;

const billingPaymentsFiltersInitState = {
    [FilterFieldDateNamesEnum.DATE_RANGE]: null,
    [FilterFieldArrayOptionNamesEnum.PAYMENT_STATUSES]: [],
    [FilterFieldArrayOptionNamesEnum.PAYMENT_METHODS]: [],
};

const initialState: BillingPaymentsFiltersSliceState = {
    isOpen: false,
    applyCount: 0,
    filters: billingPaymentsFiltersInitState,
    searchQuery: '',
};

export const billingPaymentsFiltersSlice = createSlice({
    name: FilterBillingNames.BILLING_PAYMENTS_FILTERS_SLICE,
    initialState,
    reducers: getFiltersReducer<BillingPaymentsFiltersState, BillingPaymentsFiltersSliceState>(
        billingPaymentsFiltersInitState,
    ),
});
