import React from 'react';
import type { IconProps } from '@models/types';

export const SettingsIcon: React.FC<IconProps> = ({ ...props }) => (
    <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M11 4H13L13.4921 6.18699C14.0481 6.3293 14.5731 6.54929 15.0547 6.83472L16.9493 5.63607L18.3636 7.05029L17.165 8.9448C17.4506 9.42654 17.6707 9.95167 17.813 10.5079L20 11V13L17.813 13.4921C17.6707 14.0481 17.4507 14.5731 17.1653 15.0547L18.3645 16.9503L16.9503 18.3645L15.0547 17.1653C14.5731 17.4507 14.0481 17.6707 13.4921 17.813L13 20H11L10.5079 17.813C9.95172 17.6707 9.42664 17.4506 8.94494 17.1651L7.04985 18.364L5.63563 16.9498L6.83465 15.0546C6.54925 14.573 6.32929 14.0481 6.18699 13.4921L4 13V11L6.18699 10.5079C6.32933 9.95173 6.54939 9.42666 6.83492 8.94497L5.63655 7.05076L7.05076 5.63655L8.94496 6.83492C9.42666 6.54939 9.95173 6.32933 10.5079 6.18699L11 4ZM12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z'
            fill='currentColor'
        />
    </svg>
);
