import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { WritableDraft } from 'immer';

export type AppState = {
    isMenuOpen: boolean;
    isSettingsMenuOpen: boolean;
    selectedMenu: string;
    openedSubMenus: string[];
};

const initialState: AppState = {
    isMenuOpen: localStorage.getItem('isMenuOpen') === 'true',
    isSettingsMenuOpen: false,
    selectedMenu: '',
    openedSubMenus: [],
};
const setMenuOpen = (state: WritableDraft<AppState>, value: boolean) => {
    const newValue = value;

    localStorage.setItem('isMenuOpen', newValue.toString());
    state.isMenuOpen = newValue;
    if (!newValue) {
        state.openedSubMenus = [];
    }
};

export const appSlice = createSlice({
    name: 'appSlice',
    initialState,
    reducers: {
        toggleMenu: (state) => {
            setMenuOpen(state, !state.isMenuOpen);
        },
        setMenu: (state, { payload }: PayloadAction<boolean>) => {
            setMenuOpen(state, payload);
        },

        toggleSettingsMenu: (state) => {
            state.isSettingsMenuOpen = !state.isSettingsMenuOpen;
        },
        setSelectedMenu: (state, { payload }: PayloadAction<string>) => {
            state.selectedMenu = payload;
        },
        hideSubMenu: (state, { payload }: PayloadAction<string>) => {
            const index = state.openedSubMenus.findIndex((item) => item === payload);

            if (index >= 0) {
                const newValue = [...state.openedSubMenus];

                newValue.splice(index, 1);
                state.openedSubMenus = newValue;
            }
        },
        toggleSubMenu: (state, { payload }: PayloadAction<string>) => {
            const index = state.openedSubMenus.findIndex((item) => item === payload);

            if (index >= 0) {
                const newValue = [...state.openedSubMenus];

                newValue.splice(index, 1);
                state.openedSubMenus = newValue;
            } else {
                // only one sub menu can be opened at the same time
                state.openedSubMenus = [payload];
            }
        },
    },
});
