import { FilterApplicantOrganizationNames, FilterFieldOptionNamesEnum } from '@models/enums';
import type { AutocompleteOption, FilterMenuProps } from '@models/types';
import { createSlice } from '@reduxjs/toolkit';
import { getFiltersReducer } from '@utils/filters/util';

export type ApplicantOrganizationFiltersState = {
    [FilterFieldOptionNamesEnum.COUNTRY]?: AutocompleteOption<string>;
};

export type ApplicantOrganizationFiltersSliceState =
    FilterMenuProps<ApplicantOrganizationFiltersState>;

export const applicantOrganizationFiltersInitState: ApplicantOrganizationFiltersState = {
    [FilterFieldOptionNamesEnum.COUNTRY]: undefined,
};

const initialState: ApplicantOrganizationFiltersSliceState = {
    isOpen: false,
    applyCount: 0,
    filters: applicantOrganizationFiltersInitState,
    searchQuery: '',
};

export const applicantOrganizationFiltersSlice = createSlice({
    name: FilterApplicantOrganizationNames.APPLICANT_ORGANIZATION_FILTERS_SLICE,
    initialState,
    reducers: getFiltersReducer<
        ApplicantOrganizationFiltersState,
        ApplicantOrganizationFiltersSliceState
    >(applicantOrganizationFiltersInitState),
});
