import React from 'react';

export const WalletIcon: React.FC = () => (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M4 11.204C4 8.72295 4 7.48241 4.73364 6.63652C5.46728 5.79063 6.69534 5.61519 9.15147 5.26431L11.1515 4.9786C14.338 4.52339 15.9312 4.29578 16.9656 5.19291C17.489 5.64686 17.7476 6.2832 17.8753 7.21816C16.9968 7 15.7777 7 14 7H10C7.17157 7 5.75736 7 4.87868 7.87868C4.05336 8.70399 4.00324 10.0018 4.0002 12.5001C4 12.3386 4 12.172 4 12.0002V11.204ZM4 14C4 11.1716 4 9.75736 4.87868 8.87868C5.75736 8 7.17157 8 10 8H14C16.8284 8 18.2426 8 19.1213 8.87868C20 9.75736 20 11.1716 20 14V15C20 17.8284 20 19.2426 19.1213 20.1213C18.2426 21 16.8284 21 14 21H10C7.17157 21 5.75736 21 4.87868 20.1213C4 19.2426 4 17.8284 4 15V14ZM8.25 11.5C8.25 11.0858 7.91421 10.75 7.5 10.75C7.08579 10.75 6.75 11.0858 6.75 11.5V17.5C6.75 17.9142 7.08579 18.25 7.5 18.25C7.91421 18.25 8.25 17.9142 8.25 17.5V11.5ZM16.5 16C17.3284 16 18 15.3284 18 14.5C18 13.6716 17.3284 13 16.5 13C15.6716 13 15 13.6716 15 14.5C15 15.3284 15.6716 16 16.5 16Z'
            fill='currentColor'
        />
    </svg>
);
