import type { CSSProperties } from 'react';
import React from 'react';
import { Badge } from '@components/atoms/badge';
import { BadgeStatusEnum } from '@models/enums';

export type BetaIconProps = {
    badgeStyle?: CSSProperties;
};

export const BetaIcon: React.FC<BetaIconProps> = ({ badgeStyle = {} }) => (
    <Badge
        text='BETA'
        status={BadgeStatusEnum.PRIMARY}
        size='s'
        style={{
            ...badgeStyle,
        }}
    />
);
