import React from 'react';
import type { IconProps } from '@models/types';

export const CloseIconFilter: React.FC<IconProps> = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="16" height="16" rx="8" fill="#AFAFF0" />
        <path
            d="M11.1374 10.1953C11.3978 10.4556 11.3978 10.8777 11.1374 11.1381C10.8771 11.3984 10.455 11.3984 10.1946 11.1381L7.99935 8.9428L5.80409 11.1381C5.54374 11.3984 5.12163 11.3984 4.86128 11.1381C4.60093 10.8777 4.60093 10.4556 4.86128 10.1953L7.05654 7.99999L4.86128 5.80473C4.60093 5.54438 4.60093 5.12227 4.86128 4.86192C5.12163 4.60157 5.54374 4.60157 5.80409 4.86192L7.99935 7.05718L10.1946 4.86192C10.455 4.60157 10.8771 4.60157 11.1374 4.86192C11.3978 5.12227 11.3978 5.54438 11.1374 5.80473L8.94216 7.99999L11.1374 10.1953Z"
            fill="white"
        />
    </svg>
);
