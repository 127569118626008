export enum ManualFieldNamesEnum {
    FULL_NAME = 'full_name',
    COUNTRY = 'country',
    PHONE = 'phone',
    DOB = 'dob',
    GENDER = 'gender',
    CITIZENSHIP = 'citizenship',
    ADDRESS = 'address',
    EMAIL = 'email',
}
