import React from 'react';
import type { IconProps } from '@models/types';

export const VerificationProfilesIcon: React.FC<IconProps> = ({ ...props }) => (
    <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
    >
        <g clipPath='url(#clip0_207_7828)'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M2.18817 5.33341C2.54351 5.46053 2.83736 5.63613 3.08889 5.87868C4.00012 6.75736 4.00012 8.17157 4.00012 11V14C4.00012 16.8284 4.00012 18.2426 3.08889 19.1213C2.89196 19.3112 2.6691 19.4601 2.41109 19.5768C2.58284 20.076 2.82634 20.4832 3.17157 20.8284C4.34315 22 6.22876 22 10 22H14C17.7712 22 19.6569 22 20.8284 20.8284C21.1736 20.4832 21.4171 20.076 21.5889 19.5768C21.3308 19.4601 21.1079 19.3113 20.9109 19.1213C19.9997 18.2426 19.9997 16.8284 19.9997 14V11C19.9997 8.17157 19.9997 6.75736 20.9109 5.87868C21.1625 5.63609 21.4564 5.46048 21.8118 5.33335C21.6538 4.38975 21.3631 3.70629 20.8284 3.17157C19.6569 2 17.7712 2 14 2H10C6.22876 2 4.34315 2 3.17157 3.17157C2.63684 3.7063 2.34617 4.38978 2.18817 5.33341ZM21 13.8V11.2C21 8.7487 21 7.52304 21.716 6.76152C22.3333 6.10493 23.275 6.01446 25 6.00199V18.998C23.275 18.9855 22.3333 18.8951 21.716 18.2385C21 17.477 21 16.2513 21 13.8ZM3 13.798V11.198C3 8.74674 3 7.52109 2.28404 6.75957C1.66673 6.10297 0.72502 6.0125 -1 6.00004V18.9961C0.725021 18.9836 1.66673 18.8931 2.28404 18.2365C3 17.475 3 16.2494 3 13.798ZM16.2071 10.7071C16.5976 10.3166 16.5976 9.68342 16.2071 9.29289C15.8166 8.90237 15.1834 8.90237 14.7929 9.29289L11 13.0858L8.70711 10.7929C8.31658 10.4024 7.68342 10.4024 7.29289 10.7929C6.90237 11.1834 6.90237 11.8166 7.29289 12.2071L11 15.9142L16.2071 10.7071Z'
                fill='currentColor'
            />
        </g>
        <defs>
            <clipPath id='clip0_207_7828'>
                <rect width='24' height='24' fill='white' />
            </clipPath>
        </defs>
    </svg>
);
