import React from 'react';
import type { IconProps } from '@models/types';

export const ExitIcon: React.FC<IconProps> = () => (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M12.2073 8.70711C12.5978 8.31658 12.5978 7.68342 12.2073 7.29289C11.8167 6.90237 11.1836 6.90237 10.793 7.29289L6.79304 11.2929L6.08594 12L6.79304 12.7071L10.793 16.7071C11.1836 17.0976 11.8167 17.0976 12.2073 16.7071C12.5978 16.3166 12.5978 15.6834 12.2073 15.2929L9.91436 13H21.0001C21.5524 13 22.0001 12.5523 22.0001 12C22.0001 11.4477 21.5524 11 21.0001 11H9.91436L12.2073 8.70711Z'
            fill='currentColor'
        />
        <path
            d='M20.0007 6C18.1763 3.57111 15.2716 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C15.2716 22 18.1763 20.4289 20.0007 18'
            stroke='currentColor'
            strokeWidth='2'
            strokeLinecap='round'
        />
    </svg>
);
