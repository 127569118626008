import React from 'react';
import type { IconProps } from '@models/types';

export const PassportsIcon: React.FC<IconProps> = ({ ...props }) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width='20'
        height='20'
        viewBox='0 0 16 20'
        fill='none'
        {...props}
    >
        <path
            d='M12.5835 16.6667H14.2502C14.4712 16.6667 14.6831 16.579 14.8394 16.4227C14.9957 16.2664 15.0835 16.0544 15.0835 15.8334V2.50008C15.0835 2.27907 14.9957 2.06711 14.8394 1.91083C14.6831 1.75455 14.4712 1.66675 14.2502 1.66675H5.91683C5.69582 1.66675 5.48385 1.75455 5.32757 1.91083C5.17129 2.06711 5.0835 2.27907 5.0835 2.50008V4.16675'
            stroke='currentColor'
            strokeWidth='1.66667'
            strokeLinecap='round'
        />
        <path
            d='M12.5835 4.99992V17.4999C12.5835 17.9602 12.2104 18.3333 11.7502 18.3333H1.75016C1.28993 18.3333 0.91683 17.9602 0.91683 17.4999V4.99992C0.91683 4.53968 1.28993 4.16659 1.75016 4.16659H11.7502C12.2104 4.16659 12.5835 4.53968 12.5835 4.99992Z'
            stroke='currentColor'
            strokeWidth='1.66667'
        />
        <path
            d='M6.75016 14.5834C4.90921 14.5834 3.41683 13.091 3.41683 11.2501C3.41683 9.40913 4.90921 7.91675 6.75016 7.91675C8.59111 7.91675 10.0835 9.40913 10.0835 11.2501C10.0835 13.091 8.59111 14.5834 6.75016 14.5834Z'
            stroke='currentColor'
            strokeWidth='1.66667'
        />
        <path
            d='M3.83333 11.695C3.83333 11.695 5.56423 11.5184 6.71816 12.0481C7.87267 12.5778 8.2177 13.85 7.87209 14.1667'
            stroke='currentColor'
            strokeWidth='1.66667'
            strokeLinecap='round'
        />
        <path
            d='M6.3335 8.33325C6.3335 8.33325 6.3335 9.99992 7.5835 9.99992'
            stroke='currentColor'
            strokeWidth='1.66667'
            strokeLinecap='round'
        />
    </svg>
);
