import React from 'react';
import type { IconProps } from '@models/types';

export const FilterIcon: React.FC<IconProps> = (props) => (
    <svg { ...props } width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M17 1C17 0.447715 16.5523 0 16 0C15.4477 0 15 0.447715 15 1V2H1C0.447715 2 0 2.44772 0 3C0 3.55228 0.447715 4 1 4H15V5C15 5.55228 15.4477 6 16 6C16.5523 6 17 5.55228 17 5V4H19C19.5523 4 20 3.55228 20 3C20 2.44772 19.5523 2 19 2H17V1Z"
            fill="currentColor"
        />
        <path
            d="M1 8C0.447715 8 0 8.44771 0 9C0 9.55229 0.447715 10 1 10H5V11C5 11.5523 5.44772 12 6 12C6.55228 12 7 11.5523 7 11V10H19C19.5523 10 20 9.55229 20 9C20 8.44771 19.5523 8 19 8H7V7C7 6.44772 6.55228 6 6 6C5.44772 6 5 6.44772 5 7V8H1Z"
            fill="currentColor"
        />
        <path
            d="M0 15C0 14.4477 0.447715 14 1 14H11V13C11 12.4477 11.4477 12 12 12C12.5523 12 13 12.4477 13 13V14H19C19.5523 14 20 14.4477 20 15C20 15.5523 19.5523 16 19 16H13V17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17V16H1C0.447715 16 0 15.5523 0 15Z"
            fill="currentColor"
        />
    </svg>
);
