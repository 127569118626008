import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { SANDBOX } from '@constants';
import { SandboxEntityEnum, SandboxErrorVariantEnum } from '@models/enums';
import type { RootState } from '@store/root-reducer';
import { sessionSlice } from '@store/slices/session-slice';

export const useTrackSandbox = () => {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const isSandboxSession = useSelector((state: RootState) => state.dashboard.session.isSandbox);

    const isSandboxUrl = useMemo(() => searchParams.get(SANDBOX.IS_SANDBOX), [searchParams]);

    const setIsSandbox = useCallback(
        (isSandbox: boolean) => {
            dispatch(sessionSlice.actions.setIsSandbox(isSandbox));
            setSearchParams((prevParams) => {
                if (isSandbox) {
                    prevParams.set(SANDBOX.IS_SANDBOX, 'true');
                } else {
                    prevParams.delete(SANDBOX.IS_SANDBOX);
                }

                return prevParams;
            });
        },
        [dispatch, setSearchParams],
    );

    const getEntity = useCallback((message: string) => {
        let entity;

        if (message?.includes(SANDBOX.APPLICANT)) {
            entity = SandboxEntityEnum.APPLICANT;
        }
        if (message?.includes(SANDBOX.VERIFICATION)) {
            entity = SandboxEntityEnum.VERIFICATION;
        }

        return entity;
    }, []);

    const setIsSandboxByKey = useCallback(
        (isSandbox: boolean) => {
            if (!isSandboxSession && isSandbox) {
                setIsSandbox(true);
            }
        },
        [isSandboxSession, setIsSandbox],
    );

    const setIsSandboxGlobal = useCallback(() => {
        if (!isSandboxSession && isSandboxUrl) {
            setIsSandbox(true);
        }

        if (isSandboxSession && !isSandboxUrl) {
            setIsSandbox(true);
        }
    }, [isSandboxSession, isSandboxUrl, setIsSandbox]);

    const trackSandboxErrorMassage = useCallback(
        (message?: string) => {
            let sandboxError = {
                sandboxVariant: SandboxErrorVariantEnum.ANOTHER_ERROR,
                isSandbox: undefined,
                entity: undefined,
            } as {
                sandboxVariant: SandboxErrorVariantEnum;
                isSandbox?: boolean;
                entity?: SandboxEntityEnum;
            };

            if (message?.includes('sandbox')) {
                if (message?.includes(SANDBOX.ERROR_MESSAGE_IS_SANDBOX)) {
                    sandboxError = {
                        sandboxVariant: SandboxErrorVariantEnum.MAKE_REQUEST_WITH_SANDBOX,
                        isSandbox: true,
                        entity: getEntity(message),
                    };
                }
                if (message?.includes(SANDBOX.ERROR_MESSAGE_IS_NONE_SANDBOX)) {
                    sandboxError = {
                        sandboxVariant: SandboxErrorVariantEnum.MAKE_REQUEST_WITHOUT_SANDBOX,
                        isSandbox: false,
                        entity: getEntity(message),
                    };
                }
            }

            return sandboxError;
        },
        [getEntity],
    );

    return {
        setIsSandboxByKey,
        setIsSandboxGlobal,
        setIsSandbox,
        trackSandboxErrorMassage,
    };
};
